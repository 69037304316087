import dayjs from "dayjs";

export const reformatInvoiceNumber = (value: any, createdAt: any) => {
    const parts = value.split('/');
    if (parts.length !== 2) {
        throw new Error("Invalid format");
    }

    const prefix = parts[0].slice(0, 2);
    const middle = parts[1];
    const number = parts[0].slice(2);
    const month = dayjs(createdAt).format('MM');

    const formattedValue = `${prefix}${middle}${month}-${number.padStart(4, '0')}`;

    return formattedValue;
};
