import { HttpError, IResourceComponentsProps } from "@pankod/refine-core";
import { Create, Form, Input, useForm, Row, Col, Breadcrumb } from "@pankod/refine-antd";
import React from "react";
import { RESOURCE_LIST_NAME } from "./constant";
import { IBranch } from "interfaces";

export const BranchCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, mutationResult } = useForm<IBranch, HttpError>({
    action: "create",
    redirect: "list",
  });

  return (
    <Create
      isLoading={mutationResult?.isLoading}
      saveButtonProps={{
        ...saveButtonProps,
      }}
      pageHeaderProps={{
        title: "Add New Branch",
        breadcrumb: <Breadcrumb hideIcons />,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={({ ...values }) => {
          formProps.onFinish({
            ...values,
          });
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
