import { RESOURCE_FIELDS as caseTemplateTypeResourceFields } from "../case-template-types/constant";
export const RESOURCE_NAME = "caseTemplates";

export const RESOURCE_LIST_NAME = "case-templates";

export const RESOURCE_FIELDS = [
  "id",
  "name",
  "enabledSms",
  "enabledEmail",
  "statuses",
  "status",
  "createdAt",
  "updatedAt",
  {
    type: caseTemplateTypeResourceFields,
  },
  {
    branch: ["id", "name"],
  },
] as const;
