import {
  Breadcrumb,
  PageHeader,
  Col,
  List,
  DateField,
  Row,
  Show,
  Table,
  Typography,
  Space,
  Button,
  Icons,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useShow, useNavigation } from "@pankod/refine-core";
import { ILegalCase } from "interfaces";
import { FC } from "react";
import { LegalCaseClientType, PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import "./show.less";
import { useParams } from "react-router-dom";
import { useDrawer } from "hooks/use-drawer";
import { PrintLegalCase } from "components/cases/LegalCasePrint";

export const CaseShow: FC<IResourceComponentsProps> = () => {
  const { Text, Title } = Typography;
  const { goBack } = useNavigation();

  const { drawerProps, dataProps, show } = useDrawer<ILegalCase>({
    resource: "legalCase",
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
  });

  // i can fetch again based on id.
  const { id } = useParams();
  const { queryResult } = useShow<ILegalCase>({
    resource: RESOURCE_NAME,
    id,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
  });

  const { data, isLoading } = queryResult;
  const associates: string[] = [];

  const { clientPartyOne, clientPartyTwo } = (data?.data?.legalCaseClients || []).reduce<{
    clientPartyOne: ILegalCase["legalCaseClients"];
    clientPartyTwo: ILegalCase["legalCaseClients"];
  }>(
    (acc, value) => {
      if (value.type === LegalCaseClientType.PARTY_1) {
        acc.clientPartyOne.push(value);
      } else if (value.type === LegalCaseClientType.PARTY_2) {
        acc.clientPartyTwo.push(value);
      }
      return acc;
    },
    { clientPartyOne: [], clientPartyTwo: [] }
  );

  associates.push(data?.data?.lawyeredBy?.lastName.trim() || "");
  associates.push(
    clientPartyOne?.find(Boolean)?.client?.fullName + " - " + clientPartyTwo.find(Boolean)?.client?.fullName || ""
  );
  associates.push(data?.data?.sequenceId || "");
  associates.push(data?.data?.managedBy?.lastName || "");

  const projectSummary: string[] = [];
  if (data?.data?.caseProjectTypeName) {
    projectSummary.push(data?.data?.caseProjectTypeName.trim());
  }
  if (data?.data?.propertyAddress) {
    projectSummary.push(data?.data?.propertyAddress.trim());
  }
  if (data?.data?.caseTemplateName) {
    projectSummary.push(data?.data?.caseTemplateName.trim());
  }

  return (
    <div>
      <PageHeader breadcrumb={<Breadcrumb />} style={{ backgroundColor: "white" }}>
        <Button
          className="backButton"
          style={{ border: "none" }}
          icon={<Icons.ArrowLeftOutlined />}
          onClick={() => {
            goBack();
          }}
        />
        <Row justify="end" className="button">
          <Button
            key={"pdf"}
            style={{ fontSize: 15, fontWeight: 500 }}
            icon={
              <Icons.FilePdfOutlined
                style={{
                  color: "#52c41a",
                  fontSize: 17,
                  fontWeight: 500,
                }}
              />
            }
            onClick={() => {
              show(id);
            }}
          >
            Print
          </Button>
        </Row>
        <Row
          style={{
            width: "100%",
            position: "relative",
            background: "white",
            flexGrow: "1",
            minHeight: "calc(297mm - 76px)", // A4 height
          }}
        >
          <Col xs={24} sm={4} md={4} lg={4} xl={4}>
            <div className="sideInformation">
              <Text strong style={{ fontSize: 26 }}>
                {associates.join("/ ")}
              </Text>
              <Text style={{ fontSize: 18 }}>{data?.data?.propertyAddress}</Text>
            </div>
          </Col>

          <Col xs={24} sm={20} md={20} lg={20} xl={20} className="content">
            <div className="header">
              <img src={"/logo.png"} alt="Thai Advocates" />

              <div className="caption">
                <div className="caption__title">
                  <Text className="capital">T</Text>
                  <Text className="words">HAI</Text>
                  &nbsp; &nbsp; &nbsp;
                  <Text className="capital">A</Text>
                  <Text className="words">DVOCATES</Text>
                </div>

                <Text strong italic className="caption__subtitle">
                  Advocates & Solicitors · Peguambela & Peguamcara
                </Text>
              </div>
            </div>

            <div className="reference">
              <Text strong>
                File Ref No. : {data?.data?.sequenceId}/{data?.data?.managedBy?.lastName}
              </Text>
              <Text strong>
                Instruction Date : <DateField format="DD/MM/YYYY" value={data?.data?.createdAt} />
              </Text>
              <Text strong>Project Type : {data?.data?.caseProjectType?.name ?? "-"}</Text>
              <Text strong>Introducer: {data?.data?.introducedBy?.fullName ?? "-"}</Text>
            </div>

            <Title level={3}>{data?.data?.caseTemplateName}</Title>
            <div className="particulars">
              <Title level={4} underline style={{ marginBottom: 0 }}>
                CLIENT PARTICULARS ({data?.data?.documentVariables?.proposition})
              </Title>
              <Table
                // scroll={{ x: true }}
                dataSource={clientPartyOne.map((legalCaseClient, idx) => {
                  return {
                    id: idx + 1,
                    name: legalCaseClient.client?.fullName,
                    identificationNumber: legalCaseClient.client?.identificatioDocumentNumber,
                    phoneNumberMobile: legalCaseClient.client?.phoneNumberMobile,
                  };
                })}
                pagination={false}
                rowKey="id"
              >
                <Table.Column dataIndex="id" title="No."></Table.Column>
                <Table.Column dataIndex="name" title="Name"></Table.Column>
                <Table.Column dataIndex="identificationNumber" title="IC No."></Table.Column>
                <Table.Column fixed="right" dataIndex="phoneNumberMobile" title="Contact No."></Table.Column>
              </Table>
            </div>
            <div className="particulars">
              <Title level={4} underline style={{ marginBottom: 0 }}>
                CLIENT PARTICULARS ({data?.data?.documentVariables?.opposition})
              </Title>
              <Table
                // scroll={{ x: true }}
                dataSource={clientPartyTwo.map((legalCaseClient, idx) => {
                  return {
                    id: idx + 1,
                    name: legalCaseClient.client?.fullName,
                    identificationNumber: legalCaseClient.client?.identificatioDocumentNumber,
                    phoneNumberMobile: legalCaseClient.client?.phoneNumberMobile,
                  };
                })}
                pagination={false}
                rowKey="id"
                showHeader={false}
              >
                <Table.Column dataIndex="id" title="No."></Table.Column>
                <Table.Column dataIndex="name" title="Name"></Table.Column>
                <Table.Column dataIndex="identificationNumber" title="IC No."></Table.Column>
                <Table.Column fixed="right" dataIndex="phoneNumberMobile" title="Contact No."></Table.Column>
              </Table>
            </div>
            <div className="particulars">
              <Title level={4} underline style={{ marginBottom: 0 }}>
                PROPERTY PARTICULARS
              </Title>
              <Table
                dataSource={[data?.data?.propertyAddress].map((pa) => ({ id: 1, address: pa }))}
                pagination={false}
                rowKey="id"
                // responsive enabling
                // scroll={{ x: true }}
              >
                <Table.Column dataIndex="id" title="No."></Table.Column>
                <Table.Column dataIndex="address" title="Address"></Table.Column>
              </Table>
            </div>

            <div className="content-section">
              <Title level={4} underline style={{ marginBottom: 0 }}>
                REMARKS
              </Title>
              <Text>{data?.data?.remarks}</Text>
            </div>

            <div className="content-section">
              <Title level={4} underline style={{ marginBottom: 0 }}>
                FINANCIAL PARTICULARS
              </Title>
              <Text>Name: {data?.data?.bankerName ?? "-"}</Text>
              <Text>Contact: {data?.data?.bankerMobileNumber ?? "-"}</Text>
              <Text>Amount: {data?.data?.amount ?? "-"}</Text>
            </div>

            {/*<div className="content-section">
              <div style={{ textAlign: "center" }}>
                <div>{associates.join("/ ")}</div>
                <div style={{ fontWeight: 600 }}>{projectSummary.join("/ ")}</div>
              </div>
              </div>*/}
          </Col>
        </Row>
      </PageHeader>

      <PrintLegalCase
        drawerProps={drawerProps}
        dataProps={dataProps?.data}
        id={id}
        sequenceId={dataProps.data?.sequenceId}
      />
    </div>
  );
};
