import { useOne, MetaDataQuery, GetOneResponse } from "@pankod/refine-core";
import { useEffect, useState } from "react";

interface IUseDrawer<T> {
  drawerProps: {
    visible: boolean;
    onClose: () => void;
  };
  dataProps: GetOneResponse<T>;
  show: (id: string) => void;
  isLoading: boolean;
  isError: boolean;
  id: string | null;
}

export const useDrawer = <T>(props: { resource: string; metaData: MetaDataQuery }): IUseDrawer<T> => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);

  const { data, isLoading, isError, refetch } = useOne<T>({
    resource: props.resource,
    metaData: props.metaData,
    id,
    queryOptions: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  const show = (id: string) => {
    setOpen(true);
    setId(id);
  };

  const onClose = () => {
    setOpen(false);
  };

  return {
    drawerProps: {
      visible: open,
      onClose,
    },
    dataProps: {
      ...data,
    },
    show,
    isLoading,
    isError,
    id,
  };
};
