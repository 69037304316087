import { useLogin, useTranslate } from "@pankod/refine-core";
import { Row, Col, AntdLayout, Card, Typography, Form, Input, Button } from "@pankod/refine-antd";
import "./style.less";
import { Trans } from "react-i18next";

const { Text, Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const t = useTranslate();

  const [form] = Form.useForm<ILoginForm>();

  const { mutate: login, isLoading } = useLogin<ILoginForm>();

  const CardTitle = (
    <Title level={3} className="layout-title">
      <Trans
        i18nKey="pages.login.message"
        defaults="<0>Sign in</0> your account"
        components={[<Text key="0" style={{ color: "#67be23" }} />]}
      />
    </Title>
  );

  return (
    <AntdLayout>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div style={{ maxWidth: "408px", margin: "auto" }}>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(values);
                }}
                requiredMark={false}
                initialValues={{
                  username: "",
                  password: "",
                }}
              >
                <Form.Item name="username" label={t("pages.login.email", "Username")} rules={[{ required: true }]}>
                  <Input size="large" placeholder="email" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label={t("pages.login.password", "Password")}
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>

                <Button type="primary" size="large" htmlType="submit" block loading={isLoading}>
                  {t("pages.login.signin", "Sign in")}
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
