import React, { useState } from "react";
import { Select, Typography, Space } from "antd";
import { debounce } from "lodash";
import { useSearchLegalClient } from "hooks/use-search-legal-client";

const { Option } = Select;

interface SearchLegalClientInputProps {
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const SearchLegalClientInput: React.FC<SearchLegalClientInputProps> = (props) => {
  const [keyword, setKeyword] = useState(undefined);

  const { refetch, ...queryResult } = useSearchLegalClient(keyword || props.value);

  const options = queryResult?.data?.data || [];

  const handleSearch = debounce((newKeyword: string) => {
    setKeyword(newKeyword);
    refetch();
  }, 500);

  const handleChange = (id: string) => {
    props?.onChange(id);
  };

  return (
    <Select
      value={props.value}
      style={{ width: "100%" }}
      showSearch
      placeholder={props.placeholder}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      loading={queryResult.isFetching || false}
      notFoundContent={
        <Space>
          <Typography.Text>No client found</Typography.Text>
        </Space>
      }
    >
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {[option.firstName, option.lastName].filter(Boolean).join(" ")}
        </Option>
      ))}
    </Select>
  );
};
