import { HttpError, useGetIdentity } from "@pankod/refine-core";
import { Form, Input, Row, Col, Select, useForm, Switch, Button, DatePicker } from "@pankod/refine-antd";
import React, { useState } from "react";
import { useClientOptions } from "hooks/use-client-options";
import { BANK_DISPLAY_NAMES } from "libs/constant";
import { SaveOutlined } from "@ant-design/icons";
import { useBranchOptions } from "hooks/use-branch-options";
import { useAdminOptions } from "hooks/use-admin-options";
import { ILegalCase } from "interfaces";
import { RESOURCE_FIELDS } from "./constant";
import { mapValuesToSetter } from "libs";
import dayjs from "dayjs";

export const CaseForm = ({ id, onMutationSuccess }: { id: string; onMutationSuccess: () => void }) => {
  const { data: user } = useGetIdentity();

  const { formProps, saveButtonProps } = useForm<ILegalCase, HttpError, any>({
    id,
    action: "edit",
    redirect: false,
    resource: "LegalCase",
    metaData: {
      fields: [...RESOURCE_FIELDS].filter((field) => Object.keys(field).length > 0),
    },
    queryOptions: {
      enabled: false,
    },
    onMutationSuccess: () => {
      onMutationSuccess();
    },
  });

  const [showPropertyAddressField, setShowPropertyAddressField] = useState(
    formProps.form.getFieldValue("isLegalCaseForProperty")
  );

  const { selectProps: branchSelectProps } = useBranchOptions({
    branchId: user?.branchId,
  });

  const branchId = Form.useWatch("branchId", formProps.form);

  const { selectProps: clientSelectProps } = useClientOptions({
    branchId,
  });

  const { selectProps: adminSelectProps, queryResult: adminQueryResult } = useAdminOptions({
    branchId,
  });

  return (
    <Form
      {...formProps}
      layout="vertical"
      onFinish={({ branchId, introducedByClientId, managedByAdminId, ...values }) => {
        formProps.onFinish({
          ...mapValuesToSetter({
            dueAt: values?.dueAt ? dayjs(values?.dueAt).startOf("day").toDate() : null,
            bankName: values.bankName,
            remarks: values.remarks,
            propertyAddress: values.isLegalCaseForProperty ? values.propertyAddress : "",
          }),
          ...(branchId && {
            branch: {
              connect: { id: branchId },
            },
          }),
          ...(introducedByClientId && {
            introducedBy: {
              connect: { id: introducedByClientId },
            },
          }),
          ...(managedByAdminId && {
            managedBy: {
              connect: { id: managedByAdminId },
            },
          }),
        });
      }}
    >
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item label="Branch" name={["branchId"]} rules={[{ required: true }]}>
            <Select {...branchSelectProps} allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Bank Name" name="bankName" rules={[{ required: false }]}>
            <Select>
              {BANK_DISPLAY_NAMES.map((option, index) => (
                <Select.Option key={`bank${index}`} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Introducer" name={["introducedByClientId"]} rules={[{ required: false }]}>
            <Select {...clientSelectProps} options={[...clientSelectProps.options]} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Managed By" name="managedByAdminId" rules={[{ required: true }]}>
            <Select
              {...adminSelectProps}
              options={adminQueryResult?.data?.data?.map((o) => ({
                label: [o.firstName, o.lastName].filter(Boolean).join(" "),
                value: o.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Due At"
            name="dueAt"
            getValueProps={(value) => ({
              value: value ? dayjs(value) : "",
            })}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Remarks" name="remarks">
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Has Property?" name="isLegalCaseForProperty" valuePropName="checked">
            <Switch
              onChange={(enabled) => {
                setShowPropertyAddressField(enabled);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Property Address"
            name="propertyAddress"
            hidden={!showPropertyAddressField}
            rules={[{ required: showPropertyAddressField }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]} justify="end">
        <Col>
          <Button type="primary" icon={<SaveOutlined />} {...saveButtonProps}>
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
