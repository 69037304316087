import { Document, Font, Page, PDFViewer, StyleSheet } from "@react-pdf/renderer";
import { PdfMaker } from "components/pdf";
import { formatMoney } from "libs/formatter";
import { companyInfo } from "libs/constant";
import dayjs from "dayjs";
import { ILegalCase } from "interfaces";
import { reformatInvoiceNumber } from "libs";

Font.register({
  family: "Helvetica",
  fonts: [],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
});

interface IGeneralInvoiceProps {
  dataProps: {
    id: string;
    sequenceId: string;
    bankName: string;
    caseTemplateName: string;
    caseTemplateTypeName: string;
    caseProjectType: {
      id: string;
      name: string;
    };
    recipient: string;
    receiptNumber: string;
    invoiceNumber: string;
    createdAt: string;
    updatedAt: string;
    legalCase: ILegalCase;
    chargeSummary: {
      fee: number;
      otherCharge: number;
      subtotal: number;
      sst: number;
      total: number;
    };
    items: [
      {
        id: string;
        name: string;
        fee: number;
        otherCharge: number;
        createdAt: string;
        updatedAt: string;
      }
    ];
    legalCaseMatterId: null;
    particular: string;
    purchasePrice: number;
    loanAmount: number;
    managedBy: {
      id: string;
      firstName: string;
      lastName: string;
    };
    introducedBy: {
      id: string;
      firstName: string;
      lastName: string;
      fullName: string;
    };
    legalCaseClients: [];
    metadata?: {
      oppositionClientId: string;
      propositionClientId: string;
    };
  };
}

export const GeneralInvoice = ({ dataProps }: IGeneralInvoiceProps) => {
  const defaultSpacing = Array.from({ length: 10 - Number(dataProps?.items?.length || 0) }, (_, i) => `Item ${i + 1}`);
  return (
    <>
      <PDFViewer style={{ width: "100%", height: "70vh" }}>
        <Document>
          <Page size="A4" style={styles.body}>
            <PdfMaker.View style={{ marginBottom: 10 }}>
              <PdfMaker.Text bold textAlign="center">
                {companyInfo.name}
              </PdfMaker.Text>
              <PdfMaker.Text textAlign="center">{companyInfo.addressLine1}</PdfMaker.Text>
              <PdfMaker.Text textAlign="center">{companyInfo.addressLine2}</PdfMaker.Text>
              <PdfMaker.Text textAlign="center">
                Tel : {companyInfo.tel} Fax : {companyInfo.fax}
              </PdfMaker.Text>
              <PdfMaker.Text textAlign="center">SST No : {companyInfo.sstNo}</PdfMaker.Text>
            </PdfMaker.View>

            <PdfMaker.View style={{ marginBottom: 10 }}>
              <PdfMaker.Text bold underline textAlign="center">
                TAX INVOICE
              </PdfMaker.Text>
            </PdfMaker.View>

            <PdfMaker.Table borderless style={{ marginBottom: "12px" }}>
              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="5%">
                  <PdfMaker.Text>To: </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="35%">
                  <PdfMaker.Text>{dataProps?.recipient || "-"}</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text>Invoice No: </PdfMaker.Text>
                  <PdfMaker.Text>Invoice Date: </PdfMaker.Text>
                  <PdfMaker.Text>Our Ref: </PdfMaker.Text>
                  <PdfMaker.Text>Project: </PdfMaker.Text>
                  <PdfMaker.Text>Introducer: </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="45%">
                  <PdfMaker.Text>{reformatInvoiceNumber(dataProps?.invoiceNumber, dataProps?.createdAt)}</PdfMaker.Text>
                  <PdfMaker.Text>{dayjs(dataProps?.createdAt).format("DD/MM/YYYY")}</PdfMaker.Text>
                  <PdfMaker.Text>
                    {dataProps?.legalCase?.sequenceId}/{dataProps?.legalCase?.managedBy?.lastName}
                  </PdfMaker.Text>
                  <PdfMaker.Text>{dataProps?.legalCase?.caseProjectType?.name}</PdfMaker.Text>
                  <PdfMaker.Text>{dataProps?.legalCase?.introducedBy?.fullName}</PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>
            </PdfMaker.Table>

            <PdfMaker.Table
              style={{
                borderRight: 0,
                borderBottom: 0,
              }}
            >
              <PdfMaker.Tr
                borderless
                borderStyle={{
                  borderRight: "1px solid #000",
                  borderBottom: "1px solid #000",
                }}
              >
                <PdfMaker.Td width="40%">
                  <PdfMaker.Text bold>PARTICULARS</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text bold textAlign="center">
                    LEGAL FEE
                  </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="30%">
                  <PdfMaker.Text bold textAlign="center">
                    S/Duty Regn.fee Dup. Copies, Notice fee & W/C fee{" "}
                  </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text bold textAlign="center">
                    TOTAL
                  </PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>

              {dataProps?.legalCase.propertyAddress ? (
                <PdfMaker.Tr
                  borderless
                  borderStyle={{
                    borderRight: "1px solid #000",
                  }}
                >
                  <PdfMaker.Td width="40%">
                    <PdfMaker.Table borderless>
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="35%">
                          <PdfMaker.Text bold>Property</PdfMaker.Text>
                        </PdfMaker.Td>
                        <PdfMaker.Td width="65%">
                          <PdfMaker.Text>{dataProps?.legalCase.caseProjectType?.name}</PdfMaker.Text>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>  
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="35%"></PdfMaker.Td>
                        <PdfMaker.Td width="65%">
                          <PdfMaker.Text>{dataProps?.particular}</PdfMaker.Text>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="100%">
                          <PdfMaker.Text bold>P.Price: RM {dataProps?.purchasePrice}</PdfMaker.Text>
                          <PdfMaker.Text bold style={{ marginBottom: "12px" }}>
                            Loan Amt: RM {dataProps?.loanAmount}
                          </PdfMaker.Text>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>
                    </PdfMaker.Table>
                  </PdfMaker.Td>
                  <PdfMaker.Td width="15%">
                    <PdfMaker.Text bold textAlign="center">
                      RM
                    </PdfMaker.Text>
                  </PdfMaker.Td>
                  <PdfMaker.Td width="30%">
                    <PdfMaker.Text bold textAlign="center">
                      RM
                    </PdfMaker.Text>
                  </PdfMaker.Td>
                  <PdfMaker.Td width="15%"></PdfMaker.Td>
                </PdfMaker.Tr>
              ) : (
                <PdfMaker.Tr
                  borderless
                  borderStyle={{
                    borderRight: "1px solid #000",
                  }}
                >
                  <PdfMaker.Td width="40%">
                    <PdfMaker.Text>{dataProps?.particular}</PdfMaker.Text>
                  </PdfMaker.Td>
                  <PdfMaker.Td width="15%">
                    <PdfMaker.Text bold textAlign="center">
                      RM
                    </PdfMaker.Text>
                  </PdfMaker.Td>
                  <PdfMaker.Td width="30%">
                    <PdfMaker.Text bold textAlign="center">
                      RM
                    </PdfMaker.Text>
                  </PdfMaker.Td>
                  <PdfMaker.Td width="15%"></PdfMaker.Td>
                </PdfMaker.Tr>
              )}

              {dataProps?.items?.map((item) => {
                return (
                  <PdfMaker.Tr
                    key={`item_${item.id}`}
                    borderless
                    borderStyle={{
                      borderRight: "1px solid #000",
                    }}
                  >
                    <PdfMaker.Td width="40%">
                      <PdfMaker.Text>{item.name}</PdfMaker.Text>
                    </PdfMaker.Td>
                    <PdfMaker.Td width="15%">
                      <PdfMaker.Text textAlign="right">{formatMoney(item.fee)}</PdfMaker.Text>
                    </PdfMaker.Td>
                    <PdfMaker.Td width="30%">
                      <PdfMaker.Text textAlign="right">{formatMoney(item.otherCharge)}</PdfMaker.Text>
                    </PdfMaker.Td>
                    <PdfMaker.Td width="15%"></PdfMaker.Td>
                  </PdfMaker.Tr>
                );
              })}

              {defaultSpacing.map((index) => {
                return (
                  <PdfMaker.Tr key={`item_${index}`} borderless borderStyle={{ borderRight: "1px solid #000" }}>
                    <PdfMaker.Td width="40%"></PdfMaker.Td>
                    <PdfMaker.Td width="15%"></PdfMaker.Td>
                    <PdfMaker.Td width="30%"></PdfMaker.Td>
                    <PdfMaker.Td width="15%"></PdfMaker.Td>
                  </PdfMaker.Tr>
                );
              })}
            </PdfMaker.Table>

            <PdfMaker.Table style={{ borderRight: 0 }}>
              <PdfMaker.Tr
                borderless
                borderStyle={{
                  borderRight: "1px solid #000",
                  borderBottom: "1px solid #000",
                }}
              >
                <PdfMaker.Td width="40%">
                  <PdfMaker.Text bold>SUB TOTAL</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="right">{formatMoney(dataProps?.chargeSummary?.fee)}</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="30%">
                  <PdfMaker.Text textAlign="right">{formatMoney(dataProps?.chargeSummary?.otherCharge)}</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="right">{formatMoney(dataProps?.chargeSummary?.subtotal)}</PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>

              <PdfMaker.Tr
                borderless
                borderStyle={{
                  borderRight: "1px solid #000",
                  borderBottom: "1px solid #000",
                }}
              >
                <PdfMaker.Td width="85%">
                  <PdfMaker.Text textAlign="right" bold>
                    TAX AMOUNT (SST 8% of Legal Fee)
                  </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="right">{formatMoney(dataProps?.chargeSummary?.sst)}</PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>
              <PdfMaker.Tr borderless borderStyle={{ borderRight: "1px solid #000" }}>
                <PdfMaker.Td width="85%">
                  <PdfMaker.Text textAlign="right" bold>
                    TOTAL
                  </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="right">{formatMoney(dataProps?.chargeSummary?.total)}</PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>
            </PdfMaker.Table>

            <PdfMaker.View style={{ marginTop: "40px" }}>
              <PdfMaker.View
                style={{
                  height: "20px",
                  borderBottom: "1px solid black",
                  width: "150px",
                }}
              />
              <PdfMaker.View style={{ height: "12px" }} />
              <PdfMaker.Text bold>{companyInfo.name}</PdfMaker.Text>
              <PdfMaker.View style={{ height: "12px" }} />
              <PdfMaker.Text>Note: This invoice is expressly subject to the following terms: -</PdfMaker.Text>
              {[
                "E. & O.E.",
                "Outstation cheques should include Bank Commission",
                "No receipt is considered valid unless on the firm's official receipt.",
                "Interest @ 14% p.a. pursuant to Section 6 of the Advocates Remuneration Rules 1988 will be chargeable",
                "after thirty (30) days of receipt of this invoice if invoice remains unpaid.",
                "Payment to be made to  'THAI ADVOCATES' Public Bank accout number: 3987068528",
                "Please provide us with the bank in slip after payment made",
              ].map((o, index) => (
                <PdfMaker.Text key={index}>
                  {++index}. {o}
                </PdfMaker.Text>
              ))}
            </PdfMaker.View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};
