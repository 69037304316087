import { useGetLocale, useSetLocale, useGetIdentity, useNavigation, useLogout } from "@pankod/refine-core";
import { AntdLayout, Space, Menu, Icons, Avatar, Typography, Dropdown, Grid } from "@pankod/refine-antd";
import { InsuranceOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const Header: React.FC = () => {
  const { push, replace, goBack } = useNavigation();
  const { mutate: logout } = useLogout();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity();

  const currentLocale = locale();
  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const menu = (
    <Menu
      selectedKeys={currentLocale ? [currentLocale] : []}
      style={{ marginTop: "8px" }}
      items={[
        {
          key: "edit-profile",
          icon: <InsuranceOutlined />,
          label: " Edit Profile",
          onClick: () => {
            replace("/account-settings/profile");
          },
        },
        {
          key: "logout",
          icon: <InsuranceOutlined />,
          label: " Logout",
          onClick: () => {
            logout();
          },
        },
      ]}
    />
  );

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        backgroundColor: "#FFF",
      }}
    >
      <Space>
        <Dropdown overlay={menu}>
          <a href="/" onClick={(e) => e.preventDefault()}>
            <Space>
              {user?.name && (
                <Text ellipsis strong>
                  {user.name}
                </Text>
              )}
              {user?.avatar && <Avatar size={"default"} src={user?.avatar} alt={user?.name} />}
            </Space>
          </a>
        </Dropdown>
      </Space>
    </AntdLayout.Header>
  );
};
