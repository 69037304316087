import React from "react";
import { IResourceComponentsProps, CrudFilters, CrudSort, HttpError, useGetIdentity } from "@pankod/refine-core";
import {
  List,
  Table,
  Card,
  Input,
  Icons,
  Form,
  Button,
  FormProps,
  Row,
  Col,
  Space,
  useTable,
  EditButton,
  CreateButton,
  useSelect,
  Select,
  DateField,
  getDefaultSortOrder,
  Avatar,
} from "@pankod/refine-antd";
import { RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { IClient, IClientFilterVariables, IAdminRole } from "interfaces";
import { CRUDFILTER_OPERATOR_OR, PAGINATION_FIELDS } from "libs/constant";
import { useSearchParams } from "react-router-dom";

export const AdminUserList: React.FC<IResourceComponentsProps> = () => {
  const initialSorter: CrudSort[] = [{ field: "updatedAt", order: "desc" }];
  const initialPageSize = 10;
  const { data: user } = useGetIdentity();

  const { tableProps, searchFormProps, filters, sorter } = useTable<IClient, HttpError, IClientFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
    permanentFilter: [
      {
        operator: "ne",
        field: "id",
        value: user?.id,
      },
      {
        field: "branchId",
        operator: "eq",
        value: user?.branchId,
      },
    ],
    /*queryOptions: {
      select: (data) => {
        data.data = data?.data.filter( e => e.id !== identity?.id)
        return data;
      },
    },*/
    syncWithLocation: true,
    onSearch: (params: any) => {
      const { name, email, username, roleId, branchId } = params;
      const filters: CrudFilters = [
        {
          operator: CRUDFILTER_OPERATOR_OR,
          field: "name",
          value: name
            ? [
                {
                  field: "firstName",
                  operator: "contains",
                  value: name,
                },
                {
                  field: "lastName",
                  operator: "contains",
                  value: name,
                },
              ]
            : undefined,
        },
        {
          operator: "contains",
          field: "email",
          value: email,
        },
        {
          operator: "contains",
          field: "username",
          value: username,
        },
        {
          operator: "in",
          field: "roleId",
          value: roleId,
        },
      ];

      return filters;
    },
  });

  const Actions: React.FC = () => (
    <Space>
      <CreateButton />
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={24} xl={6}>
        <Card title="Filter">
          <Filter formProps={searchFormProps} filters={filters || []} />
        </Card>
      </Col>

      <Col xs={24} xl={18}>
        <List
          pageHeaderProps={{
            extra: <Actions />,
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="fullName" title="Full Name" />
            <Table.Column
              align="center"
              key="avatar"
              dataIndex="username"
              title="Avatar"
              render={(value) => <Avatar size="large" src={`https://avatars.dicebear.com/api/croodles/${value}.svg`} />}
            />
            <Table.Column dataIndex="username" title="Username" />
            <Table.Column dataIndex="email" title="Email" />
            {user?.branchId === null && (
              <Table.Column dataIndex={["branch", "name"]} title="Branch" render={(value) => value || "N/A"} />
            )}
            <Table.Column dataIndex={["department"]} title="Department" />
            <Table.Column dataIndex={["role", "name"]} title="Type" />
            <Table.Column
              dataIndex="status"
              title="Status"
              defaultSortOrder={getDefaultSortOrder("status", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="createdAt"
              title="Created At"
              render={(record) => <DateField format="LLL" value={record} />}
              defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
              sorter
            />
            <Table.Column<any>
              fixed="right"
              width="5%"
              key="actions"
              title={"Actions"}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton disabled={record?.id === user?.id} hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps?: FormProps; filters: CrudFilters }> = (props) => {
  const { selectProps: adminRoleSelectProps } = useSelect<IAdminRole>({
    resource: "adminRoles",
    metaData: {
      items: ["id", "name"],
      metadata: PAGINATION_FIELDS,
    },
    optionValue: "id",
    optionLabel: "name",
  });

  const [_, setSearchParams] = useSearchParams();

  const handleResetFilters = () => {
    setSearchParams({});

    props.formProps.form.resetFields();

    props.formProps?.form.submit();
  };

  return (
    <Form
      layout="vertical"
      {...props.formProps}
      initialValues={{
        ...props.formProps?.initialValues,
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Name" name="name">
            <Input placeholder="Search Name" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Username" name="username">
            <Input placeholder="Search Username" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Email" name="email">
            <Input placeholder="Search Email" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Type" name="roleId">
            <Select mode="multiple" {...adminRoleSelectProps} placeholder="Search Type" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="button" onClick={handleResetFilters} type="default">
              Reset Filters
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
