import React from "react";
import {
  IResourceComponentsProps,
  CrudFilters,
  CrudSort,
  HttpError,
  getDefaultFilter,
  useGetIdentity,
} from "@pankod/refine-core";
import {
  List,
  Table,
  Card,
  Input,
  Icons,
  Form,
  Button,
  FormProps,
  Row,
  Col,
  Space,
  useTable,
  EditButton,
  CreateButton,
} from "@pankod/refine-antd";
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { ICaseTemplateType, ICaseTemplateTypeFilterVariables } from "interfaces";

export const CaseTemplateTypeList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const initialSorter: CrudSort[] = [{ field: "updatedAt", order: "desc" }];
  const initialPageSize = 30;
  const permanentFilter: CrudFilters = [
    {
      field: "branchId",
      operator: "eq",
      value: user?.branchId,
    },
  ];

  const { tableProps } = useTable<ICaseTemplateType, HttpError, ICaseTemplateTypeFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter,
    initialSorter,
    initialPageSize,
    queryOptions: {
      enabled: !!user,
    },
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { name, code } = params;

      filters.push({
        field: "name",
        operator: "contains",
        value: name,
      });

      filters.push({
        field: "code",
        operator: "contains",
        value: code,
      });

      return filters;
    },
  });

  const Actions: React.FC = () => (
    <Space>
      <CreateButton />
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <List
          pageHeaderProps={{
            extra: <Actions />,
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="name" title="Name" />
            <Table.Column dataIndex="code" title="Code" />
            <Table.Column dataIndex="currentSequenceId" title="Current Running Number" />
            <Table.Column<ICaseTemplateType>
              dataIndex="branchId"
              title="Branch name"
              render={(_, record) => record?.branch?.name || "N/A"}
            />
            <Table.Column dataIndex="status" title="Status" />
            <Table.Column<any>
              width="5%"
              key="actions"
              title={"Actions"}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps?: FormProps; filters: CrudFilters }> = (props) => {
  return (
    <Form
      layout="vertical"
      {...props.formProps}
      initialValues={{
        title: getDefaultFilter("title", props.filters),
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={20} xl={20} md={20}>
          <Form.Item label="name" name="name">
            <Input placeholder="Search" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>

        <Col xs={4} xl={4} md={4}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
