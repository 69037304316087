import {
  HttpError,
  IResourceComponentsProps,
  useRedirectionAfterSubmission,
  useResourceWithRoute,
} from "@pankod/refine-core";
import { Create, Form, Input, useForm, Row, Col, Card, Breadcrumb } from "@pankod/refine-antd";
import React from "react";
import { RESOURCE_LIST_NAME } from "./constant";
import { IClientType } from "interfaces";
import { simplifyErrorMessage } from "libs";
import { message } from "@pankod/refine-antd";

const responsive = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

export const ClientTypeCreate: React.FC<IResourceComponentsProps> = () => {
  const resourceWithRoute = useResourceWithRoute();
  const resource = resourceWithRoute(RESOURCE_LIST_NAME);
  const handleSubmitWithRedirect = useRedirectionAfterSubmission();

  const { formProps, saveButtonProps, mutationResult } = useForm<IClientType, HttpError>({
    action: "create",
    redirect: false,
    onMutationError: (error) => {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || "Failed to create client type!");
    },
    onMutationSuccess: () => {
      handleSubmitWithRedirect({
        redirect: "list",
        resource: resource,
      });
    },
  });

  return (
    <Create
      isLoading={mutationResult?.isLoading}
      saveButtonProps={{
        ...saveButtonProps,
      }}
      pageHeaderProps={{
        title: "Add Client Type",
        breadcrumb: <Breadcrumb hideIcons />,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={({ ...values }) => {
          formProps.onFinish({
            ...values,
          });
        }}
      >
        <Row gutter={[24, 24]} justify="start" align="top">
          <Col {...responsive}>
            <Card title="General Settings">
              <Form.Item
                normalize={(value) => {
                  return value.toString().toLowerCase();
                }}
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/[^A-Z]+/),
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
