export const PAGINATION_FIELDS = ["currentPage", "perPage", "totalCount", "totalPages"] as const;

// note(roy): had to mask 'containss' as 'or', the original refine 'or' has bug
export const CRUDFILTER_OPERATOR_OR = "containss";
export const CRUDFILTER_OPERATOR_SOME = "ncontainss";
export const CRUDFILTER_OPERATOR_IS = "ncontains";

export const BANK_DISPLAY_NAMES = [
  { label: "Affin Bank", value: "Affin Bank" },
  { label: "Affin Islamic Bank", value: "Affin Islamic Bank" },
  { label: "Agrobank", value: "Agrobank" },
  {
    label: "Al-Rajhi Banking & Inv.Corp.(M) Bhd",
    value: "Al-Rajhi Banking & Inv.Corp.(M) Bhd",
  },
  { label: "Alliance Bank", value: "Alliance Bank" },
  { label: "Alliance Islamic Bank", value: "Alliance Islamic Bank" },
  { label: "Ambank", value: "Ambank" },
  { label: "AmIslamic Bank", value: "AmIslamic Bank" },
  { label: "Bank Islam", value: "Bank Islam" },
  { label: "Bank Kerjasama Rakyat", value: "Bank Kerjasama Rakyat" },
  { label: "Bank Muamalat", value: "Bank Muamalat" },
  { label: "Bank of America", value: "Bank of America" },
  { label: "Bank of China", value: "Bank of China" },
  {
    label: "Bank of Tokyo-Mitsubishi UFJ",
    value: "Bank of Tokyo-Mitsubishi UFJ",
  },
  { label: "Bank Simpanan Nasional", value: "Bank Simpanan Nasional" },
  { label: "BNP Paribas", value: "BNP Paribas" },
  { label: "CIMB Bank", value: "CIMB Bank" },
  { label: "CIMB Islamic Bank", value: "CIMB Islamic Bank" },
  { label: "Citibank", value: "Citibank" },
  { label: "Deutsche Bank", value: "Deutsche Bank" },
  { label: "Hong Leong Bank", value: "Hong Leong Bank" },
  {
    label: "Hong Leong Islamic Bank",
    value: "Hong Leong Islamic Bank",
  },
  { label: "HSBC Amanah", value: "HSBC Amanah" },
  { label: "HSBC Bank", value: "HSBC Bank" },
  {
    label: "Industrial & Commercial Bank of China (ICBC)",
    value: "Industrial & Commercial Bank of China (ICBC)",
  },
  { label: "JP Morgan Chase", value: "JP Morgan Chase" },
  { label: "Kuwait Finance House", value: "Kuwait Finance House" },
  { label: "Maybank", value: "Maybank" },
  { label: "Maybank Islamic", value: "Maybank Islamic" },
  { label: "Mizuho Bank", value: "Mizuho Bank" },
  { label: "OCBC Al-Amin Bank", value: "OCBC Al-Amin Bank" },
  { label: "OCBC Bank", value: "OCBC Bank" },
  { label: "Public Bank", value: "Public Bank" },
  { label: "Public Islamic Bank", value: "Public Islamic Bank" },
  { label: "RHB Bank", value: "RHB Bank" },
  { label: "RHB Islamic Bank", value: "RHB Islamic Bank" },
  {
    label: "Standard Chartered Bank",
    value: "Standard Chartered Bank",
  },
  {
    label: "Standard Chartered Saadiq",
    value: "Standard Chartered Saadiq",
  },
  {
    label: "Sumitomo Mitsui Banking Corporation",
    value: "Sumitomo Mitsui Banking Corporation",
  },
  { label: "United Overseas Bank", value: "United Overseas Bank" },
];

export const ROLES = {
  SUPERVISOR_ADMIN: "Supervisor Admin",
  ADMIN: "Admin",
  LAWYER: "Lawyer",
  HEAD_OF_DEPARTMENT: "HOD",
  STAFF: "Staff",
};

export const companyInfo = {
  name: "THAI ADVOCATES",
  addressLine1: "Lot 917, Fortune Park Commercial Centre",
  addressLine2: "P.O.Box 2988, 97012 Bintulu, Sarawak",
  tel: "086-319919",
  fax: "086-319929",
  email: "alexwoo.ta@gmail.com",
  sstNo: "Y58-2402-32000018",
};
