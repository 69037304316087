import { useSelect } from "@pankod/refine-antd";
import { PAGINATION_FIELDS } from "libs/constant";

interface ILegalGoodOptions {
  id: number;
  name: string;
  description: string;
  fee: number;
  otherCharge: number;
}

export const useLegalGoodOptions = () => {
  return useSelect<ILegalGoodOptions>({
    resource: "legalGoods",
    debounce: 500,
    metaData: {
      method: "get",
      items: ["id", "name", "description", "fee", "otherCharge", "status"],
      metadata: PAGINATION_FIELDS,
    },
    fetchSize: 500, // TODO: dirty fix for now
    optionValue: "name",
    optionLabel: "name",
  });
};
