export const RESOURCE_NAME = "clientTypes";

export const RESOURCE_LIST_NAME = "client-types";

export const RESOURCE_FIELDS = [
  "id",
  "name",
  "description",
  "status",
  "createdAt",
  "updatedAt",
] as const;
export const PAGINATION_FIELDS = [
  "currentPage",
  "perPage",
  "totalCount",
  "totalPages",
] as const;
