import { useSelect } from "@pankod/refine-antd";
import { IClient } from "interfaces";
import { CRUDFILTER_OPERATOR_OR, PAGINATION_FIELDS } from "libs/constant";

export const useClientOptions = ({ branchId }: { branchId: string }) => {
  return useSelect<IClient>({
    resource: "clients",
    debounce: 500,
    onSearch: (value) => [
      {
        operator: CRUDFILTER_OPERATOR_OR,
        field: "",
        value: [
          {
            field: "email",
            operator: "contains",
            value: value,
          },
          {
            field: "firstName",
            operator: "contains",
            value: value,
          },
          {
            field: "lastName",
            operator: "contains",
            value: value,
          },
        ],
      },
    ],
    metaData: {
      method: "get",
      items: [
        "id",
        "firstName",
        "lastName",
        "fullName",
        "fullNameWithIC",
        "identificatioDocumentNumber",
        "phoneNumberMobile",
        "phoneNumberOffice",
        "address",
        "email",
        "clientTypeId",
        "branchId",
        {
          clientType: ["id", "name"],
        },
      ],
      metadata: PAGINATION_FIELDS,
    },
    filters: branchId ? [{ field: "branchId", operator: "eq", value: branchId }] : undefined,
    fetchSize: 200, // TODO: dirty fix for now
    optionValue: "id",
    optionLabel: "fullNameWithIC",
  });
};
