import { Drawer, DrawerProps, Grid, PageHeader, Card } from "@pankod/refine-antd";
import { BaseKey } from "@pankod/refine-core";
import { InvoiceTemplate } from "./templates";

interface InvoiceDetailsProps {
  drawerProps: DrawerProps;
  dataProps: any;
}

export const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({ drawerProps, dataProps }) => {
  const breakpoint = Grid.useBreakpoint();

  // TODO handle new and legacy data
  const proposition = dataProps?.data?.client?.proposition;
  const opposition = dataProps?.data?.client?.opposition;

  const renderPdf = () => {
    if (
      dataProps?.data?.legalCase?.caseTemplateName === "BANKING LITIGATION" &&
      dataProps?.data?.legalCase?.caseTemplateTypeName === "Litigation"
    ) {
      return (
        <InvoiceTemplate.Litigation
          dataProps={{
            ...dataProps?.data,
            legalFees: dataProps?.data?.chargeSummary.fee,
            disbursement: dataProps?.data?.chargeSummary.otherCharge,
            sst: dataProps?.data?.chargeSummary.sst,
            subtotal: dataProps?.data?.chargeSummary.subtotal,
            totalAmount: dataProps?.data?.chargeSummary.total,
            propositionClient: {
              ...(proposition || {}),
            },
            oppositionClient: {
              ...(opposition || {}),
            },
          }}
        />
      );
    }

    return <InvoiceTemplate.General dataProps={dataProps?.data} />;
  };

  return (
    <Drawer {...drawerProps} width={breakpoint.sm ? "50%" : "100%"} zIndex={1001}>
      <PageHeader
        ghost={false}
        title={"Invoice"}
        backIcon={false}
        extra={null}
        breadcrumb={{ style: { display: "none" } }}
      >
        {dataProps.data && <Card bordered={false}>{renderPdf()}</Card>}
      </PageHeader>
    </Drawer>
  );
};
