import { HttpError, IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";
import { Edit, Form, Row, Col, Input, Select, Card, Space, useForm } from "@pankod/refine-antd";
import { RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { useParams } from "react-router-dom";
import React from "react";
import { IClient } from "interfaces";
import { mapValuesToSetter } from "libs";
import { useBranchOptions } from "hooks/use-branch-options";
import { useClientTypeOptions } from "hooks/use-client-type-options";

export const ClientEdit: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const { id } = useParams();

  const { formProps, queryResult, saveButtonProps } = useForm<IClient, HttpError>({
    id: id,
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
    redirect: false,
  });

  const branchId = Form.useWatch("branchId", formProps.form);

  const clientTypeOptions = useClientTypeOptions();

  const branchOptions = useBranchOptions({
    branchId,
  });

  return (
    <Edit
      title="Edit"
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
          enabledSms: false,
          enabledEmail: false,
        }}
        layout="vertical"
        // @ts-ignore
        onFinish={({ clientType, branch, ...values }) => {
          const payload = mapValuesToSetter({
            ...values,
          });

          formProps?.onFinish?.({
            ...payload,
            clientType: {
              connect: {
                id: clientType.id,
              },
            },
            branch: {
              connect: {
                id: branch.id,
              },
            },
          });
        }}
      >
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
          <Card title="Personal Info">
            <Row gutter={[24, 0]} align="middle">
              <Col span={12}>
                <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item label="Last Name" name="lastName" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item label="Client Type" name={["clientType", "id"]} rules={[{ required: true }]}>
                  <Select {...clientTypeOptions.selectProps} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Identification/Business Number"
                  name="identificatioDocumentNumber"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Branch" name={["branch", "id"]} rules={[{ required: true }]}>
                  <Select {...branchOptions.selectProps} allowClear={true} disabled={!!user?.branchId} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Contact Info">
            <Row>
              <Col span={6}>
                <Form.Item label="Email" name="email" rules={[{ required: false, type: "email" }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Phone Number (Mobile)" name="phoneNumberMobile" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Address" name="address">
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Bank Info">
            <Row>
              <Col span={6}>
                <Form.Item label="Bank Account" name="bankAccountNo" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Bank Name" name="bankName" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Bank Person In Charge Name" name="bankPicName" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Space>
      </Form>
    </Edit>
  );
};
