import { useSelect } from "@pankod/refine-antd";
import { CrudFilters } from "@pankod/refine-core";
import { ICaseTemplateType } from "interfaces";
import { PAGINATION_FIELDS } from "libs/constant";
import { RESOURCE_NAME as caseTemplateTypeResourceName } from "pages/case-template-types/constant";

export const useCaseTemplateTypeOptions = ({ branchId }: { branchId: string }) => {
  const filters = branchId ? [{ field: "branchId", operator: "eq", value: branchId }] : [];

  return useSelect<ICaseTemplateType>({
    resource: caseTemplateTypeResourceName,
    debounce: 500,
    metaData: {
      items: ["id", "name"],
      metadata: PAGINATION_FIELDS,
    },
    filters: [...filters] as CrudFilters,
    optionValue: "id",
    optionLabel: "name",
  });
};
