import { Authenticated, AuthProvider, Refine, useGetIdentity } from "@pankod/refine-core";
import { notificationProvider, ReadyPage, ErrorComponent, Layout } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { message } from "@pankod/refine-antd";

import "styles/antd.less";
import { Title, Header, Footer, Slider } from "components/layout";
import { gql, GraphQLClient, ClientError } from "@pankod/refine-graphql";

import { simplifyErrorMessage } from "libs";
import dataProvider from "dataProvider";
import { LoginPage } from "pages/login";
import { CaseTemplateTypeCreate, CaseTemplateTypeEdit, CaseTemplateTypeList } from "pages/case-template-types";
import { CaseTemplateList, CaseTemplateCreate, CaseTemplateEdit } from "pages/case-templates";
import { ClientTypeCreate, ClientTypeEdit, ClientTypeList } from "pages/client-types";
import { ClientCreate, ClientEdit, ClientList } from "pages/clients";
import { BranchCreate, BranchEdit, BranchList } from "pages/branches";
import  RedirectToMail from "pages/mail"

import {
  ApartmentOutlined,
  IdcardOutlined,
  InsuranceOutlined,
  MoneyCollectOutlined,
  ProjectOutlined,
  SisternodeOutlined,
  UsergroupAddOutlined,
  VerifiedOutlined,
  MailOutlined
} from "@ant-design/icons";
import { CaseCreate, CaseEdit, CaseList, CaseShow } from "pages/cases";
import { DashboardPage } from "pages/dashboard";
import { AdminUserCreate, AdminUserList, AdminUserEdit } from "pages/admin-users";
import { CaseProjectTypeCreate, CaseProjectTypeEdit, CaseProjectTypeList } from "pages/case-project-types";
import { AccountSettingEditProfile } from "./pages/account-setting/edit-profile";
import { CaseInvoiceList } from "pages/case-invoices/list";
import { LegalGoodList, LegalGoodEdit } from "pages/legal-goods";

const API_URL = process.env.REACT_APP_API_URL as string;
export const gqlClient = new GraphQLClient(API_URL);
export const gqlDataProvider = dataProvider(gqlClient);

export const TOKEN_KEY = "refine-auth";

export const isUnauthorizedError = (error: ClientError) =>
  (error?.response?.errors || []).some((err) => {
    return err?.extensions?.response?.statusCode === 401;
  });

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const mutation = gql`
        mutation AdminLogin($username: String!, $password: String!) {
          adminLogin(data: { username: $username, password: $password }) {
            accessToken
            refreshToken
          }
        }
      `;

      const response = await gqlClient.request(mutation, {
        username,
        password,
      });

      localStorage.setItem(TOKEN_KEY, JSON.stringify(response.adminLogin));

      gqlClient.setHeaders({
        Authorization: `Bearer ${response.adminLogin.accessToken}`,
      });

      message.success("Login success!");

      return Promise.resolve();
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || "Login failed!");
      return;
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    gqlClient.setHeaders({ Authorization: "" });
    return Promise.resolve();
  },
  checkError: (error) => {
    if (isUnauthorizedError(error)) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      const payload = JSON.parse(token);
      gqlClient.setHeaders({
        Authorization: `Bearer ${payload.accessToken}`,
      });
      return Promise.resolve();
    }

    localStorage.removeItem(TOKEN_KEY);
    gqlClient.setHeaders({ Authorization: "" });
    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    try {
      const token = localStorage.getItem(TOKEN_KEY);

      if (!token) {
        return Promise.reject();
      }

      const query = gql`
        query me {
          me {
            ... on AdminUser {
              id
              username
              firstName
              lastName
              email
              status
              branchId
              branch {
                id
                name
              }
              role {
                id
                name
                slug
              }
            }
          }
        }
      `;

      const response = await gqlClient.request(query);
      const sprites = "croodles";
      const username = response?.me?.username;

      const name =
        response?.me?.firstName && response?.me?.lastName
          ? `${response?.me?.firstName} ${response?.me?.lastName}`
          : username;

      return Promise.resolve({
        ...response?.me,
        name,
        avatar: `https://avatars.dicebear.com/api/${sprites}/${username}.svg`,
      });
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || "Login failed!");
      return;
    }
  },
};

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <Refine
      notificationProvider={notificationProvider}
      LoginPage={LoginPage}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      DashboardPage={DashboardPage}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: (
              <Authenticated>
                <AccountSettingEditProfile />
              </Authenticated>
            ),
            path: "/account-settings/profile",
            layout: true,
          },
        ],
      }}
      dataProvider={gqlDataProvider}
      Title={Title}
      Header={Header}
      Sider={Slider}
      Footer={Footer}
      Layout={Layout}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      reactQueryClientConfig={{
        defaultOptions: {
          mutations: {
            retry: 0,
          },
          queries: {
            retry: 0,
          },
        },
      }}
      resources={[
        {
          icon: <InsuranceOutlined />,
          name: "cases",
          options: { label: "Cases" },
          list: CaseList,
          edit: CaseEdit,
          create: CaseCreate,
          show: CaseShow,
        },
        {
          icon: <MoneyCollectOutlined />,
          name: "case-invoices",
          options: { label: "Invoices" },
          list: CaseInvoiceList,
        },
        {
          icon: <VerifiedOutlined />,
          name: "case-templates",
          options: { label: "Matters" },
          list: CaseTemplateList,
          edit: CaseTemplateEdit,
          create: CaseTemplateCreate,
        },
        {
          icon: <SisternodeOutlined />,
          name: "case-template-types",
          options: { label: "Case Types" },
          list: CaseTemplateTypeList,
          edit: CaseTemplateTypeEdit,
          create: CaseTemplateTypeCreate,
        },
        {
          icon: <ProjectOutlined />,
          name: "case-project-types",
          options: { label: "Projects" },
          list: CaseProjectTypeList,
          edit: CaseProjectTypeEdit,
          create: CaseProjectTypeCreate,
        },
        {
          icon: <UsergroupAddOutlined />,
          name: "clients",
          options: { label: "Clients" },
          list: ClientList,
          edit: ClientEdit,
          create: ClientCreate,
        },
        {
          icon: <IdcardOutlined />,
          name: "client-types",
          options: { label: "Client Types" },
          list: ClientTypeList,
          edit: ClientTypeEdit,
          create: ClientTypeCreate,
        },
        {
          icon: <MoneyCollectOutlined />,
          name: "Services",
          options: { label: "Services" },
          list: LegalGoodList,
          edit: LegalGoodEdit,
        },
        {
          icon: <ApartmentOutlined />,
          name: "admin-users",
          options: { label: "Users" },
          list: AdminUserList,
          create: AdminUserCreate,
          edit: AdminUserEdit,
        },

        {
          icon: <ApartmentOutlined />,
          name: "branches",
          options: { label: "Branch" },
          list: BranchList,
          create: BranchCreate,
          edit: BranchEdit,
        },
        {
          icon: <MailOutlined />,
          name: "mail",
          options: { label: "Mail" },
          list: RedirectToMail, 
          create: RedirectToMail,
          edit: RedirectToMail,
        }
      ]}
      accessControlProvider={{
        can: async ({ resource, action, params }) => {
          const token = localStorage.getItem(TOKEN_KEY);

          if (!token) {
            localStorage.removeItem(TOKEN_KEY);
            gqlClient.setHeaders({ Authorization: "" });
            return Promise.reject();
          }

          const query = gql`
            query me {
              me {
                ... on AdminUser {
                  id
                  role {
                    id
                    name
                    slug
                  }
                  branchId
                }
              }
            }
          `;

          try {
            const response = await gqlClient.request(query);

            if (resource === "branch" && response && response?.me?.role?.slug !== "admin") {
              return Promise.resolve({
                can: false,
                reason: "Unauthorized",
              });
            }

            if (resource === "admin-users" && response && response?.me?.role?.slug !== "admin") {
              return Promise.resolve({
                can: false,
                reason: "Unauthorized",
              });
            }

            return Promise.resolve({ can: true });
          } catch (error) {
            if (isUnauthorizedError(error as ClientError)) {
              localStorage.removeItem(TOKEN_KEY);
              gqlClient.setHeaders({ Authorization: "" });
            }
            return Promise.resolve({ can: true });
          }
        },
      }}
    />
  );
}

export default App;
