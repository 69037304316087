import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, Form, Input, useForm, InputNumber } from "@pankod/refine-antd";
import { useParams } from "react-router-dom";
import { mapValuesToSetter } from "libs";
import { FIND_FIRST_LEGAL_GOOD } from "components/legal-good/constant";

export const LegalGoodEdit: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();

  const { form, formProps, queryResult } = useForm({
    id: id,
    action: "edit",
    resource: "legalGoods",
    metaData: {
      fields: [...FIND_FIRST_LEGAL_GOOD],
    },
    redirect: false,
    successNotification: {
      type: "success",
      message: "Successfully edited Service",
    },
  });

  return (
    <Edit
      title="Edit"
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        disabled: queryResult?.isFetching,
        onClick: () => {
          form.submit();
        },
      }}
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues,
        }}
        layout="vertical"
        onFinish={(values: any) => {
          const fee = Math.floor(values.fee);
          const feeCent = +((values.fee - fee) * 100).toFixed(2);
          const otherCharge = Math.floor(values.otherCharge);
          const otherChargeCent = +((values.otherCharge - otherCharge) * 100).toFixed(2);

          const payload = mapValuesToSetter({
            ...values,
            fee,
            feeCent,
            otherCharge,
            otherChargeCent,
          });

          formProps?.onFinish?.({
            ...payload,
          });
        }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name={"description"}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Fee"
          name="fee"
          rules={[{ required: true, message: "Fee is required" }]}
          // normalize={(v) => v.toString()}
        >
          <InputNumber addonBefore="RM" controls={false} precision={2} />
        </Form.Item>
        <Form.Item
          label="Other Charge"
          name="otherCharge"
          rules={[{ required: true, message: "Other Charge is required" }]}
          // normalize={(v) => v.toString()}
        >
          <InputNumber addonBefore="RM" controls={false} precision={2} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
