import {
  ConditionalFilter,
  CrudFilter,
  CrudFilters,
  CrudSorting,
  DataProvider,
  LogicalFilter,
} from "@pankod/refine-core";
import { GraphQLClient } from "@pankod/refine-graphql";
import * as gql from "gql-query-builder";
import camelCase from "camelcase";
import { pascalCase } from "change-case";
import pluralize from "pluralize";
import { CRUDFILTER_OPERATOR_IS, CRUDFILTER_OPERATOR_OR, CRUDFILTER_OPERATOR_SOME } from "libs/constant";
const TOKEN_KEY = "thailegal-auth";
const token = localStorage.getItem(TOKEN_KEY);
const payload = token ? JSON.parse(token) : null;

const primaStringFilters = {
  eq: "equals",
  lt: "lt",
  gt: "gt",
  lte: "lte",
  gte: "gte",
  in: "in",
  nin: "notIn",
  contains: "contains",
  between: "between",
  nbetween: "false",
  nnull: "false",
  ne: "false",
  ncontains: "is",
  containss: "or", // note(roy): I have to borrow this as 'or', as the refine's op:'or' has bug.
  ncontainss: "some",
  null: "false",
};

const prismaInputType = {
  paginate: (name: string) => pascalCase(`${name}WhereUniqueInput`),
  findUnique: (name: string) => pascalCase(`${name}WhereUniqueInput`),
  findFirst: (name: string) => pascalCase(`${name}WhereInput`),
  findMany: (name: string) => pascalCase(`${name}WhereInput`),
  create: (name: string) => pascalCase(`${name}CreateInput`),
  createMany: (name: string) => pascalCase(`${name}CreateManyInput`),
  update: (name: string) => pascalCase(`${name}UpdateInput`),
  updateMany: (name: string) => pascalCase(`${name}UpdateManyInput`),
  orderBy: (name: string) => pascalCase(`${name}OrderByWithRelationInput`),
};

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const sortQuery = sort.map((i) => {
      return {
        [i.field]: i.order,
      };
    });

    return sortQuery;
  }

  return [];
};

// note(roy): refer to https://github.com/pankod/refine/blob/635cfe9fdbfe5940b950ae99c1f0b686c78bb8e5/packages/nestjsx-crud/src/index.ts
const generateFilter = (crudFilters?: CrudFilters): { [key: string]: any } => {
  let orFilter: { [key: string]: any }[] = [];
  let nonOrFilter: { [key: string]: any } = {};

  if (!crudFilters || crudFilters.length < 1) {
    return {};
  }
  crudFilters.forEach((crudFilter: CrudFilter) => {
    if (crudFilter.operator === CRUDFILTER_OPERATOR_OR) {
      orFilter = crudFilter.value.reduce((obj: any, filter: LogicalFilter) => {
        return [...obj, mapLogicalFilter(filter)];
      }, []);
    } else {
      nonOrFilter = { ...nonOrFilter, ...mapLogicalFilter(crudFilter as LogicalFilter) };
    }
  });

  return {
    ...nonOrFilter,
    ...(orFilter.length > 0
      ? {
          OR: orFilter, //Object.entries(orFilter).map(([k, v]) => ({ [k]: v })),
        }
      : {}),
  };
};

const mapLogicalFilter = (filter: LogicalFilter): { [field: string]: any } => {
  let mapped: { [field: string]: any } = {};
  if (filter.operator === "contains" && !!filter.value) {
    mapped[filter.field] = {
      [primaStringFilters[filter.operator]]: filter.value,
      mode: "insensitive",
    };
  } else if (
    (filter.operator === CRUDFILTER_OPERATOR_SOME || filter.operator === CRUDFILTER_OPERATOR_IS) &&
    !!filter.value
  ) {
    mapped[filter.field] = {
      [primaStringFilters[filter.operator]]: filter.value,
    };
  } else if (filter.operator === "eq") {
    let value = filter.value;

    if (filter.value === "true") {
      value = true;
    }

    if (filter.value === "false") {
      value = false;
    }

    mapped[filter.field] = {
      [primaStringFilters[filter.operator]]: value,
    };
  } else if (filter.operator === "in" && filter.value.length > 0) {
    mapped[filter.field] = {
      [primaStringFilters[filter.operator]]: filter.value,
    };
  } else if (filter.operator === "between" && filter.value.length === 2) {
    mapped[filter.field] = {
      gte: filter.value[0],
      lte: filter.value[1],
    };
  } else if (filter.operator === "gt" && !!filter.value) {
    mapped[filter.field] = {
      [primaStringFilters[filter.operator]]: filter.value,
    };
  } else if (filter.operator === "lt" && !!filter.value) {
    mapped[filter.field] = {
      [primaStringFilters[filter.operator]]: filter.value,
    };
  } else if (filter.operator === "ne") {
    mapped[filter.field] = {
      not: {
        equals: filter.value,
      },
    };
  }
  return mapped;
};

interface PrismaDataProvider extends DataProvider {}

const dataProvider = (client: GraphQLClient): PrismaDataProvider => {
  return {
    getList: async ({ resource, pagination, sort, filters, metaData }) => {
      const orderBy = generateSort(sort);
      const filterBy = generateFilter(filters);
      const operation = metaData?.operation || camelCase(`list_${resource}`);
      const singularResource = pluralize.singular(resource);
      const { query, variables } = gql.query({
        operation,
        variables: {
          page: pagination?.current || 1,
          perPage: pagination?.pageSize || 10,
          ...(orderBy.length > 0 && {
            orderBy: {
              value: orderBy,
              type: `[${prismaInputType.orderBy(singularResource)}!]`,
            },
          }),
          ...(Object.keys(filterBy).length > 0 && {
            where: {
              value: filterBy,
              type: prismaInputType.findMany(singularResource),
            },
          }),
        },
        fields: [
          {
            items: metaData?.items,
            metadata: metaData?.metadata,
          },
        ],
      });

      const response = await client.request(query, variables, {});

      return {
        data: response[operation].items || [],
        total: response[operation].metadata?.totalCount || 0,
      };
    },

    getMany: async ({ resource, ids, metaData }) => {
      const { operation: op, operationType, fields } = metaData;
      const singularResource = pluralize.singular(resource);
      const operation = op || camelCase(resource);

      const { query, variables } = gql.query({
        operation,
        variables: {
          where: {
            value: { id: { in: ids } },
            type: operationType || prismaInputType.findMany(singularResource),
          },
        },
        fields: fields || [],
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    getOne: async ({ resource, id, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(singularResource);

      const { query, variables } = gql.query({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields,
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    create: async ({ resource, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`create_${singularResource}`);

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          data: {
            value: variables,
            type: metaData?.operationType || prismaInputType.create(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ["id"],
      });

      const response = await client.request(query, gqlVariables);

      return {
        data: response[operation],
      };
    },

    createMany: async ({ resource, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(`create_many_${singularResource}`);

      const response = await Promise.all(
        variables.map(async (param) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { data: param },
                type: prismaInputType.createMany(singularResource),
              },
            },
            fields: metaData?.fields ?? ["id"],
          });
          const result = await client.request(query, gqlVariables);

          return result[operation];
        })
      );
      return {
        data: response,
      };
    },

    update: async ({ resource, id, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`update_${singularResource}`);

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
          data: {
            value: variables,
            type: metaData?.operationType || prismaInputType.update(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ["id"],
      });

      const response = await client.request(query, gqlVariables);

      return {
        data: response[operation],
      };
    },

    updateMany: async ({ resource, ids, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const camelUpdateName = camelCase(`update-${singularResource}`);

      const operation = metaData?.operation ?? camelUpdateName;

      const response = await Promise.all(
        ids.map(async (id) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { where: { id }, data: variables },
                type: prismaInputType.updateMany(singularResource),
              },
            },
            fields: metaData?.fields ?? ["id"],
          });
          const result = await client.request(query, gqlVariables);

          return result[operation];
        })
      );
      return {
        data: response,
      };
    },

    deleteOne: async ({ resource, id, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`delete_${singularResource}`);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ["id"],
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    deleteMany: async ({ resource, ids, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`delete_many_${singularResource}`);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: {
              id: { in: ids },
            },
            type: prismaInputType.findMany(singularResource),
            required: true,
          },
        },
        fields: ["count"],
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    getApiUrl: () => {
      throw Error("Not implemented on refine-graphql data provider.");
    },
    custom: async ({ url, method, headers, metaData }) => {
      let gqlClient = client;

      if (url) {
        gqlClient = new GraphQLClient(url, { headers });
      }

      if (metaData) {
        if (metaData.operation) {
          if (method === "get") {
            const { query, variables } = gql.query({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            });

            const response = await gqlClient.request(query, variables);

            return {
              data: response[metaData.operation],
            };
          } else {
            const { query, variables } = gql.mutation({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            });

            const response = await gqlClient.request(query, variables);

            return {
              data: response[metaData.operation],
            };
          }
        } else {
          throw Error("GraphQL operation name required.");
        }
      } else {
        throw Error("GraphQL need to operation, fields and variables values in metaData object.");
      }
    },
  };
};

export default dataProvider;
