import React, { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Tabs } from "@pankod/refine-antd";
import { CaseInvoiceNewList } from "./new-list";
import { CaseInvoiceLegacyList } from "./legacy-list";

export const CaseInvoiceList: React.FC<IResourceComponentsProps> = () => {
  return (
    <>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="New" key="1">
          <CaseInvoiceNewList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Legacy" key="2">
          <CaseInvoiceLegacyList />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
