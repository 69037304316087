export const LIST_INVOICES = [
  "id",
  "branchId",
  "recipient",
  "receiptNumber",
  "invoiceNumber",
  "createdAt",
  "updatedAt",
  "updatedAt",
  {
    legalCase: [
      "id",
      "branchId",
      "sequenceId",
      "bankName",
      "caseTemplateName",
      "caseTemplateTypeName",
      { caseProjectType: ["id", "name"] },
      { managedBy: ["id", "firstName", "lastName"] },
      { introducedBy: ["id", "firstName", "lastName", "fullName"] },
      { legalCaseClients: [{ client: ["firstName", "lastName", "fullName"] }] },
    ],
  },
  {
    chargeSummary: ["fee", "otherCharge", "subtotal", "sst", "total"],
  },
];

export const FIND_FIRST_INVOICE = [
  "id",
  "branchId",
  "recipient",
  "receiptNumber",
  "invoiceNumber",
  "createdAt",
  "updatedAt",
  {
    legalCase: [
      "id",
      "branchId",
      "sequenceId",
      "bankName",
      "caseTemplateName",
      "caseTemplateTypeName",
      "propertyAddress",
      { caseProjectType: ["id", "name"] },
      { managedBy: ["id", "firstName", "lastName"] },
      { introducedBy: ["id", "firstName", "lastName", "fullName"] },
      { legalCaseClients: [{ client: ["firstName", "lastName", "fullName"] }] },
    ],
  },
  {
    items: ["id", "name", "fee", "otherCharge", "createdAt", "updatedAt"],
  },
  {
    chargeSummary: ["fee", "otherCharge", "subtotal", "sst", "total"],
  },
  "purchasePrice",
  "loanAmount",
  "legalCaseMatterId",
  {
    client: [
      "oppositionId",
      {
        proposition: [
          "id",
          "firstName",
          "lastName",
          "fullName",
          "bankAccountNo",
          "bankName",
          "identificatioDocumentType",
          "identificatioDocumentNumber",
        ],
      },
      "propositionId",
      {
        opposition: [
          "id",
          "firstName",
          "lastName",
          "fullName",
          "bankAccountNo",
          "bankName",
          "identificatioDocumentType",
          "identificatioDocumentNumber",
        ],
      },
    ],
  },
  "particular",
];
