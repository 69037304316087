import { HttpError, IResourceComponentsProps, useGetIdentity, useList } from "@pankod/refine-core";
import { Edit, Form, Row, Col, Input, Select, useForm } from "@pankod/refine-antd";
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { useParams } from "react-router-dom";
import { mapValuesToSetter } from "../../libs";
import React from "react";
import { IBranch, ICaseProjectType } from "interfaces";
import { BRANCH_RESOURCE_NAME } from "pages/branches/constant";

export const CaseProjectTypeEdit: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const { data: user } = useGetIdentity();

  const { form, formProps, queryResult } = useForm<ICaseProjectType, HttpError, ICaseProjectType>({
    id: id,
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
    redirect: false,
  });

  const { data: branchList } = useList<IBranch>({
    resource: BRANCH_RESOURCE_NAME,
    metaData: {
      items: ["id", "name"],
      metadata: PAGINATION_FIELDS,
    },
    config: {
      pagination: {
        current: 1,
        pageSize: 20,
      },
    },
  });

  const branchOptions = (branchList?.data || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return (
    <Edit
      title="Edit"
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        disabled: queryResult?.isFetching,
        onClick: () => {
          form.submit();
        },
      }}
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={({ branch, ...values }) => {
          const payload = mapValuesToSetter({
            ...values,
          });

          formProps?.onFinish({
            ...payload,
            branch: {
              // @ts-ignore
              connect: {
                id: branch.id,
              },
            },
          });
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} md={24} lg={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Branch" name={["branch", "id"]} rules={[{ required: true }]}>
              <Select disabled={user?.branch} style={{ width: "100%" }}>
                {branchOptions.map((option, index) => (
                  <Select.Option key={`options${index}`} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
