import { Document, Font, Page, PDFViewer, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { PdfMaker } from "components/pdf";
import { formatMoney } from "libs/formatter";
import { ILegalCaseInvoice } from "interfaces";
import { reformatInvoiceNumber } from "libs";

Font.register({
  family: "Helvetica",
  fonts: [],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
});

/* 
  legal case matter id
  1 - Letter of Demand Cum Termination
  2 - Writ of Summons
  3 - Judgement in Default
  4 - Bankruptcy Notice
  5 - Creditor's Petition
  6 - Bankruptcy Order
  7 - Proof of Debt
*/

export const LitigationInvoice = ({
  dataProps,
}: {
  dataProps: ILegalCaseInvoice & { sst?: number; subtotal?: number };
  sequenceId?: string;
}) => {
  return (
    <>
      <PDFViewer style={{ width: "100%", height: "70vh" }}>
        <Document>
          <Page size="A4" style={styles.body}>
            <PdfMaker.View style={{ marginBottom: 10 }}>
              <PdfMaker.Text bold textAlign="center">
                THAI ADVOCATES
              </PdfMaker.Text>
              <PdfMaker.Text textAlign="center">Lot 917, Fortune Park Commercial Centre</PdfMaker.Text>
              <PdfMaker.Text textAlign="center">97000 Bintulu, Sarawak</PdfMaker.Text>
              <PdfMaker.Text textAlign="center">
                Tel : 086-319919 Fax : 086-319929 Email : alexwoo.ta@gmail.com
              </PdfMaker.Text>
            </PdfMaker.View>

            <PdfMaker.View style={{ marginBottom: 10 }}>
              <PdfMaker.Text bold underline textAlign="center">
                INVOICE
              </PdfMaker.Text>
            </PdfMaker.View>

            <PdfMaker.Table borderless>
              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="5%">
                  <PdfMaker.Text textAlign="center">To:</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="50%">
                  <PdfMaker.Text underline={!!dataProps?.recipient}>{dataProps?.recipient || "-"}</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text>Invoice No:</PdfMaker.Text>
                  <PdfMaker.Text>Invoice Date:</PdfMaker.Text>
                  <PdfMaker.Text>Our Ref :</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="30%">
                  <PdfMaker.Text>{reformatInvoiceNumber(dataProps?.invoiceNumber, dataProps?.createdAt)}</PdfMaker.Text>
                  <PdfMaker.Text>{dayjs(dataProps?.createdAt).format("DD/MM/YYYY")}</PdfMaker.Text>
                  <PdfMaker.Text>
                    {dataProps?.legalCase?.sequenceId}/{dataProps?.legalCase?.managedBy?.lastName}
                  </PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>

              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="5%"></PdfMaker.Td>
                <PdfMaker.Td width="50%">
                  <PdfMaker.Text>Particulars</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="center">Legal Fee</PdfMaker.Text>
                  <PdfMaker.Text textAlign="center">(RM)</PdfMaker.Text>
                </PdfMaker.Td>
                {/* <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="center">Stamp Duty</PdfMaker.Text>
                  <PdfMaker.Text textAlign="center">(RM)</PdfMaker.Text>
                </PdfMaker.Td> */}
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="center">Disbursements</PdfMaker.Text>
                  <PdfMaker.Text textAlign="center">(RM)</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="center">Total</PdfMaker.Text>
                  <PdfMaker.Text textAlign="center">(RM)</PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>

              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="100%"></PdfMaker.Td>
              </PdfMaker.Tr>

              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="5%"></PdfMaker.Td>
                <PdfMaker.Td width="95%">
                  <PdfMaker.Text bold>{dataProps?.legalCaseMatter?.name?.toUpperCase()}</PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>

              {![1].includes(dataProps?.legalCaseMatterId) && (
                <>
                  <PdfMaker.Tr borderless>
                    <PdfMaker.Td width="5%"></PdfMaker.Td>
                    <PdfMaker.Td width="95%">
                      <PdfMaker.Text>{dataProps?.particulars || ""}</PdfMaker.Text>
                    </PdfMaker.Td>
                  </PdfMaker.Tr>
                </>
              )}

              {/* 
                  1 -  Letter of Demand Cum Termination
                   */}
              {[1].includes(dataProps?.legalCaseMatterId) && (
                <>
                  <PdfMaker.Tr borderless>
                    <PdfMaker.Td width="5%"></PdfMaker.Td>
                    <PdfMaker.Td width="95%">
                      <PdfMaker.Text bold>BANK:</PdfMaker.Text>
                      <PdfMaker.Text bold>Name: {dataProps?.propositionClient?.fullName || "-"}</PdfMaker.Text>
                    </PdfMaker.Td>
                  </PdfMaker.Tr>

                  <PdfMaker.Tr borderless>
                    <PdfMaker.Td width="5%"></PdfMaker.Td>
                    <PdfMaker.Td width="95%">
                      <PdfMaker.Text bold>CUSTOMER:</PdfMaker.Text>
                      <PdfMaker.Text bold>Name: {dataProps?.oppositionClient?.fullName || "-"}</PdfMaker.Text>
                      <PdfMaker.Text bold>
                        Bank Account No: {dataProps?.oppositionClient?.bankAccountNo || "-"}
                      </PdfMaker.Text>
                      <PdfMaker.Text bold>Bank Name: {dataProps?.oppositionClient?.bankName || "-"}</PdfMaker.Text>
                      <PdfMaker.Text bold>
                        IC/Document No: {dataProps?.oppositionClient?.identificatioDocumentNumber || "-"}
                      </PdfMaker.Text>
                    </PdfMaker.Td>
                  </PdfMaker.Tr>
                </>
              )}
              {/* 
                  2 - Writ of Summons
                  3 - Judgement in Default
                   */}
              {[2, 3].includes(dataProps?.legalCaseMatterId) && (
                <>
                  <PdfMaker.Tr borderless>
                    <PdfMaker.Td width="5%"></PdfMaker.Td>
                    <PdfMaker.Td width="95%">
                      <PdfMaker.Text bold>PLAINTIFF:</PdfMaker.Text>
                      <PdfMaker.Text bold>Name: {dataProps?.propositionClient?.fullName || "-"}</PdfMaker.Text>
                    </PdfMaker.Td>
                  </PdfMaker.Tr>
                  <PdfMaker.Tr borderless>
                    <PdfMaker.Td width="5%"></PdfMaker.Td>
                    <PdfMaker.Td width="95%">
                      <PdfMaker.Text bold>DEFENDANT:</PdfMaker.Text>
                      <PdfMaker.Text bold>Name: {dataProps?.oppositionClient?.fullName || "-"}</PdfMaker.Text>
                      <PdfMaker.Text bold>
                        Bank Account No: {dataProps?.oppositionClient?.bankAccountNo || "-"}
                      </PdfMaker.Text>
                      <PdfMaker.Text bold>Bank Name: {dataProps?.oppositionClient?.bankName || "-"}</PdfMaker.Text>
                      <PdfMaker.Text bold>
                        IC/Document No: {dataProps?.oppositionClient?.identificatioDocumentNumber || "-"}
                      </PdfMaker.Text>
                    </PdfMaker.Td>
                  </PdfMaker.Tr>
                </>
              )}
              {/*
                  4 - Bankruptcy Notice
                  5 - Creditor's Petition
                  6 - Bankruptcy Order
                  7 - Proof of Debt
                  */}
              {[4, 5, 6, 7].includes(dataProps?.legalCaseMatterId) && (
                <>
                  <PdfMaker.Tr borderless>
                    <PdfMaker.Td width="5%"></PdfMaker.Td>
                    <PdfMaker.Td width="95%">
                      <PdfMaker.Text bold>JUDGEMENT CREDITOR:</PdfMaker.Text>
                      <PdfMaker.Text bold>{dataProps?.oppositionClient?.fullName || "-"}</PdfMaker.Text>
                    </PdfMaker.Td>
                  </PdfMaker.Tr>
                  <PdfMaker.Tr borderless>
                    <PdfMaker.Td width="5%"></PdfMaker.Td>
                    <PdfMaker.Td width="95%">
                      <PdfMaker.Text bold>JUDGEMENT DEBTOR:</PdfMaker.Text>
                      <PdfMaker.Text bold>Name: {dataProps?.propositionClient?.fullName || "-"}</PdfMaker.Text>
                      <PdfMaker.Text bold>
                        Bank Account No: {dataProps?.propositionClient?.bankAccountNo || "-"}
                      </PdfMaker.Text>
                      <PdfMaker.Text bold>Bank Name: {dataProps?.propositionClient?.bankName || "-"}</PdfMaker.Text>
                      <PdfMaker.Text bold>
                        IC/Document No: {dataProps?.propositionClient?.identificatioDocumentNumber || "-"}
                      </PdfMaker.Text>
                    </PdfMaker.Td>
                  </PdfMaker.Tr>
                </>
              )}
              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="5%">
                  <PdfMaker.Text></PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="50%">
                  <PdfMaker.Text italic>
                    TO OUR PROFESSIONAL CHARGES in connection with the above matter including (where necessary)
                    correspondences, attendances, perusals, examination of documents, searches, inquires and all
                    incidental work necessary for carrying out the business entrusted to us.
                  </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text style={{ marginTop: "auto" }} textAlign="center">
                    {formatMoney(dataProps?.legalFees)}
                  </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%"></PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text style={{ marginTop: "auto" }} textAlign="center">
                    {formatMoney(dataProps?.legalFees)}
                  </PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>

              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="5%"></PdfMaker.Td>
                <PdfMaker.Td width="50%"></PdfMaker.Td>
                <PdfMaker.Td width="15%"></PdfMaker.Td>
                {/* <PdfMaker.Td width="15%">
                  <PdfMaker.Text style={{ marginTop: "auto" }} textAlign="center">
                    {formatMoney(dataProps?.stampDuty)}
                  </PdfMaker.Text>
                </PdfMaker.Td> */}
                <PdfMaker.Td width="15%">{/* <PdfMaker.Text></PdfMaker.Text> */}</PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  {/* <PdfMaker.Text style={{ marginTop: "auto" }} textAlign="center">
                    {formatMoney(dataProps?.stampDuty)}
                  </PdfMaker.Text> */}
                </PdfMaker.Td>
              </PdfMaker.Tr>

              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="5%"></PdfMaker.Td>
                <PdfMaker.Td width="50%">
                  <PdfMaker.Text bold>Disbursements: - </PdfMaker.Text>
                  <PdfMaker.Text>
                    E-filling, serving & collecting of documents, telephone & facsimile expenses, photocopy & printing
                    expenses, other sundry expenses, etc,
                  </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%"></PdfMaker.Td>
                {/* <PdfMaker.Td width="15%"></PdfMaker.Td> */}
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text style={{ marginTop: "auto" }} textAlign="center">
                    {formatMoney(dataProps?.disbursement)}
                  </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text style={{ marginTop: "auto" }} textAlign="center">
                    {formatMoney(dataProps?.disbursement)}
                  </PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>

              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="85%">
                  <PdfMaker.Text textAlign="right">SUB-TOTAL</PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="center">{formatMoney(dataProps?.subtotal)}</PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>

              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="85%">
                  <PdfMaker.Text textAlign="right" bold>
                    SST (8%)
                  </PdfMaker.Text>
                </PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="center" bold>
                    {formatMoney(dataProps?.sst)}
                  </PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>

              <PdfMaker.Tr borderless>
                <PdfMaker.Td width="85%"></PdfMaker.Td>
                <PdfMaker.Td width="15%">
                  <PdfMaker.Text textAlign="center" bold underline>
                    {formatMoney(dataProps?.totalAmount)}
                  </PdfMaker.Text>
                </PdfMaker.Td>
              </PdfMaker.Tr>

              <PdfMaker.Tr borderless style={{ marginTop: "40px" }}>
                <PdfMaker.Td width="5%"></PdfMaker.Td>
                <PdfMaker.Td width="95%">
                  <PdfMaker.View
                    style={{
                      height: "20px",
                      borderBottom: "1px solid black",
                      width: "150px",
                    }}
                  />
                  <PdfMaker.View style={{ height: "12px" }} />
                  <PdfMaker.Text bold>THAI ADVOCATES</PdfMaker.Text>
                  <PdfMaker.View style={{ height: "12px" }} />
                  <PdfMaker.Text>Note: This invoice is expressly subject to the following terms: -</PdfMaker.Text>
                  {[
                    "E. & O.E.",
                    "Outstation cheques should include Bank Commission",
                    "No receipt is considered valid unless on the firm's official receipt.",
                    "Interest @ 14% p.a. pursuant to Section 6 of the Advocates Remuneration Rules 1988 will be chargeable after thirty (30) days of receipt of this invoice if invoice remains unpaid.",
                    "Payment to be made to  'THAI ADVOCATES' Public Bank account number: 3987068528",
                    "Please provide us with the bank in slip after payment made",
                  ].map((o, index) => (
                    <PdfMaker.Text key={index}>
                      {++index}. {o}
                    </PdfMaker.Text>
                  ))}
                </PdfMaker.Td>
              </PdfMaker.Tr>
            </PdfMaker.Table>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};
