import { useEffect, useMemo } from "react";
import { Typography } from "@pankod/refine-antd";
import { Column } from "@ant-design/charts";
import { ColumnConfig } from "@ant-design/plots/lib/components/column";
import { IncreaseIcon, DecreaseIcon } from "components/icons";

import "./style.less";
import { useCustom } from "@pankod/refine-core";

interface DepartmentTypeChartProps {
  title: string;
  branchId?: string;
  department?: string[];
  status?: string[];
  year?: Number;
}

export const DepartmentTypeChart: React.FC<DepartmentTypeChartProps> = ({ title, branchId, department, status, year }) => {
  const { Text, Title } = Typography;

  const {data: response, refetch} = useCustom({
    url: "",
    method: "get",
    metaData: {
      operation: "legalCaseDepartmentAnalytics",
      variables: {
        where: {
          value: {
            year: year || new Date().getFullYear(),
            branchId: branchId ?? undefined,
            status: { in: status ?? [] },
          },
          type: "LegalCasesForChartWhereInput",
          required: true,
        },
      },
      fields: [
        "count",
        {
          items: ["department", "value", "month"],
        },
      ],
    },
    queryOptions: {
      onError(error) {
        console.log("fetch data failed", error);
      },
    },
  });

  const { data, totalCount } = useMemo(() => {
    let data = [];
    let totalCount = 0;

    if (response?.data) {
      const { count, items } = response.data as any;

      data = items ?? [];
      totalCount = count ?? 0;
    }

    return {
      data,
      totalCount,
    };
  }, [response?.data]);

  const config = useMemo(() => {
    const config: ColumnConfig = {
      data,
      loading: false,
      xField: "month",
      yField: "value",
      seriesField: "department",
      isGroup: true,
      legend: {
        position: "right-top",
        title: {
          text: "Department",
          spacing: 8,
        },
      },
    };

    return config;
  }, [data]);

  useEffect(() =>{
    refetch();
  }, [year])

  return (
    <div className="monthly-case-wrapper">
      <div className="title-area">
        <Title level={3}>{title}</Title>
        <div className="title-area__number">
          <Text strong>{totalCount} </Text>

          {totalCount ? <IncreaseIcon /> : <DecreaseIcon />}
        </div>
      </div>
      <Column style={{ padding: 0, height: 535, overflow: 'hidden' }} appendPadding={10} {...config} />
    </div>
  );
};
