import { useSelect } from "@pankod/refine-antd";
import { CrudFilters } from "@pankod/refine-core";
import { IBranch } from "interfaces";
import { PAGINATION_FIELDS } from "libs/constant";
import { RESOURCE_NAME as branchResourceName } from "pages/branches/constant";

export const useBranchOptions = ({ branchId }: { branchId: string }) => {
  const filters = branchId ? [{ field: "id", operator: "eq", value: branchId }] : [];

  return useSelect<IBranch>({
    resource: branchResourceName,
    debounce: 500,
    metaData: {
      method: "get",
      items: ["id", "name"],
      metadata: PAGINATION_FIELDS,
    },
    filters: [...filters] as CrudFilters,
    fetchSize: 200, // TODO: dirty fix for now
    optionValue: "id",
    optionLabel: "name",
  });
};
