import { Form, FormProps, Input, Modal, ModalProps } from "@pankod/refine-antd";
import { ILegalCase } from "interfaces";

type LegalCaseStageModalProps = {
  legalCase: ILegalCase;
  nextSequenceNo: number;
  modalProps: ModalProps;
  formProps: FormProps;
};
const LegalCaseStageModal: React.FC<LegalCaseStageModalProps> = ({
  legalCase,
  nextSequenceNo,
  modalProps,
  formProps,
}: LegalCaseStageModalProps) => {
  return (
    <Modal {...modalProps} title="Add Stage" width={600}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          formProps.onFinish({
            ...values,
            sequenceNo: nextSequenceNo,
            legalCase: { connect: { id: legalCase.id } },
            createdAt: legalCase.createdAt,
            updatedAt: legalCase.updatedAt,
          });
        }}
      >
        <Form.Item label="Stage Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Message Template" name="messageTemplate" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LegalCaseStageModal;
