import {
  HttpError,
  IResourceComponentsProps,
  useGetIdentity,
  useRedirectionAfterSubmission,
  useResourceWithRoute,
} from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  useForm,
  Row,
  Col,
  Breadcrumb,
  Select,
  Card,
  Divider,
  Table,
  Space,
  Button,
  useModalForm,
  Switch,
  Typography,
  Dropdown,
  Icons,
  Menu,
  DatePicker,
} from "@pankod/refine-antd";
import React, { useEffect, useState } from "react";
import { IClient, ILegalCase, ILegalCaseCreateInput } from "interfaces";
import { DeleteOutlined, EditOutlined, FileAddOutlined } from "@ant-design/icons";
import { ClientModalForm } from "components/legal-client/client-modal-form";
import { cloneDeep, startCase } from "lodash";
import { ClientRemarkModalForm } from "components/legal-client/client-remark-modal-form";
import { useClientOptions } from "hooks/use-client-options";
import { LegalCaseClientType, LEGAL_CASE_CLIENT_RESOURCE } from "./constant";
import { BANK_DISPLAY_NAMES } from "libs/constant";
import { useBranchOptions } from "hooks/use-branch-options";
import { useCaseTemplateTypeOptions } from "hooks/use-case-template-type-options";
import { useCaseProjectTypeOptions } from "hooks/use-case-project-type-options";
import { useCaseTemplateOptions } from "hooks/use-case-templete-options";
import { useAdminOptions } from "hooks/use-admin-options";
import dayjs from "dayjs";

const responsiveCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 6,
};

const { RangePicker } = DatePicker;

export type CreateLegalCaseClient = IClient & {
  legalCaseClient: {
    key: string;
    remarks?: string;
    type?: LegalCaseClientType | null;
  };
};

export const CaseCreate: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const resourceWithRoute = useResourceWithRoute();
  const resource = resourceWithRoute("cases");
  const handleSubmitWithRedirect = useRedirectionAfterSubmission();

  const [showPropertyAddressField, setShowPropertyAddressField] = useState(false);

  const { formProps, saveButtonProps, mutationResult } = useForm<ILegalCase, HttpError, ILegalCaseCreateInput>({
    action: "create",
    resource: "MinimalLegalCase",
    redirect: false,
    onMutationSuccess: () => {
      handleSubmitWithRedirect({
        redirect: "list",
        resource: resource,
      });
    },
  });
  const caseTemplateTypeId = Form.useWatch("caseTemplateTypeId", formProps.form);

  const branchId = Form.useWatch("branchId", formProps.form);

  const isBranchSelected = !!branchId;

  // reset form when branch is changed
  useEffect(() => {
    formProps.form.resetFields([
      "caseTemplateTypeId",
      "caseTemplateId",
      "caseProjectTypeId",
      "introducedByClientId",
      "managedByAdminId",
      "lawyeredByAdminId",
    ]);
    setClients([]);
  }, [branchId, formProps.form]);

  const branchOptions = useBranchOptions({
    branchId,
  });
  const caseTemplateTypeOptions = useCaseTemplateTypeOptions({
    branchId,
  });
  const caseTemplateOptions = useCaseTemplateOptions({
    branchId,
    caseTemplateTypeId,
  });
  const caseProjectTypeOptions = useCaseProjectTypeOptions({
    branchId,
  });
  const clientOptions = useClientOptions({
    branchId,
  });
  const adminOptions = useAdminOptions({
    branchId,
  });

  const [clients, setClients] = useState<Array<CreateLegalCaseClient>>([]);

  const addClient = (value: IClient) => {
    if (clients.some((client) => client.id === value.id)) {
      return;
    }
    setClients((prev) => [
      ...prev,
      {
        ...value,
        legalCaseClient: {
          key: value.id,
          remarks: "",
          type: LegalCaseClientType.PARTY_1,
        },
      },
    ]);
  };
  const updateClient = (value: IClient) => {
    setClients((prev) => {
      const records = cloneDeep(prev);
      const index = records.findIndex((client) => client.id === value.id);
      records[index] = {
        ...value,
        legalCaseClient: {
          key: value.id,
          remarks: "",
          type: null,
          ...records[index].legalCaseClient,
        },
      };
      return records;
    });
  };
  const removeClient = (value: CreateLegalCaseClient) => {
    setClients((prev) => {
      return prev.filter(({ id }) => id !== value.id);
    });
  };

  const { show: showCreate, ...createFromProps } = useModalForm<IClient>({
    action: "create",
    resource: LEGAL_CASE_CLIENT_RESOURCE.name,
    autoSubmitClose: true,
    redirect: false,
    onMutationSuccess: () => {
      clientOptions.queryResult.refetch();
    },
  });
  const { show: showEdit, ...editFromProps } = useModalForm<IClient>({
    action: "edit",
    resource: LEGAL_CASE_CLIENT_RESOURCE.name,
    metaData: {
      fields: [...LEGAL_CASE_CLIENT_RESOURCE.fields],
    },
    autoSubmitClose: true,
    redirect: false,
    onMutationSuccess: (record) => {
      clientOptions.queryResult.refetch();
      updateClient(record.data);
    },
  });

  const [editRecord, setEditRecord] = useState<CreateLegalCaseClient>(null);
  const showEditRemarks = (record: CreateLegalCaseClient) => setEditRecord(record);
  const handleCancelEditRemarks = () => setEditRecord(null);

  const onEditRemarks = (value: { id: string; remarks: string }) => {
    setClients((prev) => {
      const records = cloneDeep(prev);
      const index = records.findIndex((client) => client.id === value.id);
      records[index].legalCaseClient.remarks = value.remarks;
      return records;
    });
    setEditRecord(null);
  };
  const onEditType = (value: { id: string; type: LegalCaseClientType }) => {
    setClients((prev) => {
      const records = cloneDeep(prev);
      const index = records.findIndex((client) => client.id === value.id);
      records[index].legalCaseClient.type = value.type;
      return records;
    });
  };

  const menu = (record: CreateLegalCaseClient) => (
    <Menu
      items={Object.values(LegalCaseClientType).map((type) => ({
        key: type,
        label: (
          <Button
            type="link"
            onClick={() => {
              onEditType({ id: record.id, type });
            }}
          >
            {startCase(type)}
          </Button>
        ),
      }))}
    />
  );

  const [searchClientForm] = Form.useForm();

  return (
    <>
      <ClientModalForm
        action="create"
        values={undefined}
        modalProps={createFromProps.modalProps}
        formProps={createFromProps.formProps}
      />
      <ClientModalForm
        action="edit"
        values={editFromProps.queryResult?.data?.data}
        modalProps={editFromProps.modalProps}
        formProps={editFromProps.formProps}
      />
      <ClientRemarkModalForm
        title="Client Remarks"
        record={editRecord}
        onFinish={onEditRemarks}
        onCancel={handleCancelEditRemarks}
      />

      <Create
        isLoading={mutationResult?.isLoading}
        saveButtonProps={{
          ...saveButtonProps,
        }}
        pageHeaderProps={{
          title: "Add New Case",
          breadcrumb: <Breadcrumb hideIcons />,
        }}
      >
        <Form
          {...formProps}
          initialValues={{
            enabledSms: false,
            enabledEmail: false,
            // managedByAdminId: user?.id,
            branchId: user?.branchId || null,
          }}
          layout="vertical"
          onFinish={({ propertyAddress, isWaitingForTitle, ...values }) => {
            formProps.onFinish({
              ...values,
              dueAt: values?.dueAt ? dayjs(values?.dueAt).startOf("day").toDate() : null,
              propertyAddress: showPropertyAddressField ? propertyAddress : null,
              isWaitingForTitle: showPropertyAddressField ? isWaitingForTitle : false,
              legalCaseClients: clients.map(({ id, legalCaseClient }) => ({
                clientId: id,
                remarks: legalCaseClient?.remarks || "",
                type: legalCaseClient?.type,
              })),
            });
          }}
        >
          <Card title="General Info">
            <Row gutter={[24, 0]}>
              <Col {...responsiveCol}>
                <Form.Item label="Branch" name="branchId" rules={[{ required: true }]}>
                  <Select {...branchOptions.selectProps} allowClear={false} />
                </Form.Item>
              </Col>
              <Col {...responsiveCol}>
                <Form.Item label="Case Type" name="caseTemplateTypeId" rules={[{ required: true }]}>
                  <Select {...caseTemplateTypeOptions.selectProps} disabled={!isBranchSelected} />
                </Form.Item>
              </Col>
              <Col {...responsiveCol}>
                <Form.Item label="Matter" name="caseTemplateId" rules={[{ required: true }]}>
                  <Select {...caseTemplateOptions.selectProps} disabled={!isBranchSelected} />
                </Form.Item>
              </Col>
              <Col {...responsiveCol}>
                <Form.Item label="Project Type" name="caseProjectTypeId" rules={[{ required: true }]}>
                  <Select {...caseProjectTypeOptions.selectProps} disabled={!isBranchSelected} />
                </Form.Item>
              </Col>
              <Col {...responsiveCol}>
                <Form.Item label="Bank Name" name="bankName" rules={[{ required: false }]}>
                  <Select allowClear={true}>
                    {BANK_DISPLAY_NAMES.map((option, index) => (
                      <Select.Option key={`bank${index}`} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col {...responsiveCol}>
                <Form.Item label="Introducer" name="introducedByClientId" rules={[{ required: false }]}>
                  <Select {...clientOptions.selectProps} disabled={!isBranchSelected} />
                </Form.Item>
              </Col>
              <Col {...responsiveCol}>
                <Form.Item label="Managed By" name="managedByAdminId" rules={[{ required: true }]}>
                  <Select
                    {...adminOptions?.selectProps}
                    options={adminOptions?.queryResult?.data?.data?.map((o) => ({
                      label: [o.firstName, o.lastName].filter(Boolean).join(" "),
                      value: o.id,
                    }))}
                    disabled={!isBranchSelected}
                  />
                </Form.Item>
              </Col>
              <Col {...responsiveCol}>
                <Form.Item label="Lawyer" name="lawyeredByAdminId" rules={[{ required: true }]}>
                  <Select
                    {...adminOptions?.selectProps}
                    options={adminOptions?.queryResult?.data?.data?.map((o) => ({
                      label: [o.firstName, o.lastName].filter(Boolean).join(" "),
                      value: o.id,
                    }))}
                    disabled={!isBranchSelected}
                  />
                </Form.Item>
              </Col>
              <Col {...responsiveCol}>
                <Form.Item label="Due At" name="dueAt">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Remarks" name="remarks">
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col span={24}>
                <Form.Item label="Has Property?">
                  <Switch
                    onChange={(enabled) => {
                      setShowPropertyAddressField(enabled);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Property Address"
                  name="propertyAddress"
                  hidden={!showPropertyAddressField}
                  rules={[{ required: showPropertyAddressField }]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col span={12}>
                <Form.Item
                  label="Party 1  Description"
                  name={["documentVariables", "proposition"]}
                  rules={[
                    {
                      required: true,
                      message: "Party 1 Description is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Party 2 Description"
                  name={["documentVariables", "opposition"]}
                  rules={[
                    {
                      required: true,
                      message: "Party 2 Description is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Divider />

          <Card title="Bank Info">
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item label="Banker Name" name="bankerName" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item
                  label="Banker Mobile Number"
                  name="bankerMobileNumber"
                  rules={[
                    { required: false },
                    {
                      pattern: /^[0-9\b]+$/,
                      message: "Invalid character",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item
                  label="Amount (RM)"
                  name="amount"
                  rules={[
                    { required: false },
                    {
                      pattern: /^[0-9\b]+$/,
                      message: "Invalid character",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>

        <Divider />

        <Card title="Client Info">
          <Row gutter={[24, 24]}>
            <Col {...responsiveCol} xl={24}>
              <Form
                form={searchClientForm}
                onFinish={() => {
                  searchClientForm.resetFields();
                }}
              >
                <Form.Item name="search">
                  <Select
                    {...clientOptions.selectProps}
                    style={{ width: "100%" }}
                    allowClear={true}
                    disabled={!isBranchSelected}
                    onChange={(id) => {
                      if (!id) {
                        return;
                      }
                      const result = clientOptions.queryResult?.data?.data || [];
                      const client = result.find((o) => o.id === (id as unknown as string));
                      addClient(client);
                      searchClientForm.submit();
                    }}
                    notFoundContent={
                      <Space>
                        <Typography.Text>No client found</Typography.Text>
                        <Button type="primary" onClick={() => showCreate()}>
                          Add Client
                        </Button>
                      </Space>
                    }
                  />
                </Form.Item>
              </Form>
            </Col>

            <Col {...responsiveCol} xl={24}>
              <Table dataSource={clients} pagination={false} rowKey="id">
                <Table.Column dataIndex="firstName" title="First Name" />
                <Table.Column dataIndex="lastName" title="Last Name" />
                <Table.Column dataIndex="email" title="Email" />
                <Table.Column dataIndex={["clientType", "name"]} title="Client Type" />
                <Table.Column dataIndex="phoneNumberMobile" title="Mobile Number" />
                <Table.Column dataIndex={["legalCaseClient", "remarks"]} title="Remarks" />
                <Table.Column<CreateLegalCaseClient>
                  width="10%"
                  dataIndex="type"
                  title="Type"
                  render={(_, record) => (
                    <Space>
                      <Dropdown overlay={() => menu(record)}>
                        <Button type="link" onClick={(e) => e.preventDefault()}>
                          <Space>
                            {startCase(record?.legalCaseClient?.type ?? "Select type")}
                            <Icons.DownOutlined />
                          </Space>
                        </Button>
                      </Dropdown>
                    </Space>
                  )}
                />
                <Table.Column<CreateLegalCaseClient>
                  width="5%"
                  key="actions"
                  title={"Actions"}
                  dataIndex="actions"
                  render={(_, record) => (
                    <Space>
                      <Button icon={<FileAddOutlined />} onClick={() => showEditRemarks(record)} />
                      <Button icon={<EditOutlined />} onClick={() => showEdit(record.id)} />
                      <Button danger icon={<DeleteOutlined />} onClick={() => removeClient(record)} />
                    </Space>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </Card>
      </Create>
    </>
  );
};
