import { useSelect } from "@pankod/refine-antd";
import { IAdminUser } from "interfaces";
import { CRUDFILTER_OPERATOR_OR, PAGINATION_FIELDS } from "libs/constant";

export const ROLE_ID_ADMIN = "1";
export const ROLE_ID_SUPERVISOR = "2";
export const ROLE_ID_STAFF = "3";
export const ROLE_ID_LAWYER = "4";

export const useAdminOptions = ({ branchId }: { branchId: string }) => {
  return useSelect<IAdminUser>({
    resource: "adminUsers",
    debounce: 500,
    onSearch: (value) => [
      {
        operator: CRUDFILTER_OPERATOR_OR,
        field: "",
        value: [
          {
            field: "email",
            operator: "contains",
            value: value,
          },
          {
            field: "firstName",
            operator: "contains",
            value: value,
          },
          {
            field: "lastName",
            operator: "contains",
            value: value,
          },
        ],
      },
    ],
    metaData: {
      method: "get",
      items: ["id", "firstName", "lastName"],
      metadata: PAGINATION_FIELDS,
    },
    filters: branchId ? [{ field: "branchId", operator: "eq", value: branchId }] : undefined,
    fetchSize: 200, // TODO: dirty fix for now
  });
};
