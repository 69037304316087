import { CrudFilters, CrudSort, IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";
import { List, Table, Row, Col, Space, useTable, EditButton } from "@pankod/refine-antd";
import { LIST_LEGAL_GOODS } from "components/legal-good/constant";
import { PAGINATION_FIELDS } from "libs/constant";

export const LegalGoodList: React.FC<IResourceComponentsProps> = () => {
  const initialSorter: CrudSort[] = [{ field: "updatedAt", order: "desc" }];
  const initialPageSize = 30;
  const permanentFilter = [];

  const { tableProps } = useTable({
    resource: "legalGoods",
    metaData: {
      items: LIST_LEGAL_GOODS,
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter: permanentFilter as CrudFilters,
    initialSorter,
    initialPageSize,
  });

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="name" title="Name" />
            <Table.Column
              dataIndex="fee"
              title="Fee"
              render={(value) => {
                return `RM ${value}`;
              }}
            />
            <Table.Column
              dataIndex="otherCharge"
              title="Other Charge"
              render={(value) => {
                return `RM ${value}`;
              }}
            />
            <Table.Column<any>
              width="5%"
              key="actions"
              title={"Actions"}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
