import { message } from "@pankod/refine-antd";
import { gqlDataProvider } from "App";
import { LegalCaseStageStatusType } from "interfaces";
import { simplifyErrorMessage } from "libs";

type Props = {
  onMutationSuccess: () => void;
};

// note(roy): for custom mutations/queries.
// We could instead use code-gen for this
export const useUpdateLegalCaseStageStatusMutation = ({ onMutationSuccess }: Props) => {
  return {
    mutate: async (id: string, status: LegalCaseStageStatusType) => {
      try {
        const { data } = await gqlDataProvider.custom!({
          url: "",
          method: "post",
          metaData: {
            operation: "updateLegalCaseStageStatus",
            variables: {
              data: {
                value: {
                  id,
                  status,
                },
                type: "UpdateLegalCaseStageStatusInput",
                required: true,
              },
            },
            fields: ["id"],
          },
        });

        if (data.id) {
          onMutationSuccess();
          return data.id;
        }
      } catch (error) {
        const errorMessage = simplifyErrorMessage(error);
        message.error(errorMessage || "Unable to update case stage!");
        return;
      }
    },
  };
};
