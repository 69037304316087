import React from "react";
import { IResourceComponentsProps, CrudFilters, CrudSort, HttpError, useGetIdentity } from "@pankod/refine-core";
import { List, Table, Row, Col, Space, useTable, EditButton, CreateButton } from "@pankod/refine-antd";
import { RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { IBranch, IBranchFilterVariables } from "interfaces";
import { PAGINATION_FIELDS } from "libs/constant";

export const BranchList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const initialSorter: CrudSort[] = [{ field: "id", order: "asc" }];
  const initialPageSize = 10;
  const permanentFilter: CrudFilters = [
    {
      field: "id",
      operator: "eq",
      value: user?.branchId,
    },
  ];

  const { tableProps } = useTable<IBranch, HttpError, IBranchFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter,
    initialSorter,
    initialPageSize,
    queryOptions: {
      enabled: !!user,
    },
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { name } = params;

      filters.push({
        field: "name",
        operator: "contains",
        value: name,
      });

      return filters;
    },
  });

  const Actions: React.FC = () => (
    <Space>
      <CreateButton />
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <List
          pageHeaderProps={{
            extra: user?.branchId ? null : <Actions />,
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="name" title="Name" />
            <Table.Column dataIndex="totalAdminUsers" title="Total User" />
            <Table.Column<IBranch>
              fixed="right"
              width="5%"
              key="actions"
              title={"Actions"}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
