import React, { useMemo } from "react";

import { Alert, Button, Modal, Table, useModal, Typography } from "@pankod/refine-antd";
import { ILegalCase } from "interfaces";
import { useNavigation } from "@pankod/refine-core";
import { useListInactiveLegalCaseByRole } from "hooks/use-list-inactive-legal-case-by-role";
import dayjs from "dayjs";
import { CRUDFILTER_OPERATOR_OR } from "libs/constant";

const { Text, Paragraph } = Typography;

interface NotificationProps {
  branchId?: string;
}

export const Notification = (props: NotificationProps) => {
  const { edit } = useNavigation();

  const { modalProps, show, close } = useModal();

  const { tableProps, tableQueryResult } = useListInactiveLegalCaseByRole({
    initialPageSize: 5,
    permanentFilter: [
      {
        operator: CRUDFILTER_OPERATOR_OR,
        field: "",
        value: [
          {
            field: "status",
            operator: "in",
            value: ["NEW", "IN_PROGRESS"],
          },
          {
            field: "dueAt",
            operator: "gt",
            value: useMemo(() => dayjs().toISOString(), []),
          },
        ],
      },
      {
        field: "branchId",
        operator: "eq",
        value: props.branchId,
      },
    ],
  });

  return (
    <>
      <Modal
        title="Pending Resolve Cases"
        {...modalProps}
        width={800}
        footer={[
          <Button key="close" onClick={close}>
            Close
          </Button>,
        ]}
      >
        <Table {...tableProps} pagination={{ ...tableProps.pagination, simple: true }} showHeader={true} rowKey="id">
          <Table.Column<ILegalCase>
            title="Case Identity"
            render={(_, record) => (
              <>
                <Text strong>{record.caseTemplateTypeName}</Text>
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    symbol: <span>...</span>,
                  }}
                >
                  <Text strong>
                    #{record.sequenceId}/{record?.managedBy.lastName}
                  </Text>{" "}
                  {record.caseTemplateName}
                </Paragraph>
              </>
            )}
          />
          <Table.Column<ILegalCase>
            title="Status"
            dataIndex="status"
            key="status"
            render={(value) => <Text>{value}</Text>}
          />
          <Table.Column<ILegalCase>
            fixed="right"
            key="actions"
            align="center"
            width="15%"
            render={(_, record) => (
              <>
                <Button
                  type="ghost"
                  size="small"
                  onClick={() => {
                    edit("cases", record.id);
                  }}
                >
                  View
                </Button>
              </>
            )}
          />
        </Table>
      </Modal>

      {tableQueryResult.isSuccess && tableQueryResult.data?.total > 0 && (
        <Alert
          message="Notice"
          description={`You have ${tableQueryResult?.data?.total || 0} cases pending to resolve.`}
          type="info"
          action={
            <Button size="small" type="ghost" onClick={show}>
              View
            </Button>
          }
        />
      )}
    </>
  );
};
