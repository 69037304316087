import { Col, DateField, Row, Space } from "@pankod/refine-antd";

export const CaseDetailsDescription = ({ queryResult }) => {
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Row>
        <Col flex="1 0 25%">
          <strong>File No.</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data.sequenceId}/{queryResult?.data?.data.managedBy.lastName}</span>
        </Col>

        <Col flex="1 0 25%">
          <strong>Date</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <DateField format="LLL" value={queryResult?.data?.data.createdAt} />
        </Col>
      </Row>

      <Row>
        <Col>
          <strong>Case Type</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data.caseTemplateTypeName}</span>
        </Col>
      </Row>

      <Row>
        <Col>
          <strong>Matter</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data.caseTemplateName}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Project</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data.caseProjectTypeName ?? "N/A"}</span>
        </Col>
      </Row>

      <Row>
        <Col flex="1 0 25%">
          <strong>Introducer</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data?.introducedBy?.fullName}</span>
        </Col>
        <Col flex="1 0 25%">
          <strong>Bank Name</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data.bankName}</span>
        </Col>
      </Row>

      <Row>
        <Col flex="1 0 25%">
          <strong>Managed By</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data.managedBy?.fullName}</span>
        </Col>
        <Col flex="1 0 25%">
          <strong>Lawyer</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data?.lawyeredBy?.fullName}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Remarks</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data.remarks}</span>
        </Col>
      </Row>
      <Row>
        <Col flex="1 0 25%">
          <strong>Waiting For Title</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data.isWaitingForTitle ? "Yes" : "No"}</span>
        </Col>
      </Row>
      <Row>
        <Col flex="1 0 25%">
          <strong>Party 1 Description</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data.documentVariables?.proposition}</span>
        </Col>
      </Row>
      <Row>
        <Col flex="1 0 25%">
          <strong>Party 2 Description</strong>
          <span style={{ paddingRight: "10px", paddingLeft: "2px" }}>:</span>
          <span>{queryResult?.data?.data?.documentVariables?.opposition}</span>
        </Col>
      </Row>

      <Row>
        <Col>
          <strong>Banker Name: </strong>
          {queryResult?.data?.data?.bankerName}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Banker Mobile Number: </strong>
          {queryResult?.data?.data?.bankerMobileNumber}
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Amount (RM): </strong>
          {queryResult?.data?.data?.amount}
        </Col>
      </Row>
    </Space>
  );
};
