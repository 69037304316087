import React from "react";
import { IResourceComponentsProps, CrudFilters, CrudSort, HttpError, useGetIdentity } from "@pankod/refine-core";
import {
  List,
  Table,
  Card,
  Input,
  Icons,
  Form,
  Button,
  FormProps,
  Row,
  Col,
  Space,
  useTable,
  EditButton,
  CreateButton,
  DateField,
  getDefaultSortOrder,
  DatePicker,
} from "@pankod/refine-antd";
import { useSearchParams } from "react-router-dom";
import { RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { IClient, IClientFilterVariables } from "interfaces";
import { CRUDFILTER_OPERATOR_OR, PAGINATION_FIELDS } from "libs/constant";

export const ClientList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const initialSorter: CrudSort[] = [{ field: "updatedAt", order: "desc" }];
  const initialPageSize = 10;
  const permanentFilter: CrudFilters = [
    {
      field: "branchId",
      operator: "eq",
      value: user?.branchId,
    },
  ];

  const { tableProps, searchFormProps, filters, sorter } = useTable<IClient, HttpError, IClientFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter,
    initialSorter,
    initialPageSize,
    queryOptions: {
      enabled: !!user,
    },
    syncWithLocation: true,
    onSearch: (params: any) => {
      const { name, email, identificatioDocumentNumber, createdAt } = params;

      const filters: CrudFilters = [
        {
          operator: "contains",
          field: "email",
          value: email,
        },
        {
          operator: "contains",
          field: "identificatioDocumentNumber",
          value: identificatioDocumentNumber,
        },
        // note(roy): it will be using the local timezone.
        {
          field: "createdAt",
          operator: "between",
          value:
            createdAt && createdAt.length > 0
              ? [createdAt[0].startOf("day").toISOString(), createdAt[1].endOf("day").toISOString()]
              : undefined,
        },
      ];

      if (name) {
        filters.push({
          operator: CRUDFILTER_OPERATOR_OR,
          field: "name",
          value: [
            {
              field: "firstName",
              operator: "contains",
              value: name || undefined,
            },
            {
              field: "lastName",
              operator: "contains",
              value: name || undefined,
            },
          ],
        });
      }

      return filters;
    },
  });

  const Actions: React.FC = () => (
    <Space>
      <CreateButton />
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={24} xl={6}>
        <Card title="Filter">
          <Filter formProps={searchFormProps} filters={filters || []} />
        </Card>
      </Col>

      <Col xs={24} xl={18}>
        <List
          pageHeaderProps={{
            extra: <Actions />,
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="fullName" title="Full Name" />
            <Table.Column dataIndex="identificatioDocumentNumber" title="Identification/Business Number" />
            <Table.Column dataIndex="email" title="Email" />
            <Table.Column dataIndex="phoneNumberMobile" title="Mobile Number" />
            <Table.Column dataIndex={["clientType", "name"]} title="Client Type" />
            <Table.Column dataIndex={["branch", "name"]} title="Branch" />
            <Table.Column<any> dataIndex="status" title="Status" />
            <Table.Column
              dataIndex="createdAt"
              title="Created At"
              render={(record) => <DateField format="LLL" value={record} />}
              defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
              sorter
            />
            <Table.Column<any>
              fixed="right"
              width="5%"
              key="actions"
              title={"Actions"}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const { RangePicker } = DatePicker;

const Filter: React.FC<{ formProps?: FormProps; filters: CrudFilters }> = (props) => {
  const [_, setSearchParams] = useSearchParams();

  const handleResetFilters = () => {
    setSearchParams({});

    props.formProps?.form.resetFields();

    props.formProps?.form.submit();
  };

  return (
    <Form
      layout="vertical"
      {...props.formProps}
      initialValues={{
        ...props.formProps?.initialValues,
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Name" name="name">
            <Input placeholder="Search Name" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Email" name="email">
            <Input placeholder="Search Email" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Identification/Business Number" name="identificatioDocumentNumber">
            <Input placeholder="Search Identification/Business Number" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>

        <Col xl={24} sm={12} md={8} xs={24}>
          <Form.Item label="Created At" name="createdAt">
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="button" onClick={handleResetFilters} type="default">
              Reset Filters
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
