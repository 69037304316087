import {
  Edit,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  InputNumber,
  ButtonProps,
  Grid,
  Select,
} from "@pankod/refine-antd";
import { BaseKey } from "@pankod/refine-core";
import { SearchLegalClientInput } from "components/cases/SearchLegalClientInput";
import { mapValuesToSetter } from "libs";
import { matterClientType } from "./constant";

type EditLegalCaseInvoiceProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  id: BaseKey;
  mattersOptions: { value: number; label: string }[];
};

export const EditLegalCaseInvoice: React.FC<EditLegalCaseInvoiceProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  id,
  mattersOptions,
}) => {
  const breakpoint = Grid.useBreakpoint();

  const matterId = Form.useWatch("legalCaseMatterId", formProps.form);
  const isInvoiceMatterEditable =
    formProps.initialValues !== undefined &&
    formProps.initialValues.legalCase.caseTemplateName === "BANKING LITIGATION" &&
    formProps.initialValues.legalCase.caseTemplateTypeName === "Litigation";

  return (
    <Drawer {...drawerProps} width={breakpoint.sm ? "500px" : "100%"} bodyStyle={{ padding: 0 }} zIndex={1001}>
      <Edit
        pageHeaderProps={{
          title: "Edit Invoice",
          backIcon: false,
          extra: null,
          breadcrumb: { style: { display: "none" } },
        }}
        saveButtonProps={saveButtonProps}
        recordItemId={id}
        resource="legalCaseInvoices"
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={({ legalCaseMatterId, propositionClientId, oppositionClientId, particulars, ...values }) => {
            const payload = mapValuesToSetter({
              ...values,
              legalFees: values.legalFees.toString(),
              stampDuty: values.stampDuty.toString(),
              disbursement: values.disbursement.toString(),
            });
            formProps.onFinish({
              ...payload,
              ...(isInvoiceMatterEditable
                ? {
                    legalCaseMatter: {
                      connect: { id: legalCaseMatterId },
                    },
                    propositionClient: {
                      connect: { id: propositionClientId },
                    },
                    oppositionClient: {
                      connect: { id: oppositionClientId },
                    },
                  }
                : {
                    particulars: particulars,
                  }),
            });
          }}
        >
          <Form.Item label="Bill To" name="recipient" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Invoice No" name="invoiceNumber" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="Receipt No" name="receiptNumber" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Legal Fees" name="legalFees" rules={[{ required: true }]}>
            <InputNumber addonBefore="RM" style={{ width: "150px" }} />
          </Form.Item>
          <Form.Item label="Stamp Duty" name="stampDuty" rules={[{ required: true }]}>
            <InputNumber addonBefore="RM" style={{ width: "150px" }} />
          </Form.Item>
          <Form.Item label="Disbursement" name="disbursement" rules={[{ required: true }]}>
            <InputNumber addonBefore="RM" style={{ width: "150px" }} />
          </Form.Item>

          {!isInvoiceMatterEditable && (
            <>
              <Form.Item label="Particulars" name="particulars" rules={[{ required: false }]}>
                <Input.TextArea />
              </Form.Item>
            </>
          )}

          {isInvoiceMatterEditable && (
            <>
              <Form.Item label="Matter" name="legalCaseMatterId" rules={[{ required: true }]}>
                <Select options={mattersOptions} />
              </Form.Item>

              {matterId && (
                <>
                  <Form.Item
                    label={matterClientType.proposition[matterId]}
                    name={["propositionClientId"]}
                    rules={[{ required: true }]}
                  >
                    <SearchLegalClientInput placeholder="Search client name" />
                  </Form.Item>
                  <Form.Item
                    label={matterClientType.opposition[matterId]}
                    name={["oppositionClientId"]}
                    rules={[{ required: true }]}
                  >
                    <SearchLegalClientInput placeholder="Search client name" />
                  </Form.Item>
                </>
              )}
            </>
          )}
        </Form>
      </Edit>
    </Drawer>
  );
};
