import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Create,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  InputNumber,
  ButtonProps,
  Grid,
  Select,
  Row,
  Col,
  Button,
  Divider,
  AutoComplete,
  Edit,
} from "@pankod/refine-antd";
import { SearchLegalClientInput } from "components/cases/SearchLegalClientInput";
import { matterClientType } from "components/legalCaseInvoice";
import { useBranchOptions } from "hooks/use-branch-options";
import { useLegalCaseMatterOptions } from "hooks/use-legal-case-matter-options";
import { useLegalGoodOptions } from "hooks/use-legal-good-options";
import { mapValuesToSetter } from "libs";

type InvoiceFormProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  initialValues: {
    legalCaseId: string;
    branchId: string;
  };
  isEdit?: boolean;
  category: "litigation" | "general";
};

const InvoiceForm: React.FC<Partial<InvoiceFormProps>> = ({ formProps, initialValues, category, isEdit }) => {
  const legalCaseMatterId = Form.useWatch(["legalCaseMatterId"], formProps.form);

  const branchOptions = useBranchOptions({
    branchId: formProps.initialValues?.branchId,
  });

  const serviceOptions = useLegalGoodOptions();

  const mattersOptions = useLegalCaseMatterOptions();

  const renderExtraField = () => {
    if (category === "litigation") {
      return (
        <>
          <Form.Item label="Matter" name={["legalCaseMatterId"]} rules={[{ required: true }]}>
            <Select options={mattersOptions} />
          </Form.Item>

          {legalCaseMatterId && (
            <>
              <Form.Item
                label={matterClientType.proposition[legalCaseMatterId]}
                name={["client", "propositionId"]}
                rules={[{ required: true }]}
              >
                <SearchLegalClientInput placeholder="Search client name" />
              </Form.Item>
              <Form.Item
                label={matterClientType.opposition[legalCaseMatterId]}
                name={["client", "oppositionId"]}
                rules={[{ required: true }]}
              >
                <SearchLegalClientInput placeholder="Search client name" />
              </Form.Item>
            </>
          )}
        </>
      );
    }

    return (
      <>
        <Form.Item label="Purchase Price" name="purchasePrice" rules={[{ required: true, message: "Purchase Price is required" }]}>
          <InputNumber addonBefore="RM" controls={false} />
        </Form.Item>
        <Form.Item label="Loan Amount" name="loanAmount" rules={[{ required: true, message: "Loan Amount is required" }]}>
          <InputNumber addonBefore="RM" controls={false} />
        </Form.Item>
        <Form.Item label="Particular" name="particular">
          <Input.TextArea />
        </Form.Item>
      </>
    );
  };
  
  return (
    <Form
      {...formProps}
      initialValues={{
        items: !isEdit && category === "litigation" ? [{ name: "Disbursement", fee: 0, otherCharge: 0 }] : [],
        ...formProps.initialValues,
        ...initialValues,
      }}
      layout="vertical"
      onFinish={({
        branchId,
        items: invoiceItems,
        legalCaseMatterId,
        client,
        particular,
        purchasePrice: _purchasePrice,
        loanAmount: _loanAmount,
        ...values
      }) => {
        const items = {
          createMany: {
            data: invoiceItems.map((item) => {
              const fee = Math.floor(item.fee);
              const feeCent = Math.round((item.fee - fee) * 100);
              const otherCharge = Math.floor(item.otherCharge);
              const otherChargeCent = Math.round((item.otherCharge - otherCharge) * 100);
              return {
                name: item.name,
                fee,
                feeCent,
                otherCharge,
                otherChargeCent,
              };
            }),
          },
        };

        const purchasePrice = Math.floor(_purchasePrice);
        const purchasePriceCent = Math.round((_purchasePrice - purchasePrice) * 100);
        const loanAmount = Math.floor(_loanAmount);
        const loanAmountCent = Math.round((_loanAmount - loanAmount) * 100);

        formProps.onFinish(
          isEdit
            ? {
                ...mapValuesToSetter({
                  ...values,
                  purchasePrice,
                  purchasePriceCent,
                  loanAmount,
                  loanAmountCent,
                  particular
                }),
                legalCaseMatter: legalCaseMatterId
                  ? {
                      connect: { id: legalCaseMatterId },
                    }
                  : undefined,
                branch: initialValues.branchId
                  ? {
                      connect: { id: branchId },
                    }
                  : undefined,
                legalCase: initialValues.legalCaseId
                  ? {
                      connect: { id: initialValues.legalCaseId },
                    }
                  : undefined,
                // client is for litigation only
                client: client
                  ? {
                      update: {
                        proposition: {
                          connect: { id: client.propositionId },
                        },
                        opposition: {
                          connect: { id: client.oppositionId },
                        },
                      },
                    }
                  : undefined,
                items,
              }
            : {
                ...values,
                purchasePrice,
                purchasePriceCent,
                loanAmount,
                loanAmountCent,
                legalCaseMatter: legalCaseMatterId
                  ? {
                      connect: { id: legalCaseMatterId },
                    }
                  : undefined,
                branch: {
                  connect: { id: branchId },
                },
                legalCase: {
                  connect: { id: initialValues.legalCaseId },
                },
                // client is for litigation only
                client: client
                  ? {
                      create: {
                        proposition: {
                          connect: { id: client.propositionId },
                        },
                        opposition: {
                          connect: { id: client.oppositionId },
                        },
                      },
                    }
                  : undefined,
                items,
                particular: particular || "",
              }
        );
      }}
    >
      <Form.Item label="Branch" name="branchId" rules={[{ required: true }]}>
        <Select disabled={true} {...branchOptions.selectProps} />
      </Form.Item>
      <Form.Item label="Bill To" name="recipient" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Receipt No" name="receiptNumber" rules={[{ required: false }]}>
        <Input />
      </Form.Item>

      {renderExtraField()}

      <Divider orientation="left">Item</Divider>
      <Form.List
        name="items"
        rules={[
          {
            validator: async (_, items) => {
              if (!items || items.length < 1) {
                return Promise.reject(new Error("Please add at least 1 item"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row align="middle" justify="start" key={key} style={{ alignItems: "baseline" }} gutter={16}>
                <Col>{key + 1}</Col>
                <Col flex="1">
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[{ required: true, message: "Service is required" }]}
                  >
                    <AutoComplete
                      placeholder="Service"
                      {...serviceOptions.selectProps}
                      onSelect={(value) => {
                        const service = serviceOptions.queryResult.data.data.find((option) => option.name === value);
                        const { items } = formProps.form.getFieldsValue();
                        items[key] = {
                          ...items[key],
                          fee: service?.fee || 0,
                          otherCharge: service?.otherCharge || 0,
                        };
                        formProps.form.setFieldsValue({ items });
                      }}
                      disabled={category === "litigation"}
                    />
                  </Form.Item>

                  <Row align="middle" justify="start" style={{ alignItems: "baseline" }} gutter={16}>
                    <Col>
                      <Form.Item
                        {...restField}
                        label="Legal Fee"
                        name={[name, "fee"]}
                        rules={[{ required: true, message: "Fee is required" }]}
                        // normalize={(v) => v.toString()}
                      >
                        <InputNumber addonBefore="RM" controls={false} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        {...restField}
                        label="Stamp Duty / Other Charge"
                        name={[name, "otherCharge"]}
                        // normalize={(v) => v.toString()}
                      >
                        <InputNumber addonBefore="RM" controls={false} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col>{category !== "litigation" && <MinusCircleOutlined onClick={() => remove(name)} />}</Col>
              </Row>
            ))}
            {category !== "litigation" && (
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Item
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </Form>
  );
};

export const InvoiceCreateForm = ({ drawerProps, saveButtonProps, formProps, initialValues, category }) => {
  const breakpoint = Grid.useBreakpoint();

  return (
    <Drawer {...drawerProps} width={breakpoint.sm ? "50%" : "100%"} bodyStyle={{ padding: 0 }} zIndex={1001}>
      <Create
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          title: "Create Invoice",
          backIcon: false,
          extra: null,
          breadcrumb: { style: { display: "none" } },
        }}
      >
        <InvoiceForm formProps={formProps} initialValues={initialValues} category={category} />
      </Create>
    </Drawer>
  );
};

export const InvoiceEditForm = ({
  drawerProps,
  saveButtonProps,
  formProps,
  initialValues,
  category,
}: Omit<InvoiceFormProps, "initialValues"> & { initialValues?: InvoiceFormProps["initialValues"] }) => {
  const breakpoint = Grid.useBreakpoint();

  return (
    <Drawer {...drawerProps} width={breakpoint.sm ? "50%" : "100%"} bodyStyle={{ padding: 0 }} zIndex={1001}>
      <Edit
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          title: "Edit Invoice",
          backIcon: false,
          extra: null,
          breadcrumb: { style: { display: "none" } },
        }}
      >
        <InvoiceForm formProps={formProps} initialValues={initialValues} category={category} isEdit={true} />
      </Edit>
    </Drawer>
  );
};
