import { StyleSheet, Text as BaseText, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  oblique: {
    fontFamily: "Helvetica-Oblique",
  },
  boldOblique: {
    fontFamily: "Helvetica-BoldOblique",
  },
  normal: {
    fontFamily: "Helvetica",
  },
  //
  table: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    minHeight: 20,
    padding: 5,
    fontSize: 10,
  },
  tableCell: {},
});

const Table: React.FC<{
  borderless?: boolean;
  style?: Style;
}> = ({ children, borderless, style }) => {
  return (
    <View
      style={{
        ...styles.table,
        ...(borderless ? { border: 0 } : {}),
        ...style,
      }}
    >
      {children}
    </View>
  );
};

const Tr: React.FC<{
  borderless?: boolean;
  style?: Style;
  borderStyle?: Pick<Style, "borderTop" | "borderRight" | "borderBottom" | "borderLeft">;
}> = ({ children, borderless, style, borderStyle }) => {
  if (Array.isArray(children)) {
    return (
      <View style={{ ...styles.tableRow, ...style }}>
        {children.map((child) => {
          return (
            <View
              key={child.props?.key ?? Math.random()}
              style={{
                ...styles.tableCol,
                ...(borderless ? { border: 0 } : {}),
                ...borderStyle,
                width: child.props?.width ?? `${100 / children.length}%`,
              }}
            >
              {child}
            </View>
          );
        })}
      </View>
    );
  }

  return (
    <View style={styles.tableRow}>
      <View
        style={{
          ...styles.tableCol,
          ...(borderless ? { border: 0 } : {}),
          ...borderStyle,
          width: "100%",
        }}
      >
        {children}
      </View>
    </View>
  );
};

const Td: React.FC<{ width?: string }> = ({ children }) => {
  return <>{children}</>;
};

const Text: React.FC<
  { bold?: boolean; underline?: boolean; italic?: boolean; style?: Style } & Pick<Style, "textAlign">
> = ({ children, bold, textAlign, underline, italic, style }) => {
  const fontFamily = italic
    ? bold
      ? "Helvetica-BoldOblique"
      : "Helvetica-Oblique"
    : bold
    ? "Helvetica-Bold"
    : "Helvetica";

  return (
    <BaseText
      style={{
        ...styles.text,
        fontFamily,
        textAlign,
        textDecoration: underline ? "underline" : "none",
        ...style,
      }}
    >
      {children}
    </BaseText>
  );
};

export const PdfMaker = {
  Table,
  Tr,
  Td,
  Text,
  View,
};
