import { Dropdown, Icons, Menu } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { ILegalCase } from "interfaces";
import { FC } from "react";

type LegalCaseActionProps = {
  record: ILegalCase;
};
const LegalCaseActions: FC<LegalCaseActionProps> = ({ record }) => {
  const { edit, show } = useNavigation();

  const moreMenu = (record: ILegalCase) => (
    <Menu mode="vertical" onClick={({ domEvent }) => domEvent.stopPropagation()}>
      <Menu.Item
        key="show"
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.EyeOutlined
            style={{
              color: "#52c41a",
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        onClick={() => {
          show("cases", record.id);
        }}
      >
        {"view"}
      </Menu.Item>
      <Menu.Item
        key="edit"
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.EditOutlined
            style={{
              color: "#52c41a",
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        disabled={!record?.canEdit}
        onClick={() => {
          edit("cases", record.id);
        }}
      >
        {"edit"}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={moreMenu(record)} trigger={["click"]}>
      <Icons.MoreOutlined
        onClick={(e) => e.stopPropagation()}
        style={{
          fontSize: 24,
        }}
      />
    </Dropdown>
  );
};

export default LegalCaseActions;
