import { message, useForm } from "@pankod/refine-antd";
import { gqlClient } from "App";
import * as gql from "gql-query-builder";
import { IClient, LegalCaseClient } from "interfaces";
import { simplifyErrorMessage } from "libs";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { LegalCaseClientType } from "./constant";
import { CreateLegalCaseClient } from "./create";

const deleteLegalCaseClient = async ({ legalCaseId, clientId }, callback: () => void) => {
  try {
    const { query, variables } = gql.mutation({
      operation: "deleteLegalCaseClient",
      fields: ["clientId", "legalCaseId"],
      variables: {
        where: {
          value: {
            legalCaseId_clientId: {
              legalCaseId,
              clientId,
            },
          },
          type: "LegalCaseClientWhereUniqueInput",
          required: true,
        },
      },
    });

    const response = await gqlClient.request(query, variables);

    message.success("Successfully delete legal case client");

    callback();
  } catch (error) {
    const errorMessage = simplifyErrorMessage(error);
    message.error(errorMessage || "Error while delete legal case client");
  }
};

const updateLegalCaseClient = async ({ legalCaseId, clientId, remarks, type }, callback: () => void) => {
  try {
    const { query, variables } = gql.mutation({
      operation: "updateLegalCaseClient",
      fields: ["clientId", "legalCaseId"],
      variables: {
        where: {
          value: {
            legalCaseId_clientId: {
              legalCaseId,
              clientId,
            },
          },
          type: "LegalCaseClientWhereUniqueInput",
          required: true,
        },
        data: {
          value: {
            ...(remarks && {
              remarks: { set: remarks },
            }),
            ...(type && {
              type: { set: type },
            }),
          },
          type: "LegalCaseClientUpdateInput",
          required: true,
        },
      },
    });
    const response = await gqlClient.request(query, variables);
    message.success("Successfully update legal case client");
    callback();
  } catch (error) {
    const errorMessage = simplifyErrorMessage(error);
    message.error(errorMessage || "Error while update legal case client");
  }
};

interface UpdateClientPayload {
  legalCaseId: string;
  clientId: string;
  remarks?: string;
  type?: string;
}

export const useClientState = ({
  initialState,
  mode,
  callback,
}: {
  initialState: LegalCaseClient[];
  mode: "soft" | "hard";
  callback?: () => void;
}) => {
  const [clients, setClients] = useState<Array<CreateLegalCaseClient>>([]);

  useEffect(() => {
    const legalCaseClients = initialState || [];

    setClients(
      legalCaseClients.map((legalCaseClient) => ({
        ...legalCaseClient?.client,
        legalCaseClient: {
          key: legalCaseClient?.client.id,
          remarks: legalCaseClient?.remarks,
          type: legalCaseClient?.type as LegalCaseClientType,
        },
      }))
    );
  }, [initialState]);

  // append client to legal case
  const createClientAction = useForm({
    action: "create",
    redirect: false,
    resource: "LegalCaseClients",
    metaData: {
      fields: ["clientId", "legalCaseId"],
    },
    onMutationSuccess: () => {
      callback();
    },
  });

  const addClient = (value: IClient, legalCaseId?: string) => {
    if (clients.some((client) => client.id === value.id)) {
      return;
    }

    if (mode === "hard") {
      if (!legalCaseId) {
        throw new Error("LegalCaseId is required");
      }

      return createClientAction.onFinish({
        legalCase: { connect: { id: legalCaseId } },
        client: { connect: { id: value.id } },
      });
    }

    setClients((prev) => {
      return [
        ...prev,
        {
          ...value,
          legalCaseClient: {
            key: value.id,
            remarks: "",
            type: null,
          },
        },
      ];
    });
  };

  const removeClient = (value: IClient, legalCaseId?: string) => {
    if (mode === "hard") {
      if (!legalCaseId) {
        throw new Error("LegalCaseId is required");
      }
      return deleteLegalCaseClient({ legalCaseId, clientId: value.id }, callback);
    }

    setClients((prev) => {
      return prev.filter(({ id }) => id !== value.id);
    });
  };

  const updateClient = ({ legalCaseId, clientId, remarks, type }: UpdateClientPayload) => {
    if (mode === "hard") {
      if (!legalCaseId) {
        throw new Error("LegalCaseId is required");
      }
      return updateLegalCaseClient({ legalCaseId, clientId, remarks, type }, callback);
    }

    setClients((prev) => {
      const records = cloneDeep(prev);
      const index = records.findIndex((client) => client.id === clientId);
      records[index] = {
        ...records[index],
        legalCaseClient: {
          key: records[index].id,
          remarks: "",
          type: null,
          ...records[index].legalCaseClient,
        },
      };
      return records;
    });
  };

  return {
    clients,
    addClient,
    removeClient,
    updateClient,
  };
};
