import { Form, Input, message, Modal } from "@pankod/refine-antd";
import { gqlDataProvider } from "App";
import { simplifyErrorMessage } from "libs";
import { useCallback, useEffect, useState } from "react";

type CloseLegalCaseModalProps = {
  legalCaseId: string;
  hasIncompleteLegalCaseStages?: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
};
const CloseLegalCaseModal: React.FC<CloseLegalCaseModalProps> = ({
  legalCaseId,
  hasIncompleteLegalCaseStages = false,
  onDismiss,
  onSuccess,
}: CloseLegalCaseModalProps) => {
  const [closeLegalCaseRemarks, setCloseLegalCaseRemarks] = useState(null);
  const [form] = Form.useForm();

  const onOkClickHandler = () => {
    form.submit();
  };

  const onFinishHandler = useCallback(async () => {
    try {
      const { data } = await gqlDataProvider.custom!({
        url: "",
        method: "post",
        metaData: {
          operation: "closeLegalCase",
          variables: {
            data: {
              value: {
                id: legalCaseId,
                closeRemarks: closeLegalCaseRemarks,
              },
              type: "CloseLegalCaseInput",
              required: true,
            },
          },
          fields: ["id", "status", "overallStatus"],
        },
      });

      if (data.id) {
        onSuccess();
      }
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || "Unable to close legal case!");
      return;
    }
  }, [closeLegalCaseRemarks, legalCaseId, onSuccess]);

  useEffect(() => {
    if (hasIncompleteLegalCaseStages) {
      return;
    }

    onFinishHandler();
  }, [hasIncompleteLegalCaseStages, onFinishHandler]);

  const onCloseRemarksInputChangeHandler = (event: any) => {
    setCloseLegalCaseRemarks(event.target.value);
  };

  return (
    <>
      {hasIncompleteLegalCaseStages && (
        <Modal
          title="Are you sure close the case?"
          afterClose={onDismiss}
          onOk={onOkClickHandler}
          onCancel={onDismiss}
          visible={true}
        >
          <Form layout={"vertical"} form={form} onFinish={onFinishHandler}>
            <Form.Item
              label="Close Remarks"
              name="closeRemarks"
              rules={[
                {
                  required: hasIncompleteLegalCaseStages,
                  message: "You have incomplete stages, please input your close remarks.",
                },
              ]}
            >
              <Input.TextArea
                placeholder={hasIncompleteLegalCaseStages ? "I'm closing the case earlier due to..." : ""}
                onChange={onCloseRemarksInputChangeHandler}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default CloseLegalCaseModal;
