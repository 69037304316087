import { DateField, Table, Typography, getDefaultSortOrder } from "@pankod/refine-antd";
import { LegalCaseOverallStatus } from "components/cases";
import LegalCaseActions from "components/cases/LegalCaseActions";
import { useListLegalCaseByRole } from "hooks/use-list-legal-case-by-role";
import { ILegalCase } from "interfaces";
const { Text, Paragraph } = Typography;

const PendingCloseCases = ({ branchId }: { branchId: string }) => {
  const { tableProps, sorter } = useListLegalCaseByRole({
    initialPageSize: 4,
    permanentFilter: [
      {
        field: "status",
        operator: "eq",
        value: "AWAITING_TO_CLOSE",
      },
      {
        field: "branchId",
        operator: "eq",
        value: branchId,
      },
    ],
  });

  return (
    <Table {...tableProps} pagination={{ ...tableProps.pagination, simple: true }} showHeader={true} rowKey="id">
      <Table.Column<ILegalCase>
        className="recent-orders-col"
        key="summary"
        title="Case Identity"
        render={(_, record) => (
          <div>
            <Text strong>{record.caseTemplateTypeName}</Text>
            <Paragraph
              ellipsis={{
                rows: 2,
                symbol: <span>...</span>,
              }}
            >
              {record.caseTemplateName}
            </Paragraph>

            <Text strong className="legalCaseId">
              #{record.sequenceId}/{record?.managedBy.lastName}
            </Text>
          </div>
        )}
      />
      <Table.Column<ILegalCase>
        dataIndex={"legalCaseClient"}
        title="First Client Name"
        render={(_, record) => {
          const firstClient = record?.legalCaseClients?.find(Boolean);
          return firstClient?.client?.fullName || "N/A";
        }}
      />
      <Table.Column<ILegalCase>
        title="Status"
        render={(value, record) => {
          return (
            <LegalCaseOverallStatus
              status={record.status.toString()}
              description={record.overallStatus.split("_").join(" ")}
            />
          );
        }}
      />
      <Table.Column
        dataIndex="dueAt"
        title="Due Date"
        defaultSortOrder={getDefaultSortOrder("dueAt", sorter)}
        sorter
        render={(value) => value ? <DateField format="LLL" value={value} /> : 'N/A'}
      />
      <Table.Column
        dataIndex="updatedAt"
        title="Last Updated"
        defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
        sorter
        render={(value) => <DateField format="LLL" value={value} />}
      />
      <Table.Column<ILegalCase>
        fixed="right"
        key="actions"
        align="center"
        render={(_, record) => <LegalCaseActions record={record} />}
      />
    </Table>
  );
};

export default PendingCloseCases;
