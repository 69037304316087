import { message } from "@pankod/refine-antd";
import { gqlDataProvider } from "App";
import { simplifyErrorMessage } from "libs";

export const useUpdateLegalCaseStagesSequenceMutation = () => {
  return {
    mutate: async ({ legalCaseStageIds, onMutationSuccess }) => {
      try {
        const { data } = await gqlDataProvider.custom!({
          url: "",
          method: "post",
          metaData: {
            operation: "updateLegalCaseStagesSequence",
            variables: {
              data: {
                value: {
                  legalCaseStageIds,
                },
                type: "UpdateLegalCaseStageSequenceInput",
                required: true,
              },
            },
            fields: ["id"],
          },
        });

        if (data) {
          onMutationSuccess();
          return data;
        }
      } catch (error) {
        const errorMessage = simplifyErrorMessage(error);
        message.error(errorMessage || "Unable to update case stage!");
        return;
      }
    },
  };
};
