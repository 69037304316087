export const RESOURCE_NAME = "caseProjectTypes";

export const RESOURCE_LIST_NAME = "case-project-types";

export const RESOURCE_FIELDS = [
  "id",
  "name",
  "branchId",
  {
    branch: ["id", "name"],
  },
  "description",
  "status",
  "createdAt",
  "updatedAt",
] as const;
export const PAGINATION_FIELDS = ["currentPage", "perPage", "totalCount", "totalPages"] as const;
