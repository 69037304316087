import { useTable } from "@pankod/refine-antd";
import { CrudFilters } from "@pankod/refine-core";
import { ILegalCase } from "interfaces";
import { PAGINATION_FIELDS } from "libs/constant";

interface UseListLegalCaseByRoleProps {
  initialPageSize?: number;
  permanentFilter?: CrudFilters;
}

export const useListLegalCaseByRole = (props: UseListLegalCaseByRoleProps) => {
  return useTable<ILegalCase>({
    resource: "legalCases",
    metaData: {
      operation: "listLegalCasesByRole",
      items: [
        "id",
        "caseTemplateTypeName",
        "caseTemplateName",
        "sequenceId",
        {
          managedBy: ["id", "fullName", "firstName", "lastName", "username"],
        },
        {
          legalCaseClients: [
            "legalCaseId",
            "clientId",
            "type",
            "remarks",
            "createdAt",
            "updatedAt",
            {
              client: ["id", "firstName", "lastName", "fullName"],
            },
          ],
        },
        "overallStatus",
        "status",
        "createdAt",
        "updatedAt",
        "canEdit",
        "dueAt"
      ],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter: [
      {
        field: "updatedAt",
        order: "asc",
      },
    ],
    initialPageSize: props?.initialPageSize || 10,
    permanentFilter: props?.permanentFilter || [],
  });
};
