import { HttpError, IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  useForm,
  Row,
  Col,
  Breadcrumb,
  Select,
  Space,
  Button,
  Card,
  Switch,
} from "@pankod/refine-antd";
import React, { useEffect } from "react";
import { ICaseTemplate, ICaseTemplateCreateInput } from "interfaces";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useBranchOptions } from "hooks/use-branch-options";
import { useCaseTemplateTypeOptions } from "hooks/use-case-template-type-options";

export const CaseTemplateCreate: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { formProps, saveButtonProps, mutationResult } = useForm<ICaseTemplate, HttpError, ICaseTemplateCreateInput>({
    action: "create",
    redirect: "list",
  });

  const caseTemplateTypeOptions = useCaseTemplateTypeOptions({
    branchId: user?.branchId,
  });

  const branchOptions = useBranchOptions({
    branchId: user?.branchId,
  });

  return (
    <Create
      isLoading={mutationResult?.isLoading}
      saveButtonProps={{
        ...saveButtonProps,
      }}
      pageHeaderProps={{
        title: "Add New Matter",
        breadcrumb: <Breadcrumb hideIcons />,
      }}
    >
      <Form
        {...formProps}
        initialValues={{
          enabledSms: false,
          enabledEmail: false,
          branchId: user?.branchId,
        }}
        layout="vertical"
        onFinish={({ typeId, branchId, statuses, ...values }) => {
          formProps.onFinish({
            ...values,
            statuses: statuses.map((status, index) => ({
              sequenceNo: index + 1,
              ...status,
            })),
            // @ts-ignore
            branch: {
              connect: {
                id: branchId,
              },
            },
            type: {
              connect: {
                id: typeId,
              },
            },
          });
        }}
      >
        <Card title="General Settings">
          <Form.Item label="Case Type" name="typeId" rules={[{ required: true }]}>
            <Select {...caseTemplateTypeOptions.selectProps} />
          </Form.Item>
          <Form.Item label="Matter" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Branch" name="branchId" rules={[{ required: true }]}>
            <Select disabled={user?.branch} {...branchOptions.selectProps} />
          </Form.Item>
        </Card>

        <Card title="Status Management">
          <Space size="large">
            <Form.Item label="Enable SMS" name="enabledSms" rules={[{ required: true }]} valuePropName="checked">
              <Switch />
            </Form.Item>

            <Form.Item label="Enable Email" name="enabledEmail" rules={[{ required: true }]} valuePropName="checked">
              <Switch />
            </Form.Item>
          </Space>

          <Form.List
            name="statuses"
            rules={[
              {
                validator: async (_, statuses) => {
                  if (!statuses || statuses.length < 1) {
                    return Promise.reject(new Error("Please add at least 1 status"));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row
                    align="middle"
                    justify="start"
                    key={key}
                    style={{
                      alignItems: "baseline",
                    }}
                    gutter={16}
                  >
                    <Col>{key + 1}</Col>
                    <Col>
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[{ required: true, message: "Missing status name" }]}
                      >
                        <Input placeholder="Status Name" />
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Form.Item {...restField} name={[name, "messageTemplate"]}>
                        <Input placeholder="Message Template" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Status
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>
      </Form>
    </Create>
  );
};
