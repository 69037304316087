import { HttpError, IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";
import { Create, Form, Input, useForm, Row, Col, Breadcrumb, Select, Card, Space } from "@pankod/refine-antd";
import React from "react";
import { IClient } from "interfaces";
import { useBranchOptions } from "hooks/use-branch-options";
import { useClientTypeOptions } from "hooks/use-client-type-options";

interface IClientCreateInput {
  firstName: string;
  lastName: string;
  identificatioDocumentNumber: string;
  phoneNumberMobile: string;
  phoneNumberOffice: string;
  address: string;
  email: string;
  clientType: {
    connect: {
      id: string;
    };
  };
  branch: {
    connect: {
      id: string;
    };
  };
  clientTypeId: string;
  branchId: string;
}

export const ClientCreate: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const { formProps, saveButtonProps, mutationResult } = useForm<IClient, HttpError, IClientCreateInput>({
    action: "create",
    redirect: "list",
  });

  const branchId = Form.useWatch("branchId", formProps.form);

  const clientTypeOptions = useClientTypeOptions();

  const branchOptions = useBranchOptions({
    branchId,
  });

  return (
    <Create
      isLoading={mutationResult?.isLoading}
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        title: "Add New Client",
        breadcrumb: <Breadcrumb hideIcons />,
      }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
          enabledSms: false,
          enabledEmail: false,
          branchId: user?.branchId,
        }}
        layout="vertical"
        onFinish={({ clientTypeId, branchId, ...values }) => {
          // @ts-ignore
          formProps.onFinish({
            ...values,
            clientType: {
              connect: {
                id: clientTypeId,
              },
            },
            branch: {
              connect: {
                id: branchId,
              },
            },
          });
        }}
      >
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
          <Card title="Personal Info">
            <Row gutter={[24, 0]} align="middle">
              <Col span={12}>
                <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item label="Last Name" name="lastName">
                  <Input />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item label="Client Type" name={["clientTypeId"]} rules={[{ required: true }]}>
                  <Select {...clientTypeOptions.selectProps} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Identification/Business Number"
                  name="identificatioDocumentNumber"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Branch" name={["branchId"]} rules={[{ required: true }]}>
                  <Select {...branchOptions.selectProps} allowClear={true} disabled={!!user?.branchId} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Contact Info">
            <Row>
              <Col span={6}>
                <Form.Item label="Email" name="email" rules={[{ required: false, type: "email" }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Phone Number (Mobile)" name="phoneNumberMobile" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Address" name="address">
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title="Bank Info">
            <Row>
              <Col span={6}>
                <Form.Item label="Bank Account" name="bankAccountNo" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Bank Name" name="bankName" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Bank Person In Charge Name" name="bankPicName" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Space>
      </Form>
    </Create>
  );
};
