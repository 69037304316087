/**
 * change `'0'` (default value of Decimal field) to `''`
 */
export const normalizeDecimal = (decimalValue: string) => (decimalValue === "0" ? "" : decimalValue);

export const normalizeNullToString = <Values extends object, KeyToNormalize extends keyof Values>(
  values: Values,
  keysToNormalize: Array<KeyToNormalize>
) => {
  const result = { ...values };

  keysToNormalize.forEach((key) => {
    if (result[key] === null) {
      result[key] = "" as any;
    }
  });

  return result as Omit<Values, KeyToNormalize> & {
    [key in KeyToNormalize]: Exclude<Values[key], null>;
  };
};

type ValuesSetters<Values extends object> = {
  [key in keyof Values]: {
    set: Values[key] | null;
  };
};

export const mapValuesToSetter = <Values extends object>(values: Values): ValuesSetters<Values> => {
  const result: ValuesSetters<Values> = {} as any;

  Object.keys(values).forEach((k) => {
    const key = k as keyof Values;
    result[key] = {
      set: (values[key] as any) === "" ? null : values[key],
    };
  });

  return result;
};

export type PickKey<T, K extends keyof T> = Extract<keyof T, K>;
