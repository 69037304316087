import { HttpError, IResourceComponentsProps, useGetIdentity, useList } from "@pankod/refine-core";
import { Create, Form, Input, useForm, Row, Col, Card, Breadcrumb, Select } from "@pankod/refine-antd";
import React from "react";
import { IBranch, ICaseProjectType } from "interfaces";
import { BRANCH_RESOURCE_NAME, PAGINATION_FIELDS } from "pages/branches/constant";

const responsive = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

export const CaseProjectTypeCreate: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const { formProps, saveButtonProps, mutationResult } = useForm<ICaseProjectType, HttpError, ICaseProjectType>({
    action: "create",
    redirect: "list",
  });

  const { data: branchList } = useList<IBranch>({
    resource: BRANCH_RESOURCE_NAME,
    metaData: {
      items: ["id", "name"],
      metadata: PAGINATION_FIELDS,
    },
    config: {
      pagination: {
        current: 1,
        pageSize: 20,
      },
    },
  });

  const branchOptions = (branchList?.data || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return (
    <Create
      isLoading={mutationResult?.isLoading}
      saveButtonProps={{
        ...saveButtonProps,
      }}
      pageHeaderProps={{
        title: "Add New Project",
        breadcrumb: <Breadcrumb hideIcons />,
      }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues,
          branch: {
            id: user?.branchId,
          },
        }}
        layout="vertical"
        onFinish={({ branch, ...values }) => {
          formProps.onFinish({
            ...values,
            branch: {
              // @ts-ignore
              connect: {
                id: branch.id,
              },
            },
          });
        }}
      >
        <Row gutter={[24, 24]} justify="start" align="top">
          <Col {...responsive}>
            <Card title="General Settings">
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Branch" name={["branch", "id"]} rules={[{ required: true }]}>
                <Select disabled={user?.branch} style={{ width: "100%" }}>
                  {branchOptions.map((option, index) => (
                    <Select.Option key={`options${index}`} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
