import { HttpError, IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";
import { Create, Form, Input, useForm, Row, Col, Card, InputNumber, Breadcrumb, Select } from "@pankod/refine-antd";
import React from "react";
import { ICaseTemplateType, ICaseTemplateTypeCreateInput } from "interfaces";
import { useBranchOptions } from "hooks/use-branch-options";

const responsive = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

export const CaseTemplateTypeCreate: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const { formProps, saveButtonProps, mutationResult } = useForm<
    ICaseTemplateType,
    HttpError,
    ICaseTemplateTypeCreateInput
  >({
    action: "create",
    redirect: "list",
  });

  const branchOptions = useBranchOptions({
    branchId: user?.branchId,
  });

  return (
    <Create
      isLoading={mutationResult?.isLoading}
      saveButtonProps={{
        ...saveButtonProps,
      }}
      pageHeaderProps={{
        title: "Add New Case Type",
        breadcrumb: <Breadcrumb hideIcons />,
      }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
          initialSequenceNo: 1,
          branchId: user?.branchId,
        }}
        layout="vertical"
        onFinish={({ branchId, ...values }) => {
          // @ts-ignore
          const payload = {
            ...values,
            branch: {
              connect: {
                id: branchId,
              },
            },
          };

          formProps.onFinish(payload);
        }}
      >
        <Row gutter={[24, 24]} justify="start" align="top">
          <Col {...responsive}>
            <Card title="General Settings">
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Code" name="code" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Branch" name="branchId" rules={[{ required: true }]}>
                <Select disabled={user?.branch} {...branchOptions.selectProps} />
              </Form.Item>

              <Form.Item label="Initial Sequence" name="initialSequenceNo" rules={[{ required: true }]}>
                <InputNumber min={1} max={100}></InputNumber>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
