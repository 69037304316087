import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, Form, Input, Row, Col, Typography, Select, Icons, message, Modal } from "@pankod/refine-antd";
import { useState } from "react";
import { gqlDataProvider } from "../../App";
import { simplifyErrorMessage } from "libs";

const { Text, Title } = Typography;

export const AccountSettingEditProfile: React.FC<IResourceComponentsProps> = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const updateForm = async (payload: any) => {
    try {
      setIsLoading(true);

      if (payload.confirmLoginPassword !== payload.newLoginPassword) {
        message.error("New Login Password does not match");
        setIsLoading(false);
        return;
      }

      const { data } = await gqlDataProvider.custom!({
        url: "",
        method: "put",
        metaData: {
          operation: "updateAdminPassword",
          variables: {
            data: {
              value: {
                newPassword: payload.confirmLoginPassword,
                currentPassword: payload.currentPassword,
              },
              type: "UpdatePasswordInput",
              required: true,
            },
          },
          fields: ["id"],
        },
      });
      setIsLoading(false);
      form.resetFields();
      message.success("Successfully edited profile");
    } catch (error) {
      setIsLoading(false);
      form.resetFields();
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || "Error while editing profile");
    }
  };

  return (
    <>
      <Edit
        title="Account Setting"
        isLoading={isLoading}
        saveButtonProps={{
          disabled: isLoading,
          onClick: () => {
            form.submit();
          },
        }}
        pageHeaderProps={{
          extra: <></>,
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            updateForm(values);
          }}
        >
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={24}>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Typography.Title level={4} underline>
                  Change Login Password
                </Typography.Title>
              </div>

              <Row>
                <Col xs={24} lg={12}>
                  <Text strong>* Current Password</Text>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="currentPassword" rules={[{ required: true }]}>
                    <Input type="password" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} lg={12}>
                  <Text strong>* New Login Password</Text>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="newLoginPassword"
                    rules={[
                      { required: true, message: "Password is required" },
                      {
                        pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-__+.,])(?=.{8,20})/,
                        message:
                          "Must contain 8 - 20 characters, one letter, one number and one special case character",
                      },
                    ]}
                  >
                    <Input type="password" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} lg={12}>
                  <Text strong>* Confirm Login Password</Text>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="confirmLoginPassword"
                    rules={[
                      { required: true, message: "Password is required" },
                      {
                        pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-__+.,])(?=.{8,20})/,
                        message:
                          "Must contain 8 - 20 characters, one letter, one number and one special case character",
                      },
                    ]}
                  >
                    <Input type="password" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Edit>
    </>
  );
};
