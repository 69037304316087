import { IResourceComponentsProps, useGetIdentity, useList } from "@pankod/refine-core";
import { Edit, Form, Row, Col, Input, Select, Card, useForm } from "@pankod/refine-antd";
import { ADMIN_ROLE_RESOURCE_NAME, RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { useParams } from "react-router-dom";
import React from "react";
import { IAdminRole, IAdminUser } from "interfaces";
import { PAGINATION_FIELDS } from "../case-template-types/constant";
import { mapValuesToSetter } from "libs";
import { useBranchOptions } from "hooks/use-branch-options";
import { useDepartmentOptions } from "hooks/use-department-options";

const responsive = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

export const AdminUserEdit: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const { data: user } = useGetIdentity();

  const { formProps, queryResult, saveButtonProps } = useForm<IAdminUser>({
    id: id,
    action: "edit",
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
    redirect: false,
  });

  const { data: adminRoleList } = useList<IAdminRole>({
    resource: ADMIN_ROLE_RESOURCE_NAME,
    metaData: {
      items: ["id", "name", "slug"],
      metadata: PAGINATION_FIELDS,
    },
    config: {
      pagination: {
        current: 1,
        pageSize: 20,
      },
    },
  });

  const branchOptions = useBranchOptions({
    branchId: user?.branchId,
  });

  const adminRoleOptions = (adminRoleList?.data || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const statusOptions = [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Inactive",
      value: "INACTIVE",
    },
  ];

  const departmentOptions = useDepartmentOptions();

  return (
    <Edit
      title="Edit"
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        // @ts-ignore
        onFinish={({ roleId, branchId, ...values }) => {
          const payload = mapValuesToSetter({
            ...values,
          });

          formProps?.onFinish?.({
            ...payload,
            role: {
              connect: {
                id: roleId,
              },
            },
            branch: {
              connect: {
                id: branchId,
              },
            },
          });
        }}
      >
        <Row gutter={[24, 24]} justify="start" align="top">
          <Col {...responsive}>
            <Card title="General">
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name" name="lastName" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true },
                  {
                    validator: async (_, value) => {
                      if (!value) return;
                      const regex = /^[a-zA-Z]([._-](?![._-])|[a-zA-Z0-9]){3,50}[a-zA-Z0-9]$/g;
                      if (value.match(regex)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error("Username cannot start with number or symbol and must be longer than 5 character")
                        );
                      }
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, type: "email", message: "Please enter a valid email address" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Branch" name="branchId" rules={[{ required: true }]}>
                <Select {...branchOptions.selectProps} />
              </Form.Item>
              <Form.Item label="Department" name="department" rules={[{ required: true }]}>
                <Select style={{ width: "100%" }}>
                  {departmentOptions.map((option, index) => (
                    <Select.Option key={`options${index}`} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Role" name="roleId" rules={[{ required: true }]}>
                <Select style={{ width: "100%" }}>
                  {adminRoleOptions.map((option, index) => (
                    <Select.Option key={`options${index}`} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                <Select style={{ width: "100%" }}>
                  {statusOptions.map((option, index) => (
                    <Select.Option key={`options${index}`} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={[24, 24]} justify="start" align="top">
          <Col {...responsive}>
            <Card title="Reset Password">
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  {
                    pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-__+.,])(?=.{8,20})/,
                    message: "Must contain 8 - 20 characters, one letter, one number and one special case character",
                  },
                ]}
              >
                <Input type="password" />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
