export const RESOURCE_NAME = "caseTemplateTypes";

export const RESOURCE_LIST_NAME = "case-template-types";

export const RESOURCE_FIELDS = [
  "id",
  "name",
  "code",
  "initialSequenceNo",
  "currentSequenceId",
  "branchId",
  { branch: ["id", "name"] },
  "status",
  "createdAt",
  "updatedAt",
] as const;
export const PAGINATION_FIELDS = ["currentPage", "perPage", "totalCount", "totalPages"] as const;
