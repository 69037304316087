import { useEffect } from "react";
import { Card, Col, Form, FormProps, Input, Modal, ModalProps, Row } from "@pankod/refine-antd";
import { BaseRecord, useGetIdentity } from "@pankod/refine-core";
import { ClientForm } from "./client-form";
import { useClientTypeOptions } from "hooks/use-client-type-options";
import { startCase } from "lodash";
import { mapValuesToSetter } from "libs";
import { useBranchOptions } from "hooks/use-branch-options";

interface EditFormProps {
  action: "create" | "edit";
  isFetching?: boolean;
  values?: BaseRecord;
  modalProps: ModalProps;
  formProps: FormProps;
}

export const ClientModalForm = ({ action, isFetching, modalProps, formProps }: EditFormProps) => {
  const { data: user } = useGetIdentity();

  const clientTypeOptions = useClientTypeOptions();

  const branchOptions = useBranchOptions({
    branchId: user?.branchId,
  });

  return (
    <Modal {...modalProps} title={`${startCase(action)} Client`} width={"80%"}>
      {isFetching ? null : (
        <ClientForm
          formProps={formProps}
          clientTypeOptions={clientTypeOptions}
          branchOptions={branchOptions}
          onFinish={({ clientTypeId, branchId, ...values }) => {
            if (action === "create") {
              return formProps.onFinish({
                ...values,
                clientType: {
                  connect: { id: clientTypeId },
                },
                branch: {
                  connect: { id: branchId },
                },
              });
            }

            formProps.onFinish({
              ...mapValuesToSetter({
                ...values,
              }),
              clientType: {
                connect: { id: clientTypeId },
              },
              branch: {
                connect: { id: branchId },
              },
            });
          }}
        />
      )}
    </Modal>
  );
};
