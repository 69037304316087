import { HttpError, IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import {
  Form,
  useForm,
  Row,
  Col,
  Breadcrumb,
  Table,
  Space,
  Button,
  useModalForm,
  Edit,
  Popconfirm,
  Tabs,
  Divider,
  Icons,
  Modal,
  message,
  Dropdown,
  Menu,
} from "@pankod/refine-antd";
import { useParams } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { IClient, ILegalCase } from "interfaces";
import { LegalCaseClientType, LEGAL_CASE_CLIENT_RESOURCE, RESOURCE_FIELDS } from "./constant";
import { CreateLegalCaseClient } from "./create";
import { LegalCaseOverallStatus, LegalCaseStageTable } from "components/cases";
import { SearchClientInput } from "./components/SearchClientInput";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { CaseForm } from "./form";
import { simplifyErrorMessage } from "libs";
import { ClientRemarkModalForm } from "components/legal-client/client-remark-modal-form";
import { ClientModalForm } from "components/legal-client/client-modal-form";
import { useClientState } from "./use-client-state";
import { LegalCaseInvoiceList } from "components/legalCaseInvoice";
import CloseLegalCaseModal from "components/cases/CloseLegalCaseModal";
import { gqlDataProvider } from "App";
import { startCase } from "lodash";
import { CaseDetailsDescription } from "./components/CaseDetailsDescription";
import { InvoiceList } from "components/invoice";

const responsiveCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 6,
};

export const CaseEdit: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();

  const [legalCaseToClose, setLegalCaseToClose] = useState<undefined | string>();
  const dismissCloseLegalCaseDialog = () => setLegalCaseToClose(undefined);

  const [activeKey, setActiveKey] = useState("1");
  const onTabChange = (key: string) => setActiveKey(key);

  const { formProps, queryResult } = useForm<ILegalCase, HttpError, any>({
    id,
    action: "edit",
    redirect: false,
    resource: "LegalCase",
    metaData: {
      fields: [...RESOURCE_FIELDS].filter((field) => Object.keys(field).length > 0),
    },
  });

  const branchId = Form.useWatch("branchId", formProps.form);

  const { clients, addClient, updateClient, removeClient } = useClientState({
    mode: "hard",
    initialState: queryResult?.data?.data?.legalCaseClients,
    callback: queryResult.refetch,
  });

  const { show: showCreate, ...createFromProps } = useModalForm<IClient>({
    action: "create",
    resource: LEGAL_CASE_CLIENT_RESOURCE.name,
    autoSubmitClose: true,
    redirect: false,
  });

  const { show: showEdit, ...editFromProps } = useModalForm<IClient>({
    action: "edit",
    resource: LEGAL_CASE_CLIENT_RESOURCE.name,
    metaData: {
      fields: [...LEGAL_CASE_CLIENT_RESOURCE.fields],
    },
    autoSubmitClose: true,
    redirect: false,
    onMutationSuccess: () => {
      queryResult.refetch();
    },
  });

  const [editRecord, setEditRecord] = useState<CreateLegalCaseClient>(null);
  const showEditRemarks = (record: CreateLegalCaseClient) => setEditRecord(record);
  const handleCancel = () => setEditRecord(null);

  const onFinishEditRemark = (value: { id: string; remarks: string }) => {
    updateClient({
      legalCaseId: id,
      clientId: value.id,
      remarks: value.remarks,
    });
    setEditRecord(null);
  };

  const menu = (record: CreateLegalCaseClient) => (
    <Menu
      items={Object.values(LegalCaseClientType).map((type) => ({
        key: type,
        label: (
          <Button
            type="link"
            onClick={() => {
              updateClient({
                legalCaseId: id,
                clientId: record.id,
                type: type,
              });
            }}
          >
            {startCase(type)}
          </Button>
        ),
      }))}
    />
  );

  const { show } = useNavigation();
  const { confirm } = Modal;

  const onConfirmCompleteLegalCaseHandler = async (legalCaseId: string) => {
    try {
      const { data } = await gqlDataProvider.custom!({
        url: "",
        method: "post",
        metaData: {
          operation: "cancelLegalCase",
          variables: {
            data: {
              value: {
                id: legalCaseId,
              },
              type: "CancelLegalCaseInput",
              required: true,
            },
          },
          fields: ["id", "status", "overallStatus"],
        },
      });

      if (data.id) {
        queryResult.refetch();
        message.success("The case is now cancelled.");
      }
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || "Unable to complete legal case!");
      return;
    }
  };

  const onToggleIsWaitingForTitle = async (legalCaseId: string) => {
    try {
      const { data } = await gqlDataProvider.custom!({
        url: "",
        method: "post",
        metaData: {
          operation: "toggleIsWaitingForTitle",
          variables: {
            data: {
              value: {
                id: legalCaseId,
              },
              type: "ToggleIsWaitingForTitleInput",
              required: true,
            },
          },
          fields: ["id"],
        },
      });

      if (data.id) {
        queryResult.refetch();
        // message.success("The case is now cancelled.");
      }
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || "Unable to update legal case!");
      return;
    }
  };

  const onSuccessLegalCaseClosedHandler = useCallback(() => {
    message.success("The case is now closed.");
    queryResult.refetch();
    dismissCloseLegalCaseDialog();
  }, [queryResult]);

  const isWaitingForTitle = queryResult?.data?.data?.isWaitingForTitle;
  const hasStatusTerminated = queryResult?.data?.data?.hasStatusTerminated;

  if (!queryResult?.data?.data) {
    return null;
  }

  return (
    <>
      <ClientModalForm
        action="create"
        values={undefined}
        modalProps={createFromProps.modalProps}
        formProps={createFromProps.formProps}
      />
      <ClientModalForm
        action="edit"
        values={editFromProps.queryResult?.data?.data}
        modalProps={editFromProps.modalProps}
        formProps={editFromProps.formProps}
      />
      <ClientRemarkModalForm
        title="Client Remarks"
        record={editRecord}
        onFinish={onFinishEditRemark}
        onCancel={handleCancel}
      />

      {legalCaseToClose && (
        <CloseLegalCaseModal
          hasIncompleteLegalCaseStages={!queryResult?.data?.data.hasCompletedAllStages}
          onSuccess={onSuccessLegalCaseClosedHandler}
          onDismiss={dismissCloseLegalCaseDialog}
          legalCaseId={queryResult?.data?.data?.id}
        />
      )}

      <Edit
        isLoading={queryResult?.isFetching}
        pageHeaderProps={{
          breadcrumb: <Breadcrumb hideIcons />,
          title: "Case Details",
          subTitle: (
            <LegalCaseOverallStatus
              status={queryResult?.data?.data.status.toString()}
              description={queryResult?.data?.data.overallStatus.split("_").join(" ")}
            />
          ),
          extra: [
            <Popconfirm
              key="toggle"
              title={`Are you sure to ${isWaitingForTitle ? "disable" : "enable"} "Waiting For Title"?`}
              onConfirm={() => onToggleIsWaitingForTitle(queryResult?.data?.data?.id)}
              okText="Yes"
            >
              <Button
                key="toggle"
                type="primary"
                danger={isWaitingForTitle}
                disabled={hasStatusTerminated}
                icon={<Icons.AuditOutlined />}
              >
                {isWaitingForTitle ? "Disable" : "Enable"} "Pending Case"
              </Button>
            </Popconfirm>,
            <Button
              key="close"
              type="primary"
              disabled={hasStatusTerminated}
              icon={<Icons.CheckCircleOutlined />}
              onClick={() => setLegalCaseToClose(queryResult?.data?.data?.id)}
            >
              Close
            </Button>,
            <Button
              key={"pdf"}
              icon={<Icons.FilePdfOutlined />}
              onClick={() => {
                show("cases", queryResult.data.data.id);
              }}
            >
              Print
            </Button>,
            <Popconfirm
              key="cancel"
              title="Are you sure to cancel this case?"
              onConfirm={() => onConfirmCompleteLegalCaseHandler(queryResult?.data?.data?.id)}
              okText="Yes"
            >
              <Button danger disabled={hasStatusTerminated} key="cancel" icon={<Icons.CloseCircleOutlined />}>
                Cancel
              </Button>
            </Popconfirm>,
          ],
        }}
        actionButtons={[]}
      >
        <CaseDetailsDescription queryResult={queryResult} />

        <Divider />

        <Tabs activeKey={activeKey} onChange={onTabChange}>
          <Tabs.TabPane tab="Stages" key="1">
            <LegalCaseStageTable
              legalCase={queryResult?.data?.data}
              onChange={() => queryResult.refetch()}
              loading={queryResult.isLoading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="General Info" key="2">
            <CaseForm
              id={id}
              onMutationSuccess={() => {
                queryResult.refetch();
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Client Info" key="3">
            <Row gutter={[24, 24]}>
              <Col {...responsiveCol} xl={24}>
                <SearchClientInput
                  branchId={branchId || null}
                  placeholder="Search By First Name, Last Name, Email or Phone Number"
                  onChange={(values) => {
                    addClient(values, id);
                  }}
                  onCreate={showCreate}
                />
              </Col>
              <Col {...responsiveCol} xl={24}>
                <Table dataSource={[...clients]} pagination={false} rowKey="id">
                  <Table.Column dataIndex="firstName" title="First Name" />
                  <Table.Column dataIndex="lastName" title="Last Name" />
                  <Table.Column dataIndex="email" title="Email" />
                  <Table.Column dataIndex="phoneNumberMobile" title="Mobile Number" />
                  <Table.Column dataIndex={["clientType", "name"]} title="Client Type" />
                  <Table.Column dataIndex={["legalCaseClient", "remarks"]} title="Remarks" />
                  <Table.Column<CreateLegalCaseClient>
                    width="10%"
                    dataIndex="type"
                    title="Type"
                    render={(_, record) => (
                      <Space>
                        <Dropdown overlay={() => menu(record)}>
                          <Button type="link" onClick={(e) => e.preventDefault()}>
                            <Space>
                              {startCase(record?.legalCaseClient?.type ?? "Select type")}
                              <Icons.DownOutlined />
                            </Space>
                          </Button>
                        </Dropdown>
                      </Space>
                    )}
                  />
                  <Table.Column<CreateLegalCaseClient>
                    width="5%"
                    key="actions"
                    title={"Actions"}
                    dataIndex="actions"
                    render={(_, record) => (
                      <Space>
                        <Button icon={<FileAddOutlined />} onClick={() => showEditRemarks(record)} />

                        <Button icon={<EditOutlined />} onClick={() => showEdit(record.id)} />

                        <Popconfirm
                          title="Are you sure to remove this client?"
                          onConfirm={() => {
                            confirm({
                              title: "Are you sure delete this task?",
                              icon: <ExclamationCircleOutlined />,
                              content: "descriptions...",
                              okText: "Yes",
                              okType: "danger",
                              cancelText: "No",
                              onOk() {
                                removeClient(record, id);
                              },
                              onCancel() {},
                            });
                          }}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </Space>
                    )}
                  />
                </Table>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Invoices" key="4">
            <InvoiceList legalCase={queryResult?.data?.data} />

            <LegalCaseInvoiceList
              legalCaseId={queryResult?.data?.data?.id}
              branchId={queryResult?.data?.data?.branchId}
              caseTemplateName={queryResult?.data?.data?.caseTemplateName}
              caseTemplateTypeName={queryResult?.data?.data?.caseTemplateTypeName}
            />
          </Tabs.TabPane>
        </Tabs>
      </Edit>
    </>
  );
};
