import { HttpError, IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";
import { Edit, Form, Row, Col, Input, Select, Card, Space, Switch, Button, useForm } from "@pankod/refine-antd";
import { RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { useParams } from "react-router-dom";
import React from "react";
import { ICaseTemplate } from "interfaces";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { mapValuesToSetter } from "libs";
import { useCaseTemplateTypeOptions } from "hooks/use-case-template-type-options";
import { useBranchOptions } from "hooks/use-branch-options";

export const CaseTemplateEdit: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const { data: user } = useGetIdentity();

  const { form, formProps, queryResult } = useForm<ICaseTemplate, HttpError, ICaseTemplate>({
    id: id,
    action: "edit",
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
    redirect: false,
  });

  const caseTemplateTypeOptions = useCaseTemplateTypeOptions({
    branchId: user?.branchId,
  });

  const branchOptions = useBranchOptions({
    branchId: user?.branchId,
  });

  return (
    <Edit
      title="Edit"
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        disabled: queryResult?.isFetching,
        onClick: () => {
          form.submit();
        },
      }}
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues,
          enabledSms: formProps?.initialValues?.enabledSms ?? false,
          enabledEmail: formProps?.initialValues?.enabledEmail ?? false,
        }}
        layout="vertical"
        onFinish={({ type, statuses, branch, ...values }) => {
          const payload = mapValuesToSetter({
            ...values,
          });

          formProps?.onFinish?.({
            ...payload,
            statuses: statuses.map((status, index) => ({
              sequenceNo: index + 1,
              ...status,
            })),
            branch: branch?.id
              ? {
                  // @ts-ignore
                  connect: { id: branch.id },
                }
              : {
                  disconnect: true,
                },
            type: {
              // @ts-ignore
              connect: {
                id: type.id,
              },
            },
          });
        }}
      >
        <Card title="General Settings">
          <Form.Item label="Case Type" name={["type", "id"]} rules={[{ required: true }]}>
            <Select {...caseTemplateTypeOptions.selectProps} />
          </Form.Item>
          <Form.Item label="Matter" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Branch" name={["branch", "id"]}>
            <Select disabled={user?.branch} {...branchOptions.selectProps} />
          </Form.Item>
        </Card>

        <Card title="Status Management">
          <Space size="large">
            <Form.Item label="Enable SMS" name="enabledSms" valuePropName="checked" rules={[{ required: true }]}>
              <Switch />
            </Form.Item>

            <Form.Item label="Enable Email" name="enabledEmail" valuePropName="checked" rules={[{ required: true }]}>
              <Switch />
            </Form.Item>
          </Space>

          <Form.List
            name="statuses"
            rules={[
              {
                validator: async (_, statuses) => {
                  if (!statuses || statuses.length < 1) {
                    return Promise.reject(new Error("Please add at least 1 status"));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row
                    align="middle"
                    justify="start"
                    key={key}
                    style={{
                      alignItems: "baseline",
                    }}
                    gutter={16}
                  >
                    <Col>{key + 1}</Col>
                    <Col>
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[{ required: true, message: "Missing status name" }]}
                      >
                        <Input placeholder="Status Name" />
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Form.Item {...restField} name={[name, "messageTemplate"]}>
                        <Input placeholder="Message Template" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Status
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>
      </Form>
    </Edit>
  );
};
