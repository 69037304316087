export const RESOURCE_NAME = "adminUsers";

export const RESOURCE_LIST_NAME = "admin-users";

export const RESOURCE_FIELDS = [
  "id",
  "username",
  "firstName",
  "lastName",
  "fullName",
  "roleId",
  "department",
  {
    role: ["name", "slug"],
  },
  "email",
  "status",
  "createdAt",
  "updatedAt",
  "branchId",
  {
    branch: ["id", "name"],
  },
] as const;
export const PAGINATION_FIELDS = ["currentPage", "perPage", "totalCount", "totalPages"] as const;

export const ADMIN_ROLE_RESOURCE_NAME = "adminRoles";
