import { HttpError, IResourceComponentsProps, useGetIdentity, useList } from "@pankod/refine-core";
import { Create, Form, Input, useForm, Row, Col, Card, Breadcrumb, Select } from "@pankod/refine-antd";
import React from "react";
import { ADMIN_ROLE_RESOURCE_NAME, PAGINATION_FIELDS } from "./constant";
import { IAdminRole, IAdminUser, IAdminUserCreateInput } from "interfaces";
import { useBranchOptions } from "hooks/use-branch-options";
import { useDepartmentOptions } from "hooks/use-department-options";

const responsive = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

export const AdminUserCreate: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const { formProps, saveButtonProps, mutationResult } = useForm<IAdminUser, HttpError, IAdminUserCreateInput>({
    action: "create",
    redirect: "list",
  });

  const { data: adminRoleList } = useList<IAdminRole>({
    resource: ADMIN_ROLE_RESOURCE_NAME,
    metaData: {
      items: ["id", "name", "slug"],
      metadata: PAGINATION_FIELDS,
    },
    config: {
      pagination: {
        current: 1,
        pageSize: 20,
      },
    },
  });

  const branchOptions = useBranchOptions({
    branchId: user?.branchId,
  });

  const adminRoleOptions = (adminRoleList?.data || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const statusOptions = [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Inactive",
      value: "INACTIVE",
    },
  ];

  const departmentOptions = useDepartmentOptions();

  return (
    <Create
      isLoading={mutationResult?.isLoading}
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        title: "Add New User",
        breadcrumb: <Breadcrumb hideIcons />,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={({ roleId, branchId, ...values }) => {
          formProps.onFinish({
            ...values,
            role: {
              connect: {
                id: roleId,
              },
            },
            branch: {
              connect: {
                id: branchId,
              },
            },
          });
        }}
      >
        <Row gutter={[24, 24]} justify="start" align="top">
          <Col {...responsive}>
            <Card title="General">
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name" name="lastName" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true },
                  {
                    validator: async (_, value) => {
                      if (!value) return;
                      const regex = /^[a-zA-Z]([._-](?![._-])|[a-zA-Z0-9]){3,50}[a-zA-Z0-9]$/g;
                      if (value.match(regex)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error("Username cannot start with number or symbol and must be longer than 5 character")
                        );
                      }
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, type: "email", message: "Please enter a valid email address" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Branch" name="branchId" rules={[{ required: true }]}>
                <Select {...branchOptions.selectProps} />
              </Form.Item>
              <Form.Item label="Department" name="department" initialValue="LITIGATION" rules={[{ required: true }]}>
                <Select style={{ width: "100%" }}>
                  {departmentOptions.map((option, index) => (
                    <Select.Option key={`options${index}`} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Role" name="roleId" rules={[{ required: true }]}>
                <Select style={{ width: "100%" }}>
                  {adminRoleOptions.map((option, index) => (
                    <Select.Option key={`options${index}`} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Status" name="status" initialValue="ACTIVE" rules={[{ required: true }]}>
                <Select style={{ width: "100%" }}>
                  {statusOptions.map((option, index) => (
                    <Select.Option key={`options${index}`} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
