import { Row, Col, Card, Typography, Divider, Select, Space, Collapse } from "@pankod/refine-antd";

import { CaseTimeline } from "components/dashboard/CaseTimeline";
import PendingUpdateCases from "components/dashboard/PendingUpdateCases";
import PendingCloseCases from "components/dashboard/PendingCloseCases";
import { useGetIdentity } from "@pankod/refine-core";
import { useBranchOptions } from "hooks/use-branch-options";
import { useEffect, useState } from "react";
import { Notification } from "components/dashboard/Notification";
import { CaseTypeChart } from "components/dashboard/BarChart/CaseTypeChart";
import { DepartmentTypeChart } from "components/dashboard/BarChart/DepartmentTypeChart";
import { TotalDepartmentSales } from "components/dashboard/PieChart/TotalDepartmentSales";
import { TotalCaseTypeSales } from "components/dashboard/PieChart/TotalCaseTypeSales";
import { useCustom } from "@pankod/refine-core";

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;


export const DashboardPage: React.FC = () => {
  const [branchId, setBranchId] = useState();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [possibleYears, setPossibleYears] = useState([new Date().getFullYear()])

  const { data: user } = useGetIdentity();

  // HOTFIX: set branchId from user.branchId, the useGetIdentity hook is not working properly
  useEffect(() => {
    if (user?.branchId) {
      setBranchId(user?.branchId);
    }
  }, [user?.branchId]);

  const branchOptions = useBranchOptions({
    branchId: user?.branchId,
  });

  const response = useCustom({
    url: "",
    method: "get",
    metaData: {
      operation: "legalCaseYearsList",
      variables: {
        branchId: branchId || undefined,
      },
      fields: ["years"],
    },
  });

  useEffect(()=>{
    const currentYear = new Date().getFullYear();
    const years = response?.data?.data?.years || [currentYear]
    if (!years.includes(currentYear)) {
      years.unshift(currentYear);
    }
    setPossibleYears(years);
  }, [response?.data?.data])

  if (!user) {
    return null;
  }

  const handleChange = (value) =>{
    setSelectedYear(value);
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Notification branchId={branchId} />
        </Col>

        {!user?.branchId && (
          <Col span={24}>
            <Card title={<Text strong>Branch</Text>}>
              <Select
                style={{ width: "100%" }}
                disabled={!!user?.branchId}
                allowClear
                onSelect={(value) => setBranchId(value)}
                onClear={() => setBranchId(undefined)}
                {...branchOptions.selectProps}
              />
            </Card>
          </Col>
        )}
      </Row>

      <br />
      <Collapse bordered={false} defaultActiveKey={[]}>
        <Panel header="Statistics of Cases" key="1">
          <Row>
            <Select defaultValue={new Date().getFullYear()} style={{ width: 100}} onChange={handleChange}>
              {possibleYears.map((year) => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Row>
          <br/>
          <Row
            gutter={[
              { xl: 8, lg: 8, sm: 24, xs: 24 },
              { xl: 8, lg: 8, sm: 24, xs: 24 },
            ]}
          >
            <Col span={user && user.role.slug === "admin" ? 8 : 12} >
              <Card bodyStyle={{ margin: 15, padding: 10, paddingBottom: 10 }}>
                <CaseTypeChart
                  branchId={branchId}
                  title="Open Cases"
                  status={["NEW", "IN_PROGRESS", "AWAITING_TO_CLOSE"]}
                  year={selectedYear}
                />
              </Card>
            </Col>

            <Col span={user && user.role.slug === "admin" ? 8 : 12}>
              <Card bodyStyle={{ margin: 15, padding: 10, paddingBottom: 10 }}>
                <CaseTypeChart 
                  branchId={branchId} 
                  title="Close Cases" 
                  status={["CLOSED"]} 
                  year={selectedYear}/>
              </Card>
            </Col>
            
            {user && user.role.slug === "admin" && (
              <Col span={8}>
                <Card bodyStyle={{ margin: 15, padding: 10, paddingBottom: 10 }}>
                  <TotalCaseTypeSales 
                    branchId={branchId} 
                    year={selectedYear}/>
                </Card>
              </Col>
            )}

            <Col span={user && user.role.slug === "admin" ? 8 : 12}>
              <Card bodyStyle={{ margin: 15, padding: 10, paddingBottom: 10 }}>
                <DepartmentTypeChart
                  branchId={branchId}
                  title="Open Cases (Department)"
                  status={["NEW", "IN_PROGRESS", "AWAITING_TO_CLOSE"]}
                  year={selectedYear}
                />
              </Card>
            </Col>
            <Col span={user && user.role.slug === "admin" ? 8 : 12}>
              <Card bodyStyle={{ margin: 15, padding: 10, paddingBottom: 10 }}>
                <DepartmentTypeChart 
                  branchId={branchId} 
                  title="Close Cases (Department)" 
                  status={["CLOSED"]} 
                  year={selectedYear}/>
              </Card>
            </Col>
            {user && user.role.slug === "admin" && (
              <Col span={8}>
                <Card bodyStyle={{ margin: 15, padding: 10, paddingBottom: 10 }}>
                  <TotalDepartmentSales 
                    branchId={branchId} 
                    year={selectedYear}/>
                </Card>
              </Col>
            )}
          </Row>
        </Panel>  
        </Collapse>
        <br />
        <Row gutter={[16, 16]}>
          <Col xl={24} lg={16} md={24} sm={24} xs={24}>
            <Card title={<Text strong>Outstanding - Pending Update Cases</Text>}>
              <PendingUpdateCases branchId={branchId} />
            </Card>
            <Divider />
            <Card title={<Text strong>Outstanding - Pending Close Cases</Text>}>
              <PendingCloseCases branchId={branchId} />
            </Card>
          </Col>

          <Col xl={7} lg={8} md={24} sm={24} xs={24} hidden={true}>
            <Card
              hidden={true}
              bodyStyle={{
                height: "100%",
                overflowY: "scroll",
              }}
              title={<Text strong>Timelines</Text>}
            >
              <CaseTimeline />
            </Card>
          </Col>
        </Row>
    </>
  );
};
