import { useList } from "@pankod/refine-core";
import { ILegalCaseMatter } from "interfaces";
import { PAGINATION_FIELDS } from "libs/constant";

export const useLegalCaseMatterOptions = () => {
  const { data } = useList<ILegalCaseMatter>({
    resource: "legalCaseMatters",
    metaData: {
      items: ["id", "name"],
      metadata: PAGINATION_FIELDS,
    },
    config: {
      pagination: {
        current: 1,
        pageSize: 20,
      },
    },
  });

  return (data?.data || []).map(({ id, name }) => ({
    value: id,
    label: name || "",
  }));
};
