import { HttpError, CrudSort } from "@pankod/refine-core";
import {
  Row,
  Col,
  CreateButton,
  useDrawerForm,
  Table,
  useTable,
  DateField,
  Dropdown,
  Menu,
  Icons,
} from "@pankod/refine-antd";

import { IInvoiceCreateInput, ILegalCase, ILegalCaseInvoice } from "interfaces";
import { useDrawer } from "hooks/use-drawer";
import { PAGINATION_FIELDS } from "libs/constant";
import { InvoiceCreateForm, InvoiceEditForm } from "./invoice-form";
import { InvoiceDetails } from "./invoice-details";
import { FIND_FIRST_INVOICE, LIST_INVOICES } from "./constant";
import { formatMoney } from "libs/formatter";

type InvoiceListProps = {
  legalCase: ILegalCase;
};

export const InvoiceList: React.FC<InvoiceListProps> = ({ legalCase }) => {
  const initialSorter: CrudSort[] = [{ field: "createdAt", order: "desc" }];
  const initialPageSize = 5;
  const resource = "invoices";

  const { tableProps, tableQueryResult } = useTable<ILegalCaseInvoice, HttpError>({
    resource,
    metaData: {
      items: LIST_INVOICES,
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter: [
      {
        operator: "eq",
        field: "legalCaseId",
        value: legalCase.id,
      },
    ],
    initialSorter,
    initialPageSize,
  });

  const create = useDrawerForm<IInvoiceCreateInput>({
    action: "create",
    resource,
    redirect: false,
    onMutationSuccess: () => {
      create.form.resetFields();
      tableQueryResult.refetch();
    },
  });

  const edit = useDrawerForm<ILegalCaseInvoice, HttpError, ILegalCaseInvoice>({
    action: "edit",
    resource,
    redirect: false,
    metaData: {
      fields: FIND_FIRST_INVOICE,
    },
  });

  const preview = useDrawer<ILegalCaseInvoice>({
    resource,
    metaData: {
      fields: FIND_FIRST_INVOICE,
    },
  });

  const moreMenu = (id: string) => (
    <Menu mode="vertical" onClick={({ domEvent }) => domEvent.stopPropagation()}>
      {[
        {
          key: "edit",
          label: "edit",
          icon: (
            <Icons.EditOutlined
              style={{
                color: "#52c41a",
                fontSize: 17,
                fontWeight: 500,
              }}
            />
          ),
          onClick: () => {
            edit.show(id);
          },
        },
        {
          key: "print",
          label: "print",
          icon: (
            <Icons.FilePdfOutlined
              style={{
                color: "#52c41a",
                fontSize: 17,
                fontWeight: 500,
              }}
            />
          ),
          onClick: () => {
            preview.show(id);
          },
        },
      ].map((item) => (
        <Menu.Item key={item.key} icon={item.icon} onClick={item.onClick}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const category =
    legalCase.caseTemplateName === "BANKING LITIGATION" && legalCase.caseTemplateTypeName === "Litigation"
      ? "litigation"
      : "general";

  return (
    <>
      <Row>
        <Col xs={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
              marginBottom: "16px",
            }}
          >
            <b>New</b>

            <CreateButton onClick={() => create.show()}>Add Invoice</CreateButton>
          </div>
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="invoiceNumber" title="Invoice No" />
            <Table.Column dataIndex="receiptNumber" title="Receipt No" />
            <Table.Column
              dataIndex={["chargeSummary", "total"]}
              title="Total Amount"
              render={(value) => `RM ${formatMoney(value)}`}
            />
            <Table.Column
              dataIndex="createdAt"
              title="Submitted At"
              render={(value) => <DateField format="LLL" value={value} />}
            />
            <Table.Column<ILegalCaseInvoice>
              fixed="right"
              title="Actions"
              dataIndex="actions"
              key="actions"
              render={(_, record) => (
                <Dropdown overlay={moreMenu(record.id)} trigger={["click"]}>
                  <Icons.MoreOutlined onClick={(e) => e.stopPropagation()} style={{ fontSize: 24 }} />
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>

      <InvoiceDetails drawerProps={preview?.drawerProps} dataProps={preview?.dataProps} />

      <InvoiceCreateForm
        drawerProps={create.drawerProps}
        formProps={create.formProps}
        saveButtonProps={create.saveButtonProps}
        initialValues={{
          legalCaseId: legalCase.id,
          branchId: legalCase.branchId,
        }}
        category={category}
      />

      <InvoiceEditForm
        drawerProps={edit.drawerProps}
        formProps={edit.formProps}
        saveButtonProps={edit.saveButtonProps}
        initialValues={{
          legalCaseId: legalCase.id,
          branchId: legalCase.branchId,
        }}
        category={category}
      />
    </>
  );
};
