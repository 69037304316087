import { HttpError, CrudSort } from "@pankod/refine-core";
import { Row, Col, Table, useTable, DateField, Dropdown, Menu, Icons } from "@pankod/refine-antd";

import { ILegalCaseInvoice, ILegalCaseInvoiceFilterVariables } from "interfaces";
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { ShowLegalCaseInvoice } from "./show";
import { useDrawer } from "hooks/use-drawer";
import { useLegalCaseMatterOptions } from "hooks/use-legal-case-matter-options";

type LegalCaseInvoiceListProps = {
  legalCaseId: string;
  branchId: string;
  caseTemplateName: string;
  caseTemplateTypeName: string;
};

export const LegalCaseInvoiceList: React.FC<LegalCaseInvoiceListProps> = ({
  legalCaseId,
  branchId,
  caseTemplateName,
  caseTemplateTypeName,
}) => {
  const initialSorter: CrudSort[] = [{ field: "createdAt", order: "desc" }];
  const initialPageSize = 5;

  const isInvoiceMatterEditable = caseTemplateName === "BANKING LITIGATION" && caseTemplateTypeName === "Litigation";

  const { tableProps, tableQueryResult } = useTable<ILegalCaseInvoice, HttpError, ILegalCaseInvoiceFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter: [
      {
        operator: "eq",
        field: "legalCaseId",
        value: legalCaseId,
      },
    ],
    initialSorter,
    initialPageSize,
  });

  // const mattersOptions = useLegalCaseMatterOptions();

  // const {
  //   drawerProps: createDrawerProps,
  //   formProps: createFormProps,
  //   saveButtonProps: createSaveButtonProps,
  //   show: createShow,
  //   form,
  // } = useDrawerForm<ILegalCaseInvoiceCreateInput>({
  //   action: "create",
  //   resource: "legalCaseInvoices",
  //   redirect: false,
  //   onMutationSuccess: () => {
  //     form.resetFields();
  //     tableQueryResult.refetch();
  //   },
  // });

  // const {
  //   drawerProps: editDrawerProps,
  //   formProps: editFormProps,
  //   saveButtonProps: editSaveButtonProps,
  //   show: editShow,
  //   id: editLegalCaseInvoiceId,
  // } = useDrawerForm<ILegalCaseInvoice, HttpError, ILegalCaseInvoice>({
  //   action: "edit",
  //   resource: "legalCaseInvoices",
  //   redirect: false,
  //   metaData: {
  //     fields: [...RESOURCE_FIELDS],
  //   },
  // });

  const {
    drawerProps,
    dataProps,
    show,
    id: viewLegalCaseInvoiceId,
  } = useDrawer<ILegalCaseInvoice>({
    resource: "legalCaseInvoices",
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
  });

  const moreMenu = (id: string) => (
    <Menu mode="vertical" onClick={({ domEvent }) => domEvent.stopPropagation()}>
      {/* <Menu.Item
        key="accept"
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.EditOutlined
            style={{
              color: "#52c41a",
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        onClick={() => {
          editShow(id);
        }}
      >
        edit
      </Menu.Item> */}
      <Menu.Item
        key="pdf"
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.FilePdfOutlined
            style={{
              color: "#52c41a",
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        onClick={() => {
          show(id);
        }}
      >
        print
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row>
        <Col xs={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
              marginBottom: "16px",
            }}
          >
            <b>Legacy</b>
            {/* <CreateButton onClick={() => createShow()}>Add Invoice</CreateButton> */}
          </div>
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="invoiceNumber" title="Invoice No" />
            <Table.Column dataIndex="receiptNumber" title="Receipt No" />
            <Table.Column dataIndex="formattedLegalFees" title="Legal Fees" />
            <Table.Column dataIndex="formattedStampDuty" title="Stamp Duty" />
            <Table.Column dataIndex="formattedDisbursement" title="Disbursement" />
            <Table.Column dataIndex="formattedTotalAmount" title="Total Amount" />
            <Table.Column
              dataIndex="createdAt"
              title="Submitted At"
              render={(value) => <DateField format="LLL" value={value} />}
            />
            <Table.Column<ILegalCaseInvoice>
              fixed="right"
              title="Actions"
              dataIndex="actions"
              key="actions"
              render={(_, record) => (
                <Dropdown overlay={moreMenu(record.id)} trigger={["click"]}>
                  <Icons.MoreOutlined onClick={(e) => e.stopPropagation()} style={{ fontSize: 24 }} />
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>

      {/* <CreateLegalCaseInvoice
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        legalCaseId={legalCaseId}
        branchId={branchId}
        mattersOptions={mattersOptions}
        isInvoiceMatterEditable={isInvoiceMatterEditable}
      />
      <EditLegalCaseInvoice
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
        id={editLegalCaseInvoiceId}
        mattersOptions={mattersOptions}
      /> */}
      <ShowLegalCaseInvoice
        drawerProps={drawerProps}
        dataProps={dataProps?.data}
        id={viewLegalCaseInvoiceId}
        sequenceId={dataProps.data?.legalCase?.sequenceId}
      />
    </>
  );
};
