import { Dropdown, Icons, Menu } from "@pankod/refine-antd";
import { ILegalCaseStage } from "interfaces";
import { useUpdateLegalCaseStageStatusMutation } from "hooks";
import { useDelete } from "@pankod/refine-core";

type LegalCaseStageActionProps = {
  record: ILegalCaseStage;
  onMutationSuccess: () => void;
  onDeleteSuccess: (id: string) => void;
};

export const LegalCaseStageActions: React.FC<LegalCaseStageActionProps> = ({
  record,
  onMutationSuccess,
  onDeleteSuccess,
}) => {
  const { mutate } = useUpdateLegalCaseStageStatusMutation({ onMutationSuccess });

  const { mutate: remove } = useDelete();

  const moreMenu = (record: ILegalCaseStage) => (
    <Menu mode="vertical" onClick={({ domEvent }) => domEvent.stopPropagation()}>
      <Menu.Item
        key="accept"
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        // might need to disable when case is closed.
        // disabled={record.status.text !== "Pending"}
        icon={
          <Icons.ClockCircleOutlined
            className="ant-tag-blue"
            style={{
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        disabled={record.status === "PENDING"}
        onClick={async () => {
          await mutate(record.id, "PENDING");
        }}
      >
        {"PENDING"}
      </Menu.Item>
      <Menu.Item
        key="reject"
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.CheckCircleOutlined
            className="ant-tag-green"
            style={{
              fontSize: 17,
            }}
          />
        }
        disabled={record.status === "DONE"}
        onClick={async () => {
          await mutate(record.id, "DONE");
        }}
      >
        {"DONE"}
      </Menu.Item>
      <Menu.Item
        key="delete"
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.DeleteOutlined
            className="ant-tag-red"
            style={{
              fontSize: 17,
            }}
          />
        }
        onClick={async () => {
          remove(
            {
              resource: "LegalCaseStage",
              id: record.id,
            },
            {
              onSuccess: () => {
                onDeleteSuccess(record.id);
              },
            }
          );
        }}
      >
        {"DELETE"}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={moreMenu(record)} trigger={["click"]}>
      <Icons.MoreOutlined
        onClick={(e) => e.stopPropagation()}
        style={{
          fontSize: 24,
        }}
      />
    </Dropdown>
  );
};
