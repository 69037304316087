import React from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToMail: React.FC = () => {
  // return (
  //   <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
  //     <iframe
  //       src="https://c122.mschosting.com:2096/"  // Replace with your desired external URL
  //       style={{ width: '100%', height: '100%', border: 'none' }}
  //       title="External Site"
  //     />
  //   </div>
  // );
  const navigate = useNavigate();

  window.open('https://c122.mschosting.com:2096/', '_blank');
  navigate('/');


  return null;
};

export default RedirectToMail;
