import { Drawer, DrawerProps, Grid, PageHeader, Card } from "@pankod/refine-antd";
import { BaseKey } from "@pankod/refine-core";
import { ILegalCaseInvoice } from "interfaces";
import { InvoiceTemplate } from "components/invoice/templates";

type ShowLegalCaseInvoiceProps = {
  drawerProps: DrawerProps;
  dataProps: ILegalCaseInvoice | any;
  id: BaseKey;
  sequenceId: string;
};

export const ShowLegalCaseInvoice: React.FC<ShowLegalCaseInvoiceProps> = ({
  drawerProps,
  dataProps,
  id,
  sequenceId,
}) => {
  const breakpoint = Grid.useBreakpoint();

  // TODO handle new and legacy data structure
  const proposition = dataProps?.client?.proposition || dataProps?.propositionClient;
  const opposition = dataProps?.client?.opposition || dataProps?.oppositionClient;

  return (
    <Drawer {...drawerProps} width={breakpoint.sm ? "50%" : "100%"} zIndex={1001}>
      <PageHeader
        ghost={false}
        title={"Invoice"}
        backIcon={false}
        extra={null}
        breadcrumb={{ style: { display: "none" } }}
      >
        <Card bordered={false}>
          {/* <GeneralInvoice /> */}

          <InvoiceTemplate.Litigation
            dataProps={{
              ...dataProps,
              propositionClient: {
                ...(proposition || {}),
              },
              oppositionClient: {
                ...(opposition || {}),
              },
            }}
            sequenceId={sequenceId}
          />
        </Card>
      </PageHeader>
    </Drawer>
  );
};
