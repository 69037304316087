import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, Form, Row, Col, Input, useForm } from "@pankod/refine-antd";
import { RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { useParams } from "react-router-dom";
import { mapValuesToSetter } from "../../libs";
import React from "react";
import { IClientType } from "interfaces";

export const ClientTypeEdit: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();

  const { formProps, queryResult, saveButtonProps } = useForm<IClientType>({
    id: id,
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
    redirect: false,
  });

  return (
    <Edit
      title="Edit"
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          const payload = mapValuesToSetter({
            ...values,
          });

          formProps?.onFinish?.(payload);
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} md={24} lg={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
