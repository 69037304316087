import { useCustom } from "@pankod/refine-core";
import { startCase } from "lodash";
import { useMemo } from "react";

export const useDepartmentOptions = () => {
  const response = useCustom({
    url: "",
    method: "get",
    metaData: {
      operation: "departments",
      fields: ["label", "value"],
    },
  });

  return useMemo(() => {
    if (!response?.data?.data) return [];

    return response?.data?.data.map((item) => ({
      label: item.label,
      value: item.value,
    }));
  }, [response?.data?.data]);
};
