import { Button, Form, Input, Modal, Select } from "@pankod/refine-antd";
import { useEffect, useState } from "react";

export const ClientRemarkModalForm = ({ record, title, onFinish, onCancel }) => {
  const [form] = Form.useForm();
  const { setFieldsValue, resetFields } = form;

  useEffect(() => {
    setFieldsValue({
      remarks: record?.legalCaseClient?.remarks || "",
    });

    return () => resetFields();
  }, [setFieldsValue, resetFields, record]);

  return (
    <Modal
      title={title}
      onCancel={onCancel}
      visible={!!record}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={({ remarks }) => {
          onFinish({
            id: record.id,
            remarks,
          });
        }}
      >
        <Form.Item label="Remarks" name="remarks" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
