import { Form, Input, Row, Col, Select, Space, Card } from "@pankod/refine-antd";
import { useEffect } from "react";

export const ClientForm = ({ formProps, clientTypeOptions, branchOptions, onFinish }) => {
  useEffect(() => {
    return () => {
      formProps.form.resetFields();
    };
  }, [formProps.form]);

  return (
    <Form
      {...formProps}
      onFinish={onFinish}
      initialValues={{
        ...formProps.initialValues, // need this line, else the default value wont be set
        enabledSms: false,
        enabledEmail: false,
      }}
      layout="vertical"
    >
      <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
        <Card title="Personal Info">
          <Row gutter={[24, 0]} align="middle">
            <Col span={12}>
              <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item label="Last Name" name="lastName">
                <Input />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item label="Client Type" name={["clientTypeId"]} rules={[{ required: true }]}>
                <Select {...clientTypeOptions.selectProps} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Identification/Business Number"
                name="identificatioDocumentNumber"
                rules={[{ required: true }]}
              >
                <Input placeholder="89XXXXXXXXXX"/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Branch" name={["branchId"]} rules={[{ required: true }]}>
                <Select {...branchOptions.selectProps} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title="Contact Info">
          <Row>
            <Col span={6}>
              <Form.Item label="Email" name="email" rules={[{ required: false, type: "email" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item label="Phone Number (Mobile)" name="phoneNumberMobile" rules={[{ required: true }]}>
                <Input placeholder="012XXXXXXX"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item label="Address" name="address">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title="Bank Info">
          <Row>
            <Col span={6}>
              <Form.Item label="Bank Account" name="bankAccountNo" rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item label="Bank Name" name="bankName" rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item label="Bank Person In Charge Name" name="bankPicName" rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Space>
    </Form>
  );
};
