import { RESOURCE_FIELDS as caseTemplatesResourceFields } from "../case-templates/constant";
import { RESOURCE_FIELDS as caseTemplateTypesResourceFields } from "../case-template-types/constant";
import { RESOURCE_FIELDS as caseProjectTypesResourceFields } from "../case-project-types/constant";
import { RESOURCE_FIELDS as clientTypeResourceFields } from "../client-types/constant";

import { RESOURCE_FIELDS as clientResourceFields } from "../clients/constant";
export const RESOURCE_NAME = "legalCases";

export const RESOURCE_LIST_NAME = "cases";

export const LEGAL_CASE_STAGE_RESOURCE_FIELDS = [
  "id",
  "sequenceNo",
  "status",
  "name",
  "messageTemplate",
  "legalCaseId",
  "updatedAt",
];

export const ADMIN_USER_RESOURCE_FIELDS = ["id", "fullName", "firstName", "lastName", "username"];

export const LEGAL_CASE_CLIENT_RESOURCE = {
  name: "client",
  fields: [
    "id",
    "firstName",
    "lastName",
    "fullName",
    "fullNameWithIC",
    "identificatioDocumentNumber",
    "phoneNumberMobile",
    "phoneNumberOffice",
    "address",
    "email",
    "bankAccountNo",
    "bankName",
    "bankPicName",
    "clientTypeId",
    { clientType: clientTypeResourceFields },
    "branchId",
    { branch: ["id", "name"] },
    "status",
    "createdAt",
    "updatedAt",
  ],
};

export const RESOURCE_FIELDS = [
  "id",
  "sequenceId",
  "caseTemplateId",
  {
    caseTemplate: caseTemplatesResourceFields,
  },
  "caseTemplateName",
  "caseProjectTypeId",
  {
    caseProjectType: caseProjectTypesResourceFields,
  },
  "caseProjectTypeName",
  "caseTemplateEnabledSms",
  "caseTemplateEnabledEmail",
  "caseTemplateTypeId",
  {
    caseTemplateType: caseTemplateTypesResourceFields,
  },
  "caseTemplateTypeName",
  "caseTemplateTypeCode",
  "caseTemplateTypeInitialSequenceNo",
  {
    legalCaseClients: [
      "legalCaseId",
      "clientId",
      "type",
      "remarks",
      "createdAt",
      "updatedAt",
      { client: [...LEGAL_CASE_CLIENT_RESOURCE.fields] },
    ],
  },
  {
    legalCaseStages: LEGAL_CASE_STAGE_RESOURCE_FIELDS,
  },
  "branchId",
  {
    branch: ["id", "name"],
  },
  "bankName",
  "bankerName",
  "bankerMobileNumber",
  "amount",
  "remarks",
  "introducedByClientId",
  "managedByAdminId",
  "lawyeredByAdminId",
  {
    introducedBy: ["id", "fullNameWithIC"],
  },
  {
    managedBy: ADMIN_USER_RESOURCE_FIELDS,
  },
  {
    lawyeredBy: ADMIN_USER_RESOURCE_FIELDS,
  },
  {
    documentVariables: ["proposition", "opposition"],
  },
  "status",
  "overallStatus",
  "hasCompletedAllStages",
  "hasStatusTerminated",
  "propertyAddress",
  "isLegalCaseForProperty",
  "isWaitingForTitle",
  "dueAt",
  "createdAt",
  "updatedAt",
  "canEdit",
] as const;
export const PAGINATION_FIELDS = ["currentPage", "perPage", "totalCount", "totalPages"] as const;

export enum LegalCaseClientType {
  PARTY_1 = "PARTY_1",
  PARTY_2 = "PARTY_2",
}
