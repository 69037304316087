export const RESOURCE_NAME = "legalCaseInvoices";

export const RESOURCE_LIST_NAME = "legal-case-invoices";

export const RESOURCE_FIELDS = [
  "id",
  "invoiceNumber",
  "receiptNumber",
  "legalFees",
  "stampDuty",
  "disbursement",
  "totalAmount",
  "formattedLegalFees",
  "formattedStampDuty",
  "formattedDisbursement",
  "formattedTotalAmount",
  "recipient",
  {
    legalCase: [
      "sequenceId",
      "bankName",
      "caseTemplateName",
      "caseTemplateTypeName",
      { managedBy: ["lastName"] },
      { legalCaseClients: [{ client: ["firstName", "lastName", "fullName"] }] },
    ],
  },
  "createdAt",
  "updatedAt",
  "legalCaseMatterId",
  { legalCaseMatter: ["id", "name"] },
  "propositionClientId",
  {
    propositionClient: [
      "id",
      "firstName",
      "lastName",
      "fullName",
      "bankAccountNo",
      "bankName",
      "identificatioDocumentType",
      "identificatioDocumentNumber",
    ],
  },
  "oppositionClientId",
  {
    oppositionClient: [
      "id",
      "firstName",
      "lastName",
      "fullName",
      "bankAccountNo",
      "bankName",
      "identificatioDocumentType",
      "identificatioDocumentNumber",
    ],
  },
  "particulars",
  {
    remarks: [
      "bankName",
      "customerName",
      "plaintiffName",
      "defendantName",
      "judgementCreditorName",
      "judgementDebtorName",
    ],
  },
] as const;

export const PAGINATION_FIELDS = ["currentPage", "perPage", "totalCount", "totalPages"] as const;

export const matterClientType = {
  proposition: ["", "Bank name", ...Array(2).fill("Plaintiff name"), ...Array(4).fill("Judgement creditor")],
  opposition: ["", "Customer name", ...Array(2).fill("Defendant name"), ...Array(4).fill("Judgement debtor")],
};
