import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, Form, Row, Col, Input, useForm } from "@pankod/refine-antd";
import { RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import React from "react";
import { IBranch } from "interfaces";
import { mapValuesToSetter } from "libs";

export const BranchEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, queryResult, saveButtonProps } = useForm<IBranch>({
    action: "edit",
    redirect: "list",
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS].filter((field) => Object.keys(field).length > 0),
    },
  });

  return (
    <Edit
      title="Edit"
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          const payload = mapValuesToSetter({
            ...values,
          });

          formProps?.onFinish?.(payload);
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
