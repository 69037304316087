import { HttpError, IResourceComponentsProps, useGetIdentity, useList } from "@pankod/refine-core";
import { Edit, Form, Row, Col, Input, Select, useForm } from "@pankod/refine-antd";
import { RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { useParams } from "react-router-dom";
import { mapValuesToSetter } from "../../libs";
import React from "react";
import { ICaseTemplateType } from "interfaces";
import { useBranchOptions } from "hooks/use-branch-options";

export const CaseTemplateTypeEdit: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const { data: user } = useGetIdentity();

  const { formProps, queryResult, saveButtonProps } = useForm<ICaseTemplateType, HttpError, ICaseTemplateType>({
    id: id,
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
    redirect: false,
  });

  const branchOptions = useBranchOptions({
    branchId: user?.branchId,
  });

  return (
    <Edit
      title="Edit"
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={({ branch, ...values }) => {
          const payload = mapValuesToSetter({
            ...values,
          });

          // @ts-ignore
          formProps?.onFinish({
            ...payload,
            ...(branch && {
              branch: {
                connect: {
                  id: branch.id,
                },
              },
            }),
          });
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} md={24} lg={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Branch" name={["branch", "id"]} rules={[{ required: true }]}>
              <Select disabled={true} {...branchOptions.selectProps} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
