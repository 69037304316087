import React from "react";
import {
  IResourceComponentsProps,
  CrudFilters,
  CrudSort,
  HttpError,
  getDefaultFilter,
  useGetIdentity,
} from "@pankod/refine-core";
import {
  List,
  Table,
  Card,
  Input,
  Icons,
  Form,
  Button,
  FormProps,
  Row,
  Col,
  useTable,
  DateField,
  getDefaultSortOrder,
  Dropdown,
  Menu,
  useDrawerForm,
  Alert,
  Select,
} from "@pankod/refine-antd";
import { ILegalCaseInvoice, ILegalCaseInvoiceFilterVariables } from "interfaces";
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from "components/legalCaseInvoice";
import { CRUDFILTER_OPERATOR_IS } from "libs/constant";
import { useDrawer } from "hooks/use-drawer";
import { ShowLegalCaseInvoice } from "components/legalCaseInvoice/show";
import { EditLegalCaseInvoice } from "components/legalCaseInvoice/edit";
import { useLegalCaseMatterOptions } from "hooks/use-legal-case-matter-options";
import { useSearchParams } from "react-router-dom";
import { useClientOptions } from "hooks/use-client-options";

export const CaseInvoiceLegacyList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const initialSorter: CrudSort[] = [{ field: "updatedAt", order: "desc" }];
  const initialPageSize = 30;
  const permanentFilter: CrudFilters = [
    {
      field: "branchId",
      operator: "eq",
      value: user?.branchId,
    },
  ];

  // prettier-ignore
  const { tableProps, searchFormProps, filters, sorter } = useTable<
    ILegalCaseInvoice,
    HttpError,
    ILegalCaseInvoiceFilterVariables
  >({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter,
    initialSorter,
    initialPageSize,
    queryOptions: {
      enabled: !!user,
    },
    syncWithLocation: false,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { invoiceNumber, legalCaseSequenceId, legalCaseClientFirstName, legalCaseClientLastName, receiptNumber } =
        params;

      let clientValue: any = {
        client: {
          is: {},
        },
      };

      if (legalCaseClientFirstName) {
        clientValue.client.is.firstName = {
          contains: legalCaseClientFirstName,
          mode: "insensitive",
        };
      }

      if (legalCaseClientLastName) {
        clientValue.client.is.lastName = {
          contains: legalCaseClientLastName,
          mode: "insensitive",
        };
      }

      let legalCaseFilterValue = { legalCaseClients: {}, sequenceId: {} };
      if (!!clientValue.client.is?.firstName || !!clientValue.client.is?.lastName) {
        legalCaseFilterValue.legalCaseClients = { some: clientValue };
      }

      if (!!legalCaseSequenceId) {
        legalCaseFilterValue.sequenceId = { contains: legalCaseSequenceId, mode: "insensitive" };
      }

      filters.push({
        field: "legalCase",
        operator: CRUDFILTER_OPERATOR_IS,
        value: legalCaseFilterValue,
      });

      filters.push({
        field: "invoiceNumber",
        operator: "contains",
        value: invoiceNumber,
      });

      filters.push({
        field: "receiptNumber",
        operator: "contains",
        value: receiptNumber,
      });

      return filters;
    },
  });

  const mattersOptions = useLegalCaseMatterOptions();

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
    id: editLegalCaseInvoiceId,
  } = useDrawerForm<ILegalCaseInvoice, HttpError, ILegalCaseInvoice>({
    action: "edit",
    resource: "legalCaseInvoices",
    redirect: false,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
  });

  const {
    drawerProps,
    dataProps,
    show,
    id: viewLegalCaseInvoiceId,
  } = useDrawer<ILegalCaseInvoice>({
    resource: "legalCaseInvoices",
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
  });

  return (
    <>
      <ShowLegalCaseInvoice
        drawerProps={drawerProps}
        dataProps={dataProps?.data}
        id={viewLegalCaseInvoiceId}
        sequenceId={dataProps.data?.legalCase?.sequenceId}
      />
      <EditLegalCaseInvoice
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
        id={editLegalCaseInvoiceId}
        mattersOptions={mattersOptions}
      />

      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card title="Filters">
            <Filter formProps={searchFormProps} filters={filters || []} branchId={user?.branchId}/>
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List
            pageHeaderProps={{
              title: "Legal Invoices",
              subTitle: "Should not be used anymore",
            }}
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column
                dataIndex="invoiceNumber"
                title="Invoice No"
                defaultSortOrder={getDefaultSortOrder("invoiceNumber", sorter)}
                sorter
              />
              <Table.Column dataIndex={["legalCase", "sequenceId"]} title="File No" />
              <Table.Column
                title="Client Name(s)"
                render={(value) => {
                  const clientFullNames = value.legalCase.legalCaseClients.map((lcc) => lcc.client.fullName).join(", ");
                  return clientFullNames ? clientFullNames : "N/A";
                }}
              />

              <Table.Column
                dataIndex="createdAt"
                title="Submitted At"
                defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
                sorter
                render={(value) => <DateField format="LLL" value={value} />}
              />
              <Table.Column dataIndex="formattedTotalAmount" title="Total Amount" />
              <Table.Column dataIndex="receiptNumber" title="Receipt No" />

              <Table.Column<ILegalCaseInvoice>
                fixed="right"
                key="actions"
                title={"Actions"}
                dataIndex="actions"
                render={(_, record) => (
                  <>
                    <Dropdown
                      overlay={
                        <Menu mode="vertical" onClick={({ domEvent }) => domEvent.stopPropagation()}>
                          {/* <Menu.Item
                            key="edit"
                            style={{
                              fontSize: 15,
                              display: "flex",
                              alignItems: "center",
                              fontWeight: 500,
                            }}
                            icon={
                              <Icons.EditOutlined
                                style={{
                                  color: "#52c41a",
                                  fontSize: 17,
                                  fontWeight: 500,
                                }}
                              />
                            }
                            onClick={() => {
                              editShow(record.id);
                            }}
                          >
                            edit
                          </Menu.Item> */}
                          <Menu.Item
                            key="pdf"
                            style={{ fontSize: 15, fontWeight: 500 }}
                            icon={
                              <Icons.FilePdfOutlined
                                style={{
                                  color: "#52c41a",
                                  fontSize: 17,
                                  fontWeight: 500,
                                }}
                              />
                            }
                            onClick={() => {
                              show(record.id);
                            }}
                          >
                            print
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Icons.MoreOutlined onClick={(e) => e.stopPropagation()} style={{ fontSize: 24 }} />
                    </Dropdown>
                  </>
                )}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};

const Filter: React.FC<{ formProps?: FormProps; filters: CrudFilters, branchId: string }> = (props) => {
  const { selectProps: clientSelectProps, queryResult: clientQueryResult } = useClientOptions({
    branchId: props.branchId,
  });
  
  const [_, setSearchParams] = useSearchParams();

  const handleResetFilters = () => {
    setSearchParams({});

    props.formProps.form.resetFields();

    props.formProps?.form.submit();
  };

  return (
    <Form
      layout="vertical"
      {...props.formProps}
      initialValues={{
        ...props.formProps?.initialValues,
        title: getDefaultFilter("title", props.filters),
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Invoice No" name="invoiceNumber">
            <Input placeholder="Search" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="File No" name="legalCaseSequenceId">
            <Input placeholder="Search" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Receipt No" name="receiptNumber">
            <Input placeholder="Search" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Client First Name" name="legalCaseClientFirstName">
            {/* <Input placeholder="Search" prefix={<Icons.SearchOutlined />} /> */}
            <Select
              {...clientSelectProps}
              options={clientQueryResult?.data?.data?.map((o) => ({
                label: [o.firstName, o.lastName].filter(Boolean).join(" "),
                value: o.firstName,
              }))}
              placeholder="Search for client name"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="button" onClick={handleResetFilters} type="default">
              Reset Filters
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
