import { useSimpleList, AntdList, Timeline, Tooltip, Typography, Avatar } from "@pankod/refine-antd";
import { ILegalCaseEvent } from "interfaces";
import { PAGINATION_FIELDS } from "libs/constant";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "./style.less";
import { useNavigation } from "@pankod/refine-core";

dayjs.extend(relativeTime);

export const CaseTimeline: React.FC = () => {
  const { listProps } = useSimpleList<ILegalCaseEvent>({
    resource: "legalCaseEvents",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    metaData: {
      items: ["id", "createdAt", "name", "legalCaseSequenceId", "legalCaseId", "legalCaseStatus"],
      metadata: PAGINATION_FIELDS,
    },
    pagination: {
      pageSize: 6,
      simple: true,
    },
    syncWithLocation: false,
  });
  const { dataSource } = listProps;
  const { Text } = Typography;
  const { edit } = useNavigation();

  const legalCaseStatusColor = (legalCaseStatus: string): { color: string; text: string } | undefined => {
    switch (legalCaseStatus) {
      case "NEW":
        return { color: "yellow", text: "pending" };
      case "IN_PROGRESS":
        return { color: "blue", text: "ready" };
      case "CLOSED":
        return { color: "green", text: "on the way" };
      case "CANCELLED":
        return { color: "red", text: "delivered" };
      default:
        break;
    }
  };

  return (
    <AntdList {...listProps}>
      <Timeline>
        {dataSource?.map(({ createdAt, legalCaseSequenceId, name, id, legalCaseId, legalCaseStatus }) => (
          <Timeline.Item className="timeline__point" key={id} color={legalCaseStatusColor(legalCaseStatus)?.color}>
            <div className={`timeline ${legalCaseStatusColor(legalCaseStatus)?.color}`}>
              <Tooltip
                overlayInnerStyle={{ color: "#626262" }}
                color="rgba(255, 255, 255, 0.3)"
                placement="topLeft"
                title={dayjs(createdAt).format("lll")}
              >
                <Text italic className="createdAt">
                  {dayjs(createdAt).fromNow()}
                </Text>
              </Tooltip>

              <div className="timeline__metadata">
                <Avatar size={"large"} src={`https://avatars.dicebear.com/api/croodles/admin.svg`} alt={"hehe"} />
                <Text>
                  <Text strong>Admin</Text> {"actioned"} <Text italic>{name}</Text> {"on the case"}{" "}
                  <Text underline onClick={() => edit("cases", legalCaseId)} strong className="number">
                    #{legalCaseSequenceId}
                  </Text>
                </Text>
              </div>
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </AntdList>
  );
};
