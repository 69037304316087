import { useEffect, useMemo, useState } from "react";
import { Pie, measureTextWidth } from "@ant-design/plots";

import "./style.less";
import { PieConfig } from "@ant-design/charts";
import { currencyFormatter } from "../../../libs/currency-formatter";
import { useCustom } from "@pankod/refine-core";

interface TotalCaseTypeSalesProps {
  branchId?: string;
  year?: number;
}

export const TotalCaseTypeSales: React.FC<TotalCaseTypeSalesProps> = ({ branchId, year }) => {
  const response = useCustom({
    url: "",
    method: "get",
    metaData: {
      operation: "totalCaseTypeSalesAnalytics",
      variables: {
        branchId: branchId || undefined,
        year: year || new Date().getFullYear(),
      },
      fields: ["case_type", "value"],
    },
  });

  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : "inherit"};">${text}</div>`;
  }

  const config = useMemo(() => {
    const config: PieConfig = {
      appendPadding: 10,
      data: (response.data?.data as any[]) || [],
      angleField: "value",
      colorField: "case_type",
      radius: 1,
      innerRadius: 0.64,
      meta: {
        value: {
          formatter: (v) => `${v} RM`,
        },
      },
      label: {
        type: "inner",
        offset: "-50%",
        style: {
          textAlign: "center",
        },
        autoRotate: false,
        content: "{value}",
      },
      legend: {
        position: "bottom",
        title: {
          text: "Case Type",
          spacing: 8,
        },
      },
      statistic: {
        title: {
          offsetY: -4,
          customHtml: (container, view, datum) => {
            const { width, height } = container.getBoundingClientRect();
            const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
            const text = datum ? datum.case_type : "Sales";
            return renderStatistic(d, text, {
              fontSize: 28,
            });
          },
        },
        content: {
          style: {
            fontSize: "26px",
          },
          customHtml: (container, view, datum, data) => {
            const { width } = container.getBoundingClientRect();
            const totalAmount = Number(datum ? datum.value : data.reduce((r, d) => r + d.value, 0));
            const text = `RM ${currencyFormatter(totalAmount)}`;

            return renderStatistic(width, text, {
              fontSize: 26,
            });
          },
        },
      },
      // 添加 中心统计文本 交互
      interactions: [
        {
          type: "element-selected",
        },
        {
          type: "element-active",
        },
        {
          type: "pie-statistic-active",
        },
      ],
    };

    return config;
  }, [response.data?.data]);

  return (
    <div className="daily-order-wrapper">
      <Pie {...config} />
    </div>
  );
};
