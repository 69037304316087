import { useCustom } from "@pankod/refine-core";
import { IClient } from "interfaces";
import { RESOURCE_FIELDS } from "pages/clients/constant";

export const useSearchLegalClient = (value: string) => {
  return useCustom<IClient[]>({
    url: "",
    method: "get",
    metaData: {
      operation: "clients",
      fields: [...RESOURCE_FIELDS],
      variables: {
        where: {
          value: {
            ...(value && {
              OR: [
                {
                  id: {
                    equals: value,
                  },
                },
                {
                  email: {
                    contains: value,
                    mode: "insensitive",
                  },
                },
                {
                  phoneNumberMobile: {
                    contains: value,
                    mode: "insensitive",
                  },
                },
                {
                  firstName: {
                    contains: value,
                    mode: "insensitive",
                  },
                },
                {
                  lastName: {
                    contains: value,
                    mode: "insensitive",
                  },
                },
              ],
            }),
          },
          type: "ClientWhereInput",
          required: true,
        },
        take: 20,
      },
    },
    queryOptions: {
      enabled: true,
    },
  });
};
