import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Tag } from "@pankod/refine-antd";
import { capitalize } from "libs";

type LegalCaseStageProps = {
  status: "PENDING" | "DONE";
};

export const LegalCaseStage: React.FC<LegalCaseStageProps> = ({ status }) => {
  let color = "blue";
  let icon = <SyncOutlined spin />;

  switch (status) {
    case "PENDING":
      color = "blue";
      icon = <SyncOutlined spin />;
      break;
    case "DONE":
      color = "green";
      icon = <CheckCircleOutlined />;
      break;
    default:
      color = "blue";
      break;
  }

  return (
    <Tag color={color} icon={icon}>
      {capitalize(status)}
    </Tag>
  );
};
