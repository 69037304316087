import { IClientType } from "interfaces";
import { PAGINATION_FIELDS } from "libs/constant";
import { RESOURCE_NAME as clientTypeResourceName } from "../pages/client-types/constant";
import { useSelect } from "@pankod/refine-antd";

export const useClientTypeOptions = () => {
  return useSelect<IClientType>({
    resource: clientTypeResourceName,
    metaData: {
      items: ["id", "name"],
      metadata: PAGINATION_FIELDS,
    },
    debounce: 500,
    optionValue: "id",
    optionLabel: "name",
  });
};
