import { useSelect } from "@pankod/refine-antd";
import { ICaseTemplate } from "interfaces";
import { CRUDFILTER_OPERATOR_OR, PAGINATION_FIELDS } from "libs/constant";
import { RESOURCE_NAME as caseTemplateResourceName } from "pages/case-templates/constant";

export const useCaseTemplateOptions = ({ branchId, caseTemplateTypeId }: { branchId: string; caseTemplateTypeId }) => {
  return useSelect<ICaseTemplate>({
    resource: caseTemplateResourceName,
    debounce: 500,
    metaData: {
      items: ["id", "name"],
      metadata: PAGINATION_FIELDS,
    },
    fetchSize: 100,
    filters: [
      {
        operator: CRUDFILTER_OPERATOR_OR,
        field: "branchId",
        value: [branchId, null].map((value) => ({
          field: "branchId",
          operator: "eq",
          value: value,
        })),
      },
      {
        field: "typeId",
        operator: "eq",
        value: caseTemplateTypeId,
      },
    ],
    optionValue: "id",
    optionLabel: "name",
  });
};
