import React, { useEffect, useRef, useState } from "react";
import { Select, Button, Typography, Space } from "antd";
import { debounce } from "lodash";
import { useSearchLegalClient } from "hooks/use-search-legal-client";
import { IClient } from "interfaces";

const { Option } = Select;

interface SearchClientInputProps {
  placeholder: string;
  value?: string;
  onChange?: (value: IClient) => void;
  onCreate?: () => void;
  branchId: string;
}

export const SearchClientInput: React.FC<SearchClientInputProps> = (props) => {
  const [keyword, setKeyword] = useState(undefined);
  const resetKeyword = () => setKeyword("");

  const { refetch, ...queryResult } = useSearchLegalClient(keyword);
  const options = queryResult?.data?.data || [];

  const handleSearch = debounce((newKeyword: string) => {
    setKeyword(newKeyword);
    refetch();
  }, 500);

  const handleChange = (id: string) => {
    resetKeyword();

    const value = options.find((option) => option.id === id);

    if (value) {
      props?.onChange(value);
    }
  };

  const handleCreate = () => {
    resetKeyword();

    props.onCreate();
  };

  return (
    <Select
      value={keyword}
      style={{ width: "100%" }}
      showSearch
      placeholder={props.placeholder}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      loading={queryResult.isFetching || false}
      notFoundContent={
        <Space>
          <Typography.Text>No client found</Typography.Text>
          <Button type="primary" onClick={handleCreate}>
            Add Client
          </Button>
        </Space>
      }
    >
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {[option.fullNameWithIC, `(${option.clientType.name})`].filter(Boolean).join(" ")}
        </Option>
      ))}
    </Select>
  );
};
