import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, StarOutlined, SyncOutlined } from "@ant-design/icons";
import { Tag } from "@pankod/refine-antd";
import { capitalize } from "libs";

type LegalCaseOverallStatusProps = {
  status: string;
  description: string;
};

export const LegalCaseOverallStatus: React.FC<LegalCaseOverallStatusProps> = ({ status, description }) => {
  let color = "blue";
  let icon = <SyncOutlined spin />;

  switch (status) {
    case "NEW":
      color = "yellow";
      icon = <StarOutlined />;
      break;
    case "AWAITING_TO_CLOSE":
      color = "orange";
      icon = <SyncOutlined spin />;
      break;
    case "CANCELLED":
      color = "red";
      icon = <CloseCircleOutlined />;
      break;
    case "CLOSED":
      color = "green";
      icon = <CheckCircleOutlined />;
      break;
    default:
      color = "blue";
      icon = <SyncOutlined spin />;
      break;
  }

  return (
    <Tag color={color} icon={icon}>
      {capitalize(description)}
    </Tag>
  );
};
