import { Drawer, DrawerProps, Grid, PageHeader, Card } from "@pankod/refine-antd";
import { BaseKey } from "@pankod/refine-core";
import { Document, Font, Page, PDFViewer, StyleSheet, Image } from "@react-pdf/renderer";
import { PdfMaker } from "components/pdf";
import dayjs from "dayjs";
import { ILegalCase } from "interfaces";
import { useState } from "react";
import { LegalCaseClientType } from "pages/cases/constant";
import font from "fonts/NotoSerifGeorgian-Black.ttf";

type LegalCasePrintProps = {
  drawerProps: DrawerProps;
  dataProps: ILegalCase;
  id: BaseKey;
  sequenceId: string;
};

Font.register({
  family: "Noto Serif Georgian",
  src: font,
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingRight: 35,
    paddingLeft: 35,
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  sideInformation: {
    fontSize: "17px",
    fontFamily: "Helvetica-Bold",
  },
  sideInformationAddress: {
    fontSize: "15px",
    fontFamily: "Helvetica",
  },
  img: {
    width: "100px",
    padding: "12px",
  },
  capital: {
    fontFamily: "Noto Serif Georgian",
    fontSize: "50px",
    paddingTop: "3%",
  },
  word: {
    fontSize: "30px",
    fontFamily: "Noto Serif Georgian",
  },
  subtitle: {
    paddingTop: "-10px",
    paddingBottom: "20px",
  },
  reference: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    paddingVertical: "2px",
    fontFamily: "Helvetica-Bold",
  },
  particulars: {
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    paddingVertical: "5px",
    fontFamily: "Helvetica-Bold",
  },
  comments: {
    fontSize: "12px",
    fontFamily: "Helvetica",
  },
  tableColHeader: {
    color: "#222",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "12px",
    fontFamily: "Helvetica",
  },
  tableCol: {
    textAlign: "left",
    color: "#333",
    padding: "3px",
    fontSize: "12px",
    fontFamily: "Helvetica",
  },
});

export const PrintLegalCase: React.FC<LegalCasePrintProps> = ({ drawerProps, dataProps, id, sequenceId }) => {
  const breakpoint = Grid.useBreakpoint();
  const [selectedOptions, setSelectedOptions] = useState(null);

  const associates: string[] = [];
  const { clientPartyOne, clientPartyTwo } = (dataProps?.legalCaseClients || []).reduce<{
    clientPartyOne: ILegalCase["legalCaseClients"];
    clientPartyTwo: ILegalCase["legalCaseClients"];
  }>(
    (acc, value) => {
      if (value.type === LegalCaseClientType.PARTY_1) {
        acc.clientPartyOne.push(value);
      } else if (value.type === LegalCaseClientType.PARTY_2) {
        acc.clientPartyTwo.push(value);
      }
      return acc;
    },
    { clientPartyOne: [], clientPartyTwo: [] }
  );

  associates.push(dataProps?.lawyeredBy?.lastName.trim() || "");
  associates.push(
    (clientPartyOne?.find(Boolean)?.client?.fullName ?? '') + " - " + (clientPartyTwo.find(Boolean)?.client?.fullName ?? '') || ""
  );
  associates.push(dataProps?.sequenceId || "");
  associates.push(dataProps?.managedBy?.lastName || "");

  const projectSummary: string[] = [];
  if (dataProps?.caseProjectTypeName) {
    projectSummary.push(dataProps?.caseProjectTypeName.trim());
  }
  if (dataProps?.propertyAddress) {
    projectSummary.push(dataProps?.propertyAddress.trim());
  }
  if (dataProps?.caseTemplateName) {
    projectSummary.push(dataProps?.caseTemplateName.trim());
  }

  const clientsOne = clientPartyOne.map((item) => item.client);
  const clientsTwo = clientPartyTwo.map((item) => item.client);

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "50%" : "100%"}
      //   bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <PageHeader
        ghost={false}
        title={"Case"}
        backIcon={false}
        extra={null}
        breadcrumb={{ style: { display: "none" } }}
      >
        <Card bordered={false}>
          <PDFViewer style={{ width: "100%", height: "70vh" }}>
            <Document>
              <Page size="A4" style={styles.page}>
                <PdfMaker.Text
                  style={{
                    position: "absolute",
                    transform: "rotate(90deg)",
                    transformOrigin: "3% 100%",
                    textAlign: "center",
                    width: "720px",
                    height: 70,
                    paddingTop: 5,
                  }}
                >
                  <PdfMaker.Text style={styles.sideInformation}>{associates.join("/ ")}</PdfMaker.Text>
                  {'\n'}
                  <PdfMaker.View>
                    <PdfMaker.Text style={styles.sideInformationAddress}>{dataProps?.propertyAddress}</PdfMaker.Text>
                  </PdfMaker.View>
                </PdfMaker.Text>

                <PdfMaker.View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <PdfMaker.View style={{ width: "90%" }}>
                    <PdfMaker.Table borderless>
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="25%">
                          <Image style={styles.img} src={"/logo.png"} />
                        </PdfMaker.Td>
                        <PdfMaker.Td width="75%">
                          <PdfMaker.Text style={styles.capital}>
                            T
                            <PdfMaker.Text style={styles.word}>
                              HAI
                              <PdfMaker.Text style={styles.capital}>
                                A<PdfMaker.Text style={styles.word}>DVOCATES</PdfMaker.Text>
                              </PdfMaker.Text>
                            </PdfMaker.Text>
                          </PdfMaker.Text>
                          <PdfMaker.Text bold italic style={styles.subtitle}>
                            Advocates & Solicitors · Peguambela & Peguamcara
                          </PdfMaker.Text>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>
                    </PdfMaker.Table>

                    <PdfMaker.Table borderless>
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="5%"></PdfMaker.Td>
                        <PdfMaker.Td width="95%">
                          <PdfMaker.Text style={styles.reference} bold>
                            File Ref No. : {dataProps?.sequenceId}/{dataProps?.managedBy.lastName}
                          </PdfMaker.Text>
                          <PdfMaker.Text style={styles.reference} bold>
                            Instruction Date : {dayjs(dataProps?.createdAt).format("DD/MM/YYYY")}
                          </PdfMaker.Text>
                          <PdfMaker.Text style={styles.reference} bold>
                            Project Type : {dataProps?.caseProjectType?.name ?? "-"}
                          </PdfMaker.Text>
                          <PdfMaker.Text style={styles.reference} bold>
                            Introducer: {dataProps?.introducedBy?.fullName ?? "-"}
                          </PdfMaker.Text>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>
                    </PdfMaker.Table>

                    <PdfMaker.Table borderless>
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="5%"></PdfMaker.Td>
                        <PdfMaker.Td width="95%">
                          <PdfMaker.Text style={styles.reference} bold>
                            {dataProps?.caseTemplateName}
                          </PdfMaker.Text>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>
                    </PdfMaker.Table>

                    <PdfMaker.Table borderless>
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="5%"></PdfMaker.Td>
                        <PdfMaker.Td width="95%">
                          <PdfMaker.Text style={styles.particulars} bold underline>
                            CLIENT PARTICULARS ({dataProps?.documentVariables?.proposition})
                          </PdfMaker.Text>
                          <PdfMaker.Table>
                            <PdfMaker.Tr style={{ backgroundColor: "#f5f5f5" }}>
                              <PdfMaker.Td width="7%">
                                <PdfMaker.Text style={styles.tableColHeader}>No.</PdfMaker.Text>
                              </PdfMaker.Td>
                              <PdfMaker.Td width="31%">
                                <PdfMaker.Text style={styles.tableColHeader}>Full Name</PdfMaker.Text>
                              </PdfMaker.Td>
                              <PdfMaker.Td width="31%">
                                <PdfMaker.Text style={styles.tableColHeader}>ID No</PdfMaker.Text>
                              </PdfMaker.Td>
                              <PdfMaker.Td width="31%">
                                <PdfMaker.Text style={styles.tableColHeader}>Mobile No</PdfMaker.Text>
                              </PdfMaker.Td>
                            </PdfMaker.Tr>
                            {clientsOne.map((client, idx) => (
                              <PdfMaker.Tr key={client.id}>
                                <PdfMaker.Td width="7%">
                                  <PdfMaker.Text style={styles.tableCol}>{idx + 1}</PdfMaker.Text>
                                </PdfMaker.Td>
                                <PdfMaker.Td width="31%">
                                  <PdfMaker.Text style={styles.tableCol}>{client.fullName}</PdfMaker.Text>
                                </PdfMaker.Td>
                                <PdfMaker.Td width="31%">
                                  <PdfMaker.Text style={styles.tableCol}>
                                    {client.identificatioDocumentNumber}
                                  </PdfMaker.Text>
                                </PdfMaker.Td>
                                <PdfMaker.Td width="31%">
                                  <PdfMaker.Text style={styles.tableCol}>{client.phoneNumberMobile}</PdfMaker.Text>
                                </PdfMaker.Td>
                              </PdfMaker.Tr>
                            ))}
                          </PdfMaker.Table>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>
                    </PdfMaker.Table>

                    <PdfMaker.Table borderless>
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="5%"></PdfMaker.Td>
                        <PdfMaker.Td width="95%">
                          <PdfMaker.Text style={styles.particulars} bold underline>
                            CLIENT PARTICULARS ({dataProps?.documentVariables?.opposition})
                          </PdfMaker.Text>
                          <PdfMaker.Table>
                            <PdfMaker.Tr style={{ backgroundColor: "#f5f5f5" }}>
                              <PdfMaker.Td width="7%">
                                <PdfMaker.Text style={styles.tableColHeader}>No.</PdfMaker.Text>
                              </PdfMaker.Td>
                              <PdfMaker.Td width="31%">
                                <PdfMaker.Text style={styles.tableColHeader}>Full Name</PdfMaker.Text>
                              </PdfMaker.Td>
                              <PdfMaker.Td width="31%">
                                <PdfMaker.Text style={styles.tableColHeader}>ID No</PdfMaker.Text>
                              </PdfMaker.Td>
                              <PdfMaker.Td width="31%">
                                <PdfMaker.Text style={styles.tableColHeader}>Mobile No</PdfMaker.Text>
                              </PdfMaker.Td>
                            </PdfMaker.Tr>
                            {clientsTwo.map((client, idx) => (
                              <PdfMaker.Tr key={client.id}>
                                <PdfMaker.Td width="7%">
                                  <PdfMaker.Text style={styles.tableCol}>{idx + 1}</PdfMaker.Text>
                                </PdfMaker.Td>
                                <PdfMaker.Td width="31%">
                                  <PdfMaker.Text style={styles.tableCol}>{client.fullName}</PdfMaker.Text>
                                </PdfMaker.Td>
                                <PdfMaker.Td width="31%">
                                  <PdfMaker.Text style={styles.tableCol}>
                                    {client.identificatioDocumentNumber}
                                  </PdfMaker.Text>
                                </PdfMaker.Td>
                                <PdfMaker.Td width="31%">
                                  <PdfMaker.Text style={styles.tableCol}>{client.phoneNumberMobile}</PdfMaker.Text>
                                </PdfMaker.Td>
                              </PdfMaker.Tr>
                            ))}
                          </PdfMaker.Table>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>
                    </PdfMaker.Table>

                    <PdfMaker.Table borderless>
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="5%"></PdfMaker.Td>
                        <PdfMaker.Td width="95%">
                          <PdfMaker.Text style={styles.particulars} bold underline>
                            PROPERTY PARTICULARS
                          </PdfMaker.Text>
                          <PdfMaker.Table>
                            <PdfMaker.Tr style={{ backgroundColor: "#f5f5f5" }}>
                              <PdfMaker.Td width="7%">
                                <PdfMaker.Text style={styles.tableColHeader}>No.</PdfMaker.Text>
                              </PdfMaker.Td>
                              <PdfMaker.Td width="93%">
                                <PdfMaker.Text style={styles.tableColHeader}>Address</PdfMaker.Text>
                              </PdfMaker.Td>
                            </PdfMaker.Tr>
                            <PdfMaker.Tr>
                              <PdfMaker.Td width="7%">
                                <PdfMaker.Text style={styles.tableCol}>1</PdfMaker.Text>
                              </PdfMaker.Td>
                              <PdfMaker.Td width="93%">
                                <PdfMaker.Text style={styles.tableCol}>{dataProps?.propertyAddress}</PdfMaker.Text>
                              </PdfMaker.Td>
                            </PdfMaker.Tr>
                          </PdfMaker.Table>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>
                    </PdfMaker.Table>

                    <PdfMaker.Table borderless>
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="5%"></PdfMaker.Td>
                        <PdfMaker.Td width="95%">
                          <PdfMaker.Text underline bold style={styles.particulars}>
                            REMARKS
                          </PdfMaker.Text>
                          <PdfMaker.Text style={styles.comments}>{dataProps?.remarks}</PdfMaker.Text>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>
                    </PdfMaker.Table>

                    <PdfMaker.Table borderless>
                      <PdfMaker.Tr borderless>
                        <PdfMaker.Td width="5%"></PdfMaker.Td>
                        <PdfMaker.Td width="95%">
                          <PdfMaker.Text underline bold style={styles.particulars}>
                            FINANCIAL PARTICULARS
                          </PdfMaker.Text>
                          <PdfMaker.Text style={styles.comments}>Name: {dataProps?.bankerName ?? "-"}</PdfMaker.Text>
                          <PdfMaker.Text style={styles.comments}>
                            Contact: {dataProps?.bankerMobileNumber ?? "-"}
                          </PdfMaker.Text>
                          <PdfMaker.Text style={styles.comments}>Amount: {dataProps?.amount ?? "-"}</PdfMaker.Text>
                        </PdfMaker.Td>
                      </PdfMaker.Tr>
                    </PdfMaker.Table>
                  </PdfMaker.View>
                </PdfMaker.View>
              </Page>
            </Document>
          </PDFViewer>
        </Card>
      </PageHeader>
    </Drawer>
  );
};
