import { Button, Checkbox, DateField, Table, Row, Col, useModalForm, Popconfirm } from "@pankod/refine-antd";
import { ILegalCase, ILegalCaseStage } from "interfaces";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { LegalCaseStage } from "./LegalCaseStage";
import { LegalCaseStageActions } from "./LegalCaseStageActions";
import update from "immutability-helper";
import { HTML5Backend } from "react-dnd-html5-backend";
import LegalCaseStageModal from "./LegalCaseStageModal";
import { useUpdateLegalCaseStagesSequenceMutation } from "hooks/use-update-legal-case-stages-sequence-mutation";
import { useNotification } from "@pankod/refine-core";

interface LegalCaseStageTableProps {
  legalCase: ILegalCase;
  loading?: boolean;
  onChange?: () => void;
}

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = "DraggableBodyRow";

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

export const LegalCaseStageTable = (props: LegalCaseStageTableProps) => {
  const notification = useNotification();
  const [dataSource, setDataSource] = useState<ILegalCaseStage[]>([]);
  const [isRowChanged, setIsRowChanged] = useState(false);

  useEffect(() => {
    if (props.legalCase?.legalCaseStages?.length > 0) {
      const sortedData = props.legalCase.legalCaseStages.sort((a, b) => a.sequenceNo - b.sequenceNo);
      setDataSource(sortedData || []);
    }
  }, [props.legalCase?.legalCaseStages]);

  const { mutate } = useUpdateLegalCaseStagesSequenceMutation();

  const handleSave = () => {
    mutate({
      legalCaseStageIds: dataSource.map((item) => item.id),
      onMutationSuccess: () => {
        notification.open({
          type: "success",
          message: "Stage sequence updated successfully",
        });
        props.onChange?.();
        setIsRowChanged(false);
      },
    });
  };

  const { show: showCreate, ...createFromProps } = useModalForm({
    action: "create",
    resource: "LegalCase",
    metaData: {
      operation: "createLegalCaseStage",
      operationType: "LegalCaseStageCreateInput",
    },
    autoSubmitClose: true,
    redirect: false,
    onMutationSuccess: () => {
      props.onChange?.();
    },
  });

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = dataSource[dragIndex];
      setDataSource(
        update(dataSource, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
      setIsRowChanged(true);
    },
    [dataSource]
  );

  return (
    <>
      <LegalCaseStageModal
        legalCase={props.legalCase}
        nextSequenceNo={Math.max(...dataSource.map((item) => item.sequenceNo), 0) + 1}
        formProps={createFromProps.formProps}
        modalProps={createFromProps.modalProps}
      />

      <Row>
        <Col xs={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "8px",
              marginBottom: "16px",
            }}
          >
            <Button onClick={() => showCreate()}>Add Stage</Button>
            <Popconfirm
              key="toggle"
              title={`Are you sure to update stage sequence?`}
              onConfirm={() => handleSave()}
              okText="Yes"
            >
              <Button disabled={!isRowChanged}>Save Changes</Button>
            </Popconfirm>
          </div>

          <DndProvider backend={HTML5Backend}>
            <Table
              loading={props.loading || createFromProps.formLoading}
              dataSource={dataSource}
              pagination={false}
              components={{
                body: {
                  row: DraggableBodyRow,
                },
              }}
              onRow={(_, index) => {
                const attr = {
                  index,
                  moveRow,
                };
                return attr as React.HTMLAttributes<any>;
              }}
              rowKey="id"
            >
              <Table.Column dataIndex="sequenceNo" title="No" />
              <Table.Column dataIndex="name" title="Stage Name" />
              <Table.Column
                dataIndex="updatedAt"
                title="Updated At"
                render={(record) => {
                  return <DateField format="LLL" value={record} />;
                }}
              />
              <Table.Column
                dataIndex="status"
                title="Status"
                render={(value) => {
                  return <LegalCaseStage status={value} />;
                }}
              />
              <Table.Column<any>
                dataIndex="messageTemplate"
                title="Message Template"
                render={(value) => {
                  return value ? value : "N/A";
                }}
              />
              <Table.Column<any>
                title="Notifications"
                render={(value) => (
                  <>
                    <li>
                      <Checkbox checked={props.legalCase.caseTemplateEnabledEmail} disabled={true}>
                        Email
                      </Checkbox>
                    </li>
                    <li>
                      <Checkbox checked={props.legalCase.caseTemplateEnabledSms} disabled={true}>
                        SMS
                      </Checkbox>
                    </li>
                  </>
                )}
              />
              <Table.Column<ILegalCaseStage>
                fixed="right"
                title={"Actions"}
                dataIndex="status"
                key="status"
                align="center"
                render={(_value, record) => (
                  <LegalCaseStageActions
                    record={record}
                    onMutationSuccess={() => {
                      props.onChange?.();
                    }}
                    onDeleteSuccess={(id) => {
                      props.onChange?.();
                    }}
                  />
                )}
              />
            </Table>
          </DndProvider>
        </Col>
      </Row>
    </>
  );
};
