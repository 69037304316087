import { RESOURCE_FIELDS as clientTypeResourceFields } from "../client-types/constant";

export const RESOURCE_NAME = "clients";

export const RESOURCE_LIST_NAME = "clients";

export const RESOURCE_FIELDS = [
  "id",
  "firstName",
  "lastName",
  "fullName",
  "fullNameWithIC",
  "identificatioDocumentNumber",
  "phoneNumberMobile",
  "phoneNumberOffice",
  "address",
  "email",
  "clientTypeId",
  "branchId",
  {
    branch: ["id", "name"],
  },
  {
    clientType: clientTypeResourceFields,
  },
  "bankAccountNo",
  "bankName",
  "bankPicName",
  "status",
  "createdAt",
  "updatedAt",
] as const;
